import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: 50,
    // fontSize: '16px',
    fontWeight: "bold",
    borderRadius: 8,
  },
  prevButton: {
    minHeight: 50,
    // fontSize: '16px',
    fontWeight: "bold",
    borderRadius: 8,
    backgroundColor: "#f5f5f5",
    color: "#bd2132",
  },
}));

const NavigationButtons = (props) => {
  const {
    nextRoute,
    disablePrevious,
    orderDetails,
    totalPrice,
    onNext,
    page,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleNextChange = () => {
    let thisDetails = orderDetails[page];
    let thisKeys = Object.keys(thisDetails);
    let emptyValues = [];
    thisKeys.map((item) => {
      if (
        orderDetails.cap.capType != null &&
        orderDetails.cap.capType.value === "Aluminium" &&
        orderDetails.label != null &&
        orderDetails.label.labelType !== "New Label" &&
        item === "capColor"
      ) {
      } else if (
        item === "noOfBottles" &&
        orderDetails.wine.volume &&
        orderDetails.wine.volume.label === "1.5"
      ) {
        if (
          Number(orderDetails.wine.noOfBottles) < 300 ||
          Number(orderDetails.wine.noOfBottles) > 600
        ) {
          alert("Number of botles should be between 300 and 600");
          emptyValues.push(item);
        }
      } else if (
        item === "noOfBottles" &&
        orderDetails.wine.volume &&
        orderDetails.wine.volume.label === "0.75"
      ) {
        if (
          Number(orderDetails.wine.noOfBottles) < 8000 ||
          Number(orderDetails.wine.noOfBottles) > 30000
        ) {
          alert("Number of botles should be between 8000 and 30,000");
          emptyValues.push(item);
        }
      } else {
        let a =
          typeof thisDetails[item] === "object" &&
          thisDetails[item]?.value &&
          thisDetails[item]?.value === "tap_to_select"
            ? emptyValues.push(item)
            : null;
      }
    });

    let emptyFS = "";
    emptyValues.map((val) => {
      emptyFS = `${emptyFS}\n ${val}`;
    });
    console.log("This is empty value", emptyValues);
    if (emptyValues.length) {
      window.alert(`Please fill the following values\n ${emptyFS}`);
    } else {
      if (!orderDetails) {
        history.push(nextRoute);
      } else {
        history.push({
          pathname: nextRoute,
          state: { orderDetails, totalPrice },
        });
      }
    }
  };

  const handlePreviousChange = () => {
    history.goBack();
  };

  return (
    <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={6}>
        <Button
          onClick={handlePreviousChange}
          disabled={disablePrevious}
          className={classes.prevButton}
          variant="contained"
          color="default"
          fullWidth
          disableElevation
        >
          Previous
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={handleNextChange}
          className={classes.button}
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default NavigationButtons;
