import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { PriceContext, isEmpty } from "./PriceContext";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Drawer from "./Drawer";
import InputTile from "../../components/InputTile";
import NavigationButtons from "../../components/NavigationButtons";
import React, { useState } from "react";
import { humanize } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../Backend/firebase";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    margin: theme.spacing(3, 0),
  },
}));

const labelClichePrice = 500;

const defaultPayload = {
  label: "Tap to select",
  value: "tap_to_select",
};

const newData = {
  labelType: {
    sulfites: [
      {
        value: "popcorn",
        label: "Popcorn",
        price: "1.50",
        imgUrl:
          "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
      },

      {
        value: "chateau_surain",
        label: "Chateau Surain",
        price: "1.50",
        imgUrl:
          "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
      },
      {
        value: "New Label",
        label: "New Label",
        price: "1.50",
        imgUrl:
          "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
      },
    ],
    without_sulfites: [
      {
        value: "news_drinker",
        label: "News Drinker",
        price: "1.50",
        imgUrl:
          "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
      },
      {
        value: "New Label",
        label: "New Label",
        price: "1.50",
        imgUrl:
          "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
      },
    ],
  },
};

const Label = () => {
  const {
    price,
    setPrice,
    orderDetails,
    setOrderDetails,
    appData,
  } = React.useContext(PriceContext);
  console.log("These are order details", orderDetails);
  
  let {
    wine: { noOfBottles = 1 },
  } = orderDetails;
  const { volume, wine } = orderDetails;

  //appData stuff
  const data = Object.assign({}, appData.labelData);
  data.labelType =
    newData.labelType[
      orderDetails?.wine?.wineType?.value === "tap_to_select"
        ? "without_sulfites"
        : (orderDetails &&
            orderDetails.wine.wineType &&
            orderDetails.wine.wineType.value) ||
          "without_sulfites"
    ];
  //////////////////

  const classes = useStyles();
  //Drawer Stuff
  const [drawerTitle, setDrawerTitle] = React.useState("wineType");
  const [isVisible, setIsVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState("wineType");
  const [passData, setPassData] = React.useState([]);
  // const [labelType, setLabelType] = React.useState('Chateau Surain');
  const [shouldRender, setShouldRender] = React.useState(false);
  const [labelDesignerIconPrice, setLabelDesignerIconPrice] = React.useState(
    1400
  );

  const [LabelData, setLabelData] = React.useState([]);
  const GetBottleTypeo = () =>{
    console.log('ssssss doto');
    let doData =  db.collection('Label')
    .onSnapshot(x=>{
    
    setLabelData(x);
    // console.log('ssssss---======---+++++',x.docs.map(y=>y.data()));

    })
  }

  React.useEffect(()=>{
  
    GetBottleTypeo();
  },[])

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  const inputEl = React.useRef(null);
  const nonbottlesInput = () => {
    return (
      <div style={{ padding: "10px 20px 10px 10px" }}>
        <input
          ref={inputEl}
          onBlur={(e) => {
            var number = Number(e.target.value);
            console.log("THis is number", number);
            setLabelDesignerIconPrice(number);
            // if (
            //   orderDetails.wine.volume &&
            //   orderDetails.wine.volume.label === "1.5"
            // ) {
            //   if (number < 300 || number > 600) {
            //     alert("Number of botles should be between 300 and 600");
            //   } else {
            //     updateValue("noOfBottles", e.target.value);
            //   }
            // } else {
            //   if (number < 8000 || number > 30000) {
            //     alert("Number of botles should be between 8000 and 30,000");
            //   } else {
            //     updateValue("noOfBottles", e.target.value);
            //   }
            // }
          }}
          type="number"
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "7px",
            paddingLeft: 10,
            border: "1px solid #ddd",
          }}
          placeholder="Designer Icon Price"
        />
      </div>
    );
  };
  const numberInput = React.useMemo(() => nonbottlesInput);

  const updateValue = (field, value) => {
    if (field === "labelType" && value.value === "New Label") {
      updateValue("labelDesignerIcon", {
        value: true,
        price: noOfBottles
          ? labelDesignerIconPrice / noOfBottles
          : labelDesignerIconPrice,
      });
      updateValue("labelCliche", {
        value: true,
        price: noOfBottles ? labelClichePrice / noOfBottles : labelClichePrice,
      });
    } else if (field === "labelType" && value.value !== "New Label") {
      console.log("inside elseif");
      updateValue("labelDesignerIcon", { value: false, price: 0 });
      updateValue("labelCliche", { value: false, price: 0 });
      updateValue("caseCliche", false);
    }
    setOrderDetails("label", field, value);
    onClose();
  };
  React.useEffect(() => {
    if (isEmpty(orderDetails.label)) {
      setOrderDetails("label", "labelType", defaultPayload);
      setOrderDetails("label", "labelDesignerIcon", data.labelDesignerIcon);
      setOrderDetails("label", "labelCliche", data.labelCliche);
      setTimeout(() => {
        setShouldRender(true);
      }, 500);
    } else {
      setTimeout(() => {
        setShouldRender(true);
      }, 500);
    }
  }, []);
  const openDrawer = React.useCallback((dataType, drawerTitle) => {
    // setIsVisible(false);
    console.log("This is datatype", dataType);
    setDataType(dataType);
    setDrawerTitle(drawerTitle);
    setIsVisible(true);
  }, []);
  const {
    label: { labelType, labelDesignerIcon, labelCliche, labelItemPrice },
  } = orderDetails;
  return shouldRender ? (
    <Grid container direction="column">
      <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={6}>
      <Box fontWeight="fontWeightBold" mt={6} mb={3}>
        <Typography
          style={{ fontWeight: "bold" }}
          color="primary"
          align="center"
          variant="h2"
        >
          &euro; {(price - wine.wineItemPrice).toFixed(2)}
        </Typography>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold", color: "#e2b50c" }}
          variant="h6"
        >
          Item Price: {labelItemPrice.toFixed(2)}&euro;
        </Typography>
      </Box>
      <Grid
        // onClick={() => {
        //   if (
        //     orderDetails.wine.wineType &&
        //     orderDetails.wine.wineType.value === "sulfites" &&
        //     orderDetails.wine.volume &&
        //     orderDetails.wine.volume.label === "0.75"
        //   ) {
        //     setPassData(data["labelType0.75NoSulfites"]);
        //     openDrawer("labelType", "Label Type");
        //   } else if (
        //     orderDetails.wine.wineType &&
        //     orderDetails.wine.wineType.value === "without_sulfites" &&
        //     orderDetails.wine.volume &&
        //     orderDetails.wine.volume.label === "0.75"
        //   ) {
        //     openDrawer("labelType", "Label Type");
        //     setPassData(data["labelType0.75Sulfites"]);
        //   } else if (
        //     orderDetails.wine.wineType &&
        //     orderDetails.wine.wineType.value === "sulfites" &&
        //     orderDetails.wine.volume &&
        //     orderDetails.wine.volume.label === "1.5"
        //   ) {
        //     setPassData(data["labelType1.5Sulfites"]);
        //     openDrawer("labelType", "Label Type");
        //   } else {
        //     openDrawer("labelType", "Label Type");
        //     setPassData(data["labelType"]);
        //   }
        // }}
        // className={classes.inputRow}
        onClick={()=>{
          // if (orderDetails?.bottle?.Container && orderDetails?.bottle?.Container?.label !== 'Tap to select' && orderDetails?.bottle?.Capacity?.label !== 'Tap to select' && orderDetails?.bottle?.noOfBottles) {
            db.collection('LabelOptions')
            .onSnapshot(otp =>{
            console.log('label data',LabelData.docs[0].data());
          let wineColorData = LabelData?.docs.filter(y => 
             y.data().container.toLowerCase() === orderDetails?.bottle?.Container?.label.toLowerCase() 
            && y?.data()?.capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label.toLowerCase() 
            && y?.data()?.Grape?.includes(orderDetails?.wine?.Grape?.label.toLowerCase())
           )
           console.log('wineColorData',wineColorData);
          
          let doto = [...new Map(wineColorData.map((m) => [m.data().brand, {label : m.data().brand , value: m.data().brand ,
            // imgUrl: otp.docs.find(x=> x?.data()?.itemName?.toLowerCase() ===  m?.data()?.brand?.toLowerCase() )?.data()?.imageLink  ,
            imgUrl:  m.data().dropdownimageLink,
            selectedImg : orderDetails?.bottle?.Container?.label.toLowerCase() === 'can'? m.data().canimgLink : orderDetails?.bottle?.Container?.label.toLowerCase() === 'bag in box' ? m.data().baginboximgLink  : orderDetails?.bottle?.bottleType?.label.toLowerCase() === "burgundy" ? m.data().burgundyimgLink : orderDetails?.bottle?.bottleType?.label.toLowerCase() === "bordeaux" ?   m.data().bordeauximgLink : orderDetails?.bottle?.bottleType?.label.toLowerCase().replaceAll(' ','') ===  "hautbrion" ? m.data().hautBrionimgLink : '',
             price : orderDetails?.bottle?.noOfBottles < 5000 ? m.data().five  : orderDetails?.bottle?.noOfBottles > 5000 && orderDetails?.bottle?.noOfBottles < 9000 ?  m.data().nine
             : orderDetails?.bottle?.noOfBottles > 9000 && orderDetails?.bottle?.noOfBottles < 13000 ? m.data().thirten 
             : m.data().ninten 
            }])).values()]
          console.log('ssssss doto', wineColorData);
         
          setPassData(doto);
          openDrawer("labelType", "Label Type");
          // }else{
          //   alert('Please select the container & capacity first')
          // }
            })
        }}
      >
        <InputTile
          value={labelType?.label}
          label={"Type"}
          price={labelType?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      {/* <Grid className={classes.inputRow}>
        <InputTile
          innerOnClick={() => {
            openDrawer("labelDesignerIconPrice", "Designer Icon Price");
          }}
          className={classes.inputRow}
          value={labelDesignerIconPrice}
          label={"Designer Icon Price"}
          price={""}
          showIcon={false}
          onChange={(a) => {
            updateValue("labelDesignerIconPrice", a);
          }}
          // showCounter={true}
        />
      </Grid> */}
      <br/>
      {/* <Box mb={1}>
        <FormControlLabel
          onChange={(e) => {
            if (
              orderDetails.wine.volume &&
              orderDetails.wine.volume.label === "1.5"
            ) {
            } else {
              updateValue("labelDesignerIcon", {
                value: e.target.checked,
                price: e.target.checked
                  ? labelDesignerIconPrice / noOfBottles
                  : 0,
              });
            }
          }}
          checked={labelDesignerIcon && labelDesignerIcon.value}
          control={
            <Checkbox
              color="primary"
              icon={<CheckCircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              name="checkedH"
            />
          }
          label={`Designer icon (${labelDesignerIconPrice} / number of bottles)`}
        />
      </Box> */}
      {/* <Box mb={3}>
        <FormControlLabel
          onChange={(e) => {
            if (
              orderDetails.wine.volume &&
              orderDetails.wine.volume.label === "1.5"
            ) {
            } else {
              updateValue("labelCliche", {
                value: e.target.checked,
                price: e.target.checked ? labelClichePrice / noOfBottles : 0,
              });
            }
          }}
          checked={labelCliche && labelCliche.value}
          control={
            <Checkbox
              color="primary"
              icon={<CheckCircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              name="checkedH"
            />
          }
          label="Cliche (400 / number of bottles)"
        />
      </Box> */}
     
      <Drawer
        drawerTitle={drawerTitle}
        isVisible={isVisible}
        field={dataType}
        onChange={(field, value) => {
          updateValue(field, value);
        }}
        customInput={dataType == "labelDesignerIconPrice" && numberInput}
        data={passData}
        MainTitle={'label'}
        onClose={onClose}
      />
    </Grid>
    
    <Grid item xs={3}>
     {orderDetails?.label?.labelType?.selectedImg ?
    //  <image style={{ backgroundImage: `url(require(${orderDetails?.bottle?.bottleType?.imgUrl}))` }}>
        <>
         <div 
       // width="500" height="600"
              style={{
              // background: `url(${orderDetails?.bottle?.bottleType?.imgUrl})`,
              marginTop:50,
              marginBottom:50,
              backgroundPosition: 'center',
              display: 'flex',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: 310 ,
              height: 310 ,
              // width: orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 300 : 100,
              // height:orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 380 : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? 210 : 360 ,
              justifyContent:"center",
              alignItems:"center",
              // backgroundColor:"red"

            }}>
          {
        orderDetails?.bottle?.Container?.imagebuilder &&  orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'?
        <img src={orderDetails?.bottle?.Container?.imagebuilder} alt="Uploaded Image" style={{height:220,width:220,marginBottom:20}} />
        :
        orderDetails?.label?.labelType?.selectedImg && orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? 
        <img src={orderDetails?.label?.labelType?.selectedImg} alt="Uploaded Image" style={{height:300,width:300,marginBottom:-20 ,marginTop:10}} />
        :
        orderDetails?.bottle?.Container?.imagebuilder && orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'?
        <img src={orderDetails?.bottle?.Container?.imagebuilder} alt="Uploaded Image" style={{height:300,width:300,marginBottom:20}} />
        
      :
         orderDetails?.bottle?.bottleColor?.builderImg?    
        <img src={orderDetails?.bottle?.bottleColor?.builderImg} alt="Uploaded Image"  style={{height:300,width:300, marginBottom:20}}  />
        :
      
      <></>}

        {/* <img src={"https://firebasestorage.googleapis.com/v0/b/custom-wine.appspot.com/o/adminPanelImages%2Fone.png%2F0.5267764827510162?alt=media&token=b4652962-12ed-41f7-a48a-841f0a5b7fb1"} alt="Uploaded Image" style={{height:350,width:100, marginBottom:20}} /> */}
        {orderDetails?.label?.labelType?.selectedImg && orderDetails?.bottle?.Container?.label?.toLowerCase() !== 'can'?
           <img src={orderDetails?.label?.labelType?.selectedImg} alt="Uploaded Image" style={{
            // height:orderDetails?.bottle?.bottleType?.label === 'burgundy' ? 100 : orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 350  : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? 250 : 160,
            // width:orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 300 : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? 125 : 98,
            height: orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 220 :300,
            width:orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 220 : 300,
            marginTop:orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle' ? 1 : orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? -19 : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? -10 : 1 , 
          
            // marginTop:orderDetails?.bottle?.bottleType?.label === 'burgundy' ? 170 : orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? -19 : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? -18 : 100 , 
            // marginLeft:orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? -2:0,
            position:"absolute"}} />
           :
           <></>}
           {/* <img src={"https://firebasestorage.googleapis.com/v0/b/custom-wine.appspot.com/o/adminPanelImages%2Fbanner.png%2F0.6410737699060272?alt=media&token=0a1e31be-c8e6-4ff6-baa7-2942041f5343"} alt="Uploaded Image" style={{height:120,width:90,marginTop:170, position:"absolute"}} /> */}
        
        </div>
        </>
        :
        <div 
        // width="500" height="600"
               style={{
               // background: `url(${orderDetails?.bottle?.bottleType?.imgUrl})`,
               marginTop:50,
               marginBottom:50,
               backgroundPosition: 'center',
               display: 'flex',
               backgroundSize: 'cover',
               backgroundRepeat: 'no-repeat',
               width: 310 ,
               height: 310 ,
               // width: orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 300 : 100,
               // height:orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 380 : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? 210 : 360 ,
               justifyContent:"center",
               alignItems:"center",
               // backgroundColor:"red"
 
             }}>
       { orderDetails?.bottle?.bottleColor?.builderImg &&  orderDetails?.bottle?.Container?.label?.toLowerCase() === 'bottle'?
        <img src={orderDetails?.bottle?.bottleColor?.builderImg} alt="Uploaded Image"  style={{height:300,width:300, marginTop:40}}  />
     
        :
        orderDetails?.bottle?.Container?.imagebuilder && orderDetails?.bottle?.Container?.label?.toLowerCase() === 'bag in box'?
        <img src={orderDetails?.bottle?.Container?.imagebuilder} alt="Uploaded Image" style={{height:220,width:220,marginBottom:-20}} />
        :
        orderDetails?.bottle?.Container?.imagebuilder && orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'?
        <img src={orderDetails?.bottle?.Container?.imagebuilder} alt="Uploaded Image" style={{height:300,width:300,marginBottom:-20, marginTop:10}} />
        :
        <></>}
        </div>
        }
      
        </Grid>
      </Grid>
      <NavigationButtons
        nextRoute={"/order/cap"}
        disablePrevious={false}
        orderDetails={orderDetails}
        page={"label"}
      />
    </Grid>
  ) : null;
};

export default Label;
