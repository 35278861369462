import { Link, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import SnackBar from "../components/SnackBar";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { signUpWithEmail } from "../Backend/Services/authService";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  dateParent: {
    padding: '7px 12px',
    backgroundColor: '#fbfbfb',
    border: '1px solid #efefef',
    borderRadius: '8px',
    marginBottom: '10px',
    '& .MuiFormControl-root': {
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  inputStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#fbfbfb',
      border: '1px solid #efefef',
      borderRadius: '8px',
      marginBottom: '10px',
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = React.useState({ email: "", password: "", firstName: '', lastName: '', birthday: '1990-01-01' });
  const [showSnackBar, setShowSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarVariant, setSnackBarVariant] = React.useState("success");
  const [isLoading, setIsLoading] = React.useState(false);

  const signUpHandler = (e) => {
    e.preventDefault();
    const { email, password } = data;
    setIsLoading(true)
    signUpWithEmail(email, password, data)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
          history.push("/")
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false);
        setShowSnackBar(true);
        setSnackBarVariant("error");
        setSnackBarMessage(err);
      });
  }

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div style={{ paddingTop: 10, textAlign: "center" }}>
            <img
              height="100px"
              src={require("../assets/images/cw-logo.png")}
              alt=""
            />
          </div>
          <Typography component="h1" variant="h5">
            Sign up
                </Typography>
          <form className={classes.form} onSubmit={signUpHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.inputStyle}
                  autoComplete="fname"
                  name="firstName"
                  variant="filled"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => onInputChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.inputStyle}
                  variant="filled"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => onInputChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.inputStyle}
                  variant="filled"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => onInputChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.inputStyle}
                  variant="filled"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputProps={{ disableUnderline: true }}
                  onChange={(e) => onInputChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
              <div className={classes.dateParent}>
                <TextField
                  className={classes.inputStyle}
                  onChange={(e) => onInputChange(e)}
                  id="date"
                  label="Birthday"
                  name="birthday"
                  type="date"
                  fullWidth
                  defaultValue="1990-01-01"
                />
                </div>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              Sign Up
                    </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link to="/">
                  Already have an account? Sign in
                            </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          message={snackBarMessage}
          onClose={() => setShowSnackBar(false)}
          variant={snackBarVariant}
          autoHideDuration={2000}
        />
      )}
    </>
  );
}