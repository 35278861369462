import Colors from '../../styles/Colors';
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        height: 5,
        width: '96%',
        position: 'absolute',
        // alignSelf:'center',
        left: 20,
        // top:30
    },
    colorPrimary: {
        backgroundColor: '#E9E9E9'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: Colors.appRed
    }
});

export default function LinearDeterminate(props) {
    const { value , style } = props;
    const classes = useStyles();

    return (
        <div className={style ? style : classes.root  } >
            <LinearProgress
                classes={{
                    root: classes.root,
                    colorPrimary: classes.colorPrimary,
                    bar: classes.bar
                }}
                variant="determinate"
                value={value}
            />
        </div>
    );
}
