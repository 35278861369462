import React, {useEffect} from 'react'

import firebase from "firebase";

export default function Logout() {
  useEffect(() => {firebase.auth().signOut().then(() => {
  }).catch((error) => {
  });}, [])
  return (
    <div>
      Logging Out...
    </div>
  )
}
