import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField
} from '@material-ui/core';
import React, { Component } from 'react'
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import firebase from 'firebase'
import {getCurrentUser} from '../Backend/Services/authService'

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPass: false,
      data: {},
    }
  }
  setShowPass = (s) => {
    this.setState({ showPass: s })
  }
  onSubmit = async (e) => {
    e.preventDefault();
    const { data } = this.state;
    if (data.new_password === data.confirm_password) {
      getCurrentUser().then(user => {
        user.updatePassword(data.new_password).then(function() {
          window.location.href = '/'
          // Update successful.
        }).catch(function(error) {
          window.alert(`An error occured while resetting the password. ${error.messaged}`)
          // An error happened.
        });
      })
    }
  }
  onInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { showPass } = this.state;

    return (
      <div className={classes.topPadding}>
        <form onSubmit={this.onSubmit}>
          <Grid id='header' className={classes.header} container spacing={0} alignItems='center'>
            <Grid item xs={2} lg={2} md={2}>
              <ChevronLeft onClick={() => { window.history.back(); }}></ChevronLeft>
            </Grid>
            <Grid item xs={8} lg={8} md={8} className={classes.imgTop}>
              <h3>Change Password</h3>
            </Grid>
            <Grid item xs={2} lg={2} md={2}>
            </Grid>
          </Grid>
          <Grid container className={classes.inputsWrapper} direction="row" justify="space-evenly" alignItems="center">
            <Grid container xs={12} lg={6} md={6} style={{ margin: '-15px' }} >
              <Grid className={classes.gridPadding} item xs={12} lg={12} md={12}>
                <TextField
                  name='current_password'
                  fullWidth
                  onChange={(e) => this.onInputChange(e)}
                  label="Current Password"
                  className={classes.inputStyle}
                  variant="filled"
                  type={showPass ? 'text' : 'password'}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => this.setShowPass(!showPass)}
                          edge="end"
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid className={classes.gridPadding} item xs={12} lg={12} md={12}>
                <TextField
                  name='new_password'
                  fullWidth
                  onChange={(e) => this.onInputChange(e)}
                  label="New Password"
                  className={classes.inputStyle}
                  variant="filled"
                  type={showPass ? 'text' : 'password'}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => this.setShowPass(!showPass)}
                          edge="end"
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid className={classes.gridPadding} item xs={12} lg={12} md={12}>
                <TextField
                  name='confirm_password'
                  fullWidth
                  onChange={(e) => this.onInputChange(e)}
                  label="Confirm Password"
                  className={classes.inputStyle}
                  variant="filled"
                  type={showPass ? 'text' : 'password'}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => this.setShowPass(!showPass)}
                          edge="end"
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Button
                className={classes.searchButton}
                size="medium"
                type="submit"
                color="secondary"
                variant="contained"
                style={{ marginBottom: 30, marginTop: 30, }}
              >
                Continue
            </Button>
            </Grid>
          </Grid>
        </form>

      </div>
    )
  }
}


const styles = theme => ({
  root: {
  },
  topPadding: {
    // marginTop: '20px',
    padding: '15px 0',
    overflowX: 'hidden',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      // marginTop: '70px',
    },
  },
  header: {
    padding: '0px 0px',
    borderBottom: '1px solid #f1f1f1',
    marginBottom: '20px',
  },
  dateParent: {
    padding: '7px 12px',
    backgroundColor: '#fbfbfb',
    border: '1px solid #efefef',
    borderRadius: '8px',
    marginBottom: '10px',
    '& .MuiFormControl-root': {
    }
  },

  inputStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#fbfbfb',
      border: '1px solid #efefef',
      borderRadius: '8px',
      marginBottom: '10px',
    },
  },
  settingRow: {
    padding: '0px 15px',
    borderBottom: '1px solid #e1e1e1',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#efefef',
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '10px',
      top: '15px',
    }
  },
  searchButton: {
    transition: "all 0.5s",
    textTransform: "capitalize",
    color: "#fff",
    backgroundColor: "#bd2132",
    fontWeight: 500,
    whiteSpace: "nowrap",
    borderRadius: 5,
    minWidth: "100%",
    border: "none",
    padding: "10px 20px",
    boxShadow: "2px 2px 9px 2px rgb(0 0 0 / 20%)",
    "&:hover": {
      transition: "all 0.5s",
      border: "none",
      borderColor: "none",
      backgroundColor: "#bd2132",
      boxShadow: "5px 5px 9px 2px rgb(0 0 0 / 20%)",
    },
  },
  roundedImg: {
    width: '35vw',
    height: '35vw',
    borderRadius: '50vw',
    border: '1px solid white',
    boxShadow: '1px 1px 5px 3px #ddd',
    marginBottom: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '20vw',
      height: '20vw',
    },

  },
  heading: { color: 'black', marginLeft: 10 },
  imgTop: { textAlign: 'center' },

  divider: {
    'background-color': '#f1f1f1',
    'height': '15px',
    'margin': '0 -15px',
    'width': '100000px',
    'margin-left': '-1000px',
  },
  button: {
    borderRadius: '8px',
    padding: '10px 20px',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  orderTile: {
    position: 'relative',
    borderRadius: '10px',
    // backgroundColor: '#fbfbfb',
    // border: '1px solid #efefef',
    padding: '5px 10px',
    textAlign: 'left',
    marginBottom: '10px',
    '& .arrow': {
      position: 'absolute',
      top: '15px',
      right: '10px',
      fontWeight: 'bold',
      color: '#bd2132'
    },
    '& .title': {
      fontSize: 12,
      color: '#999'
    },
    '& .detail': {
      color: '#bd2132'
    },

    '& .value': {
      color: '#333'
    },
    '& p': {
      marginTop: '8px',
      marginBottom: '8px',

    },
    '& h1': {
      color: 'white',
      textAlign: 'center',
      fontSize: '75px',
      marginBottom: '30px',
      marginTop: '10px',
      paddingTop: '15px',
    }
  },
  gridPadding: {
    padding: '0px 5px'
  },
  inputsWrapper: {
    padding: '15px'
  },
});

const OrderTile = withStyles(styles)((props) => (
  <div className={props.classes.orderTile}>
    <p className={'title'}>{props.orderName}</p>
    <p className={'value'}>{props.orderValue}</p>
    <div className='arrow'>
      <p className={'detail'}>{props.price}</p>
    </div>
  </div>
))



export default withStyles(styles)(OrderDetails);

