import { User } from '../Models/users'
import { db } from '../firebase';
import firebase from 'firebase'

export const createUser = async function (data) {
  return db.collection('Users').add(data).then((r) => {
  })
    .catch((er) => {
    })
};

export const getUserById = async function (id) {
  const query = await db.collection('Users').where("userId", '==', id).get();
  let Users = [];
  query.docs.forEach((doc) => {
    const user = User.fromFirestore(doc);
    if (user) {
      Users.push(user);
    }
  });
  return Users[0];
};

export const getAppData = async function () {
  const query = await db.collection('appData').get();
  let appData = {};
  query.docs.forEach((doc) => {
    const user = doc.data();
    if (user) {
      appData[doc.id] = doc.data();
    }
  });

  return appData;
};

export const uploadProfilePicture = (file, user_uuid, callback) => {
  var metadata = {
    contentType: 'image/jpeg'
  };
  var storageRef = firebase.storage().ref();
  var uploadTask = storageRef.child('images/' + file.name).put(file, metadata);

  // Listen for state changes, errors, and completion of the upload.
  return uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          window.alert('Picture Uploaded Successfuly')
          callback();
         return db.collection('Users').doc(user_uuid).set({profilePicture: downloadURL}, { merge: true });
      });
    }
  );
}