import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { Component , useRef} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import emailjs from '@emailjs/browser';

// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Drawer from "react-bottom-drawer";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { createOrder } from "../Backend/Services/orderService";
import { getCurrentUser } from "../Backend/Services/authService";
import {
  getUserById,
  uploadProfilePicture,
} from "../Backend/Services/userService";
import firebase, { functions } from "firebase";
import { withRouter } from "react-router-dom";
import axios from "axios";
import InputTile from "../components/InputTile";

const sgMail = require("@sendgrid/mail");
const cors = require("cors")({
  origin: true,
});

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
      winePrice: '',
      userData:'',
    };
  }
 
  getUserInfo = () => {
    getCurrentUser().then((a) => {
      this.setState({ currentUser: a }, () => {
        getUserById(a.uid).then((b) => {
          console.log("THis is user dta", b);
          this.setState({ userData: b });
        });
      });
    });
  };

  componentDidMount() {
    this.getUserInfo()
    this.user = firebase.auth().currentUser;
    console.log("THis is current user", firebase.auth().currentUser.email);
    let {
      location: { state },
      history,
    } = this.props;
    if (!state) {
      history.push("/home");
    }
  }

  
  generateOrderPayload = () => {
    const {
      location: {
        state: {
          orderDetails: { wine, bottle, label, cap, cork, wineCase },
          totalPrice,
        },
      },
    } = this.props;
    return {
      timestampAdded: new Date(),
      // wine
      // noOfBottles: wine.noOfBottles,
      // wineTaste: wine.wineTaste,
      wineType: wine.wineType,
      wineYear: wine.wineYear,
      wineItemPrice: wine.wineItemPrice,
      color:wine.Color,
      grape:wine.Grape,
      stillSparkling:wine.StillSparkling,
      woody:wine.Woody,
      tannins:wine.Tannins,
      fruity:wine.Fruity,
      sweetness:wine.Sweetness,
      complexity:wine.Complexity,
      TargetMarket:wine.TargetMarket,
      PriceRange:wine.PriceRange,



      // bottle
      bottleColor: bottle.bottleColor,
      bottleType: bottle.bottleType,
      container:bottle.Container,
      type:bottle.Type,
      capacity:bottle.Capacity,
      closing:bottle.Closing,
      howMany:bottle.noOfBottles,
      


      // volume: wine.volume,
      wineWeight: bottle.wineWeight,
      bottleItemPrice: bottle.bottleItemPrice,
      // label
      labelType: label.labelType,
      // labelDesignerIcon: label.labelDesignerIcon,
      // labelCliche: label.labelCliche,
      // labelItemPrice: label.labelItemPrice,
      // cap
      capColor: cap.capColor,
      capBrand: cap.Brand,
      capSize: cap.capSize,
      capType: cap.capType,
      // custome:cap.Custome,
      // capDesignerIcon: cap.capDesignerIcon,
      capItemPrice: cap.capItemPrice,
      // cork
      corkBrand: cork?.corkBrand ? cork?.corkBrand : '',
      corkSize: cork?.corkSize ? cork?.corkSize : '',
      corkType: cork?.corkType ? cork?.corkType :'',
      // corkItemPrice: cork.corkItemPrice,
      // wine case
      caseType: wineCase.caseType,
      caseCliche: wineCase.cartonType,
      ExtraPrinting:wineCase.ExtraPrinting,
      // wineCaseItemPrice: wineCase.wineCaseItemPrice,
      // price
      totalPrice,
      //user
      userId: this.user.uid,
      email: firebase.auth().currentUser.email,
    };
  };


    

  createOrderHandler = async () => {
     const orderData = this.generateOrderPayload();
    console.log('ssss orderData',orderData);
    // console.log('ssss orderData',orderData);
//     sgMail.setApiKey(
//       "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"
//     );
    let new_data = `
    <h4>Order Infomation</h4>
    <ul>
    <li>
    Name:  ${this.state.userData.firstName +' '+ this.state.userData.lastName} 
    </li>
    <li>
    Sender Email:  ${this.state.userData.email}
    </li>
    </ul>

    <h4>Order Infomation</h4>
    <ul>
    <li>
    Time: ${new Date()}</li>
    <li>
    NoOfBottles: ${orderData?.howMany},
   </li> <li>
    WineTaste:${orderData?.grape?.label},
    </li> <li>
    WineType: ${orderData?.wineType?.label},
    </li> <li>
    WineYear:${orderData.wineYear.label},
    </li>  <li>
    BottleColor: ${orderData?.bottleColor?.label},
    </li> <li>
    BottleType: ${orderData?.bottleType?.label},
    </li>
    <li>
    TargetMarket: ${orderData?.TargetMarket?.label},
    </li>
     <li>
    Volume: ${orderData?.capacity?.label},
    </li> <li>
    WineWeight: ${orderData?.wineWeight?.label},
    </li> <li>
    BottleItemPrice: ${orderData?.bottleItemPrice},
    </li> <li>
    LabelType: ${orderData?.labelType?.label},
    </li>
    
    
    <li>

    CapColor: ${orderData?.capColor?.label},
    </li> <li>
    CapSize: ${orderData.capSize.label},
    </li> <li>
    CapType: ${orderData.capType.label},
    </li> <li>
    CapItemPrice: ${orderData?.capItemPrice},
    </li>
    <li>
    ExtraPrinting: ${orderData?.ExtraPrinting?.label},
    </li> 
    <li>
    ExtraPrinting Price: ${orderData?.ExtraPrinting?.price},
    </li> 
     <li>

    CorkBrand: ${orderData?.corkBrand ? orderData?.corkBrand?.label : 'N/A' },
    </li> <li>
    CorkSize: ${orderData.corkSize ? orderData?.corkSize?.label : 'N/A'},
    </li> <li>
    CorkType: ${orderData.corkType ? orderData?.corkType?.label : 'N/A'},
    </li> <li>

    CaseType: ${orderData?.caseType?.label},
    </li> <li>
    CaseCliche: ${orderData?.caseCliche?.label},
    </li> <li>
    <h1
    style={{
      textAlign: "center",
      fontSize: "3.5rem",
      color: "#bd2132",
    }}
  >
    Packaging Price € ${(orderData.totalPrice.toFixed(2) - Number(orderData.wineType.price)).toFixed(2) }
  </h1>
  </li> <li>
  <h1
    style={{
      textAlign: "center",
      fontSize: "3.5rem",
      color: "#bd2132",
    }}
  >
    Wine Price  € ${(Number(orderData.wineType.price) * (Number(orderData.capacity.label.substring(0, orderData.capacity.label.length-3))/100)).toFixed(2)}
  </h1>
  </li> <li>
  <h1
    style={{
      textAlign: "center",
      fontSize: "3.5rem",
      color: "#bd2132",
    }}
  >
     Customer's Purchase Price:  €  
     ${
  Number(orderData.totalPrice.toFixed(2) + (((Number(orderData.wineType.price) * (Number(orderData.capacity.label.substring(0, orderData.capacity.label.length-3))/100)).toFixed(2))* Number(orderData.capacity.label.substring(0, orderData.capacity.label.length-3)))).toFixed(2)

     }

     
  </h1>
  </li> <li>
  <h1
    style={{
      textAlign: "center",
      fontSize: "3.5rem",
      color: "#bd2132",
    }}
  >
    Total Price for order €  
    

    
    ${(Number(orderData.wineType.price)).toFixed(2) * Number(orderData.howMany)}

  </h1>
    </li>
    </ul>
`
  console.log("This is order data in function", orderData);
  console.log("THis is great", orderData);
//   createOrder(orderData)
//       .then(() => {
// const msg = {
//   to: "ranaimrannaseem@yahoo.com",
//   from: "info@vinpop.fr",
//   subject: "New Order Placed",
//   text: "and easy to do anywhere, even with Node.js",
//   html: new_data,
// };
// console.log('00000----',msg);
// sgMail
//   .send(msg)
//   .then((res) => {
//     console.log('res',res);
//     this.setState({ showDrawer: true });
//   })
//   .catch((error) => {
//     console.log('log errr ok ',JSON.parse(JSON.stringify(error)));
//   });
//   })
//       .catch((err) => {
//         console.log("This is order data in function", err);
//       });
// this.setState({ showDrawer: true });
  
         
    // const orderData = this.generateOrderPayload();
    // console.log('ssss orderData',orderData);
    // createOrder(orderData)
      // .then(() => {
       
       
      // })
      // .catch((err) => {
      //   console.log("This is order data in function", err);
      // });


console.log('--======-->',new_data);
  createOrder(orderData)
      .then( async () => {
      try {
        const data = {
            email: 'ranaimrannaseem@yahoo.com'
        };
        await axios.post(
            // 'http://127.0.0.1:5001/custom-wine/us-central1/sendEmailWhenSignupRestaurant',
            'https://us-central1-custom-wine.cloudfunctions.net/sendEmailWhenSignupRestaurant'
            // 'http://us-central1-custom-wine.cloudfunctions.net/sendEmailWhenSignupRestaurant'
            , {
              body:new_data
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        this.setState({ showDrawer: true });
        // alert('Email sent successfully!');
    } catch (error) {
        // alert('Error sending email.');
        console.log('error======>',error);
    }
   })
      .catch((err) => {
        console.log("This is order data in function",err, JSON.parse(JSON.stringify(err)));
      });
  };

  render() {
    const {
      classes,
      history,
      location: {
        state: {
          orderDetails: { wine, bottle, label, cap, cork, wineCase },
          totalPrice,
        },
      },
    } = this.props;
    // console.log(this.props);
    const editableDetails = {
      wine: {
        wineType: wine.wineType,
      wineYear: wine.wineYear,
      wineItemPrice: wine.wineItemPrice,
      Color:wine.Color,
      Grape:wine.Grape,
      StillSparkling:wine.StillSparkling,
      Woody:wine.Woody,
      Tannins:wine.Tannins,
      Freshness:wine.Freshness,
      Fruity:wine.Fruity,
      Sweetness:wine.Sweetness,
      Acidity:wine.Acidity,
      Complexity:wine.Complexity,
      TargetMarket:wine.TargetMarket,
      PriceRange:wine.PriceRange,


      },
      bottle: {
        bottleColor: bottle.bottleColor,
      bottleType: bottle.bottleType,
      Container:bottle.Container,
      Type:bottle.Type,
      Capacity:bottle.Capacity,
      Closing:bottle.Closing,
      noOfBottles:bottle.noOfBottles,
        bottleItemPrice: bottle.bottleItemPrice,
        wineWeight: bottle.wineWeight,
        menufacturingPrice:bottle.Container === 'bottle' ? bottle.manuFacturingPrice.price : bottle.Container.Manuprice,

      },
      label: {
        labelType: label.labelType,
        labelDesignerIcon: label.labelDesignerIcon,
        labelCliche: label.labelCliche,
        labelItemPrice: label.labelItemPrice,
      },
      cap: {
        capColor: cap.capColor,
        capSize: cap.capSize,
        capType: cap.capType,
        Custome:cap.Custome,
        capItemPrice: cap.capItemPrice,
        Brand:cap.Brand,
      },
      cork: {
        // corkBrand: cork.corkBrand,
        // corkSize: cork.corkSize,
        // corkType: cork.corkType,
        // corkItemPrice: cork.corkItemPrice,
        corkBrand: cork?.corkBrand ? cork?.corkBrand : '',
      corkSize: cork?.corkSize ? cork?.corkSize : '',
      corkType: cork?.corkType ? cork?.corkType :'',
      corkItemPrice: cork.corkItemPrice,

      },
      wineCase: {
        caseType: wineCase.caseType,
        cartonType: wineCase.cartonType,
        wineCaseItemPrice: wineCase.wineCaseItemPrice,
        ExtraPrinting: wineCase.ExtraPrinting,
      },
    };
    return (
      <div className={classes.topPadding}>
        <Grid
          id="header"
          className={classes.header}
          container
          spacing={0}
          alignItems="center"
        >
          <Grid item xs={2} lg={2} md={2}>
            <ChevronLeft
              onClick={() => {
                window.history.back();
              }}
            ></ChevronLeft>
          </Grid>
          <Grid item xs={10} lg={10} md={10} className={classes.imgTop}>
            <p style={{ textAlign: "center", fontWeight: "bold" }}>
              Order Summary
            </p>
          </Grid>
          <Grid item xs={2} lg={2} md={2}></Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: "left" }}>Wine</h3>

            <OrderTile
              orderName={"Color "}
              orderValue={wine?.Color?.label}
            />
            <OrderTile
              orderName={"Grape"}
              orderValue={wine?.Grape?.label}
            />
            <OrderTile
              orderName={"Still/Sparkling"}
              orderValue={wine?.StillSparkling?.label}
            />
         
            <OrderTile
              orderName={"Type of Wine"}
              // price={wine?.wineType?.price ? `€ ${wine?.wineType?.price}` : ""}
              orderValue={wine?.wineType?.label}
            />
            <OrderTile
              orderName={"Target Market"}
              // price={wine?.TargetMarket?.label}
              orderValue={wine?.TargetMarket?.label}
            />
            <OrderTile
              orderName={"Price Range"}
              // price={wine?.PriceRange?.label}
              orderValue={wine?.PriceRange?.label}
            />
            <OrderTile orderName={"Year"} orderValue={wine?.wineYear?.label} />
             {wine?.Woody?.label?
            <OrderTile
              orderName={"Woody"}
              orderValue={wine?.Woody?.label}
            />:
            <></>}
            {wine?.Tannins?.label?
             <OrderTile
              orderName={"Tannins"}
              orderValue={wine?.Tannins?.label}
            />
            :
            <></>}
            {wine?.Freshness?.label?
             <OrderTile
              orderName={"Freshness"}
              orderValue={wine?.Freshness?.label}
            />
            :
            <></>}
            {wine?.Fruity?.label?
             <OrderTile
              orderName={"Fruitness"}
              orderValue={wine?.Fruity?.label}
            />
            :
            <></>}
            {wine?.Sweetness?.label?
             <OrderTile
              orderName={"Sweetness/Sugar"}
              orderValue={wine?.Sweetness?.label}
            />
            :
            <></>}
            {wine?.Acidity?.label?
             <OrderTile
              orderName={"Acidity"}
              orderValue={wine?.Acidity?.label}
            />
            :
            <></>}
            {wine?.Complexity?.label?
             <OrderTile
              orderName={"Complexity"}
              orderValue={wine?.Complexity?.label}
            />
            :
            <></>}
            {/* <OrderTile
              orderName={"Taste"}
              price={
                wine?.wineTaste?.price ? `€ ${wine?.wineTaste?.price}` : ""
              }
              orderValue={wine?.wineTaste?.label}
            /> */}
            {/* <OrderTile
              orderName={"Volume (ml)"}
              price={wine?.volume?.price ? `‎€ ${wine?.volume?.price}` : ""}
              orderValue={wine?.volume?.label}
            /> */}
          
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: "left" }}>Container</h3>
            <OrderTile
              orderName={"Container"}
              price={bottle?.Container === 'bottle' ? bottle?.bottleColor?.price : bottle.Container?.price}
              orderValue={bottle?.Container?.label}
            />
            <OrderTile
              orderName={"Type"}
              orderValue={bottle?.Type?.label}
            />
            <OrderTile
              orderName={"Capacity"}
              orderValue={bottle?.Capacity?.label}
            />
            <OrderTile
              orderName={"Closing"}
              orderValue={bottle?.Closing?.label}
            />
            <OrderTile
              orderName={"Shape"}
              price={
                bottle?.bottleType?.price
                  ? `€ ${bottle?.bottleType?.price}`
                  : ""
              }
              orderValue={bottle?.bottleType?.label}
            />
            <OrderTile
              orderName={"Color"}
              price={
                bottle?.bottleColor?.price
                  ? `€ ${bottle?.bottleColor?.price}`
                  : ""
              }
              orderValue={bottle?.bottleColor?.label}
            />
            <OrderTile
              orderName={"Weight"}
              price={
                bottle?.wineWeight?.price
                  ? `€ ${bottle?.wineWeight?.price}`
                  : ""
              }
              orderValue={bottle?.wineWeight?.label}
            />

            <OrderTile
              orderName={"Manufacturing Cost"}
              orderValue={"ManuFacturing Cost"}
              price={bottle.Container === 'bottle' ? bottle.manuFacturingPrice.price : bottle.Container.Manuprice}
            />
            
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: "left" }}>Label</h3>
            <OrderTile
              orderName={"Type"}
              price={
                label?.labelType?.price ? `€ ${label?.labelType?.price}` : ""
              }
              orderValue={label?.labelType?.label}
            />
            {/* <FormControlLabel
              checked={label?.labelDesignerIcon?.value}
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckCircleOutlineIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  name="checkedH"
                />
              }
              label="Designer (€ 2400 / number of bottles)"
            /> */}
            {/* <FormControlLabel
              checked={label?.labelCliche?.value}
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckCircleOutlineIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  name="checkedH"
                />
              }
              label="Cliché (€ 400 / number of bottles)"
            /> */}
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: "left" }}>Closing</h3>
            <OrderTile
              orderName={"Brand"}
              price={
                cork?.corkBrand?.price ? `€ ${cork?.corkBrand?.price}` : ""
              }
              orderValue={cork?.corkBrand?.label}
            />
            <OrderTile
              orderName={"Type"}
              price={cork?.corkType?.price ? `€ ${cork?.corkType?.price}` : ""}
              orderValue={cork?.corkType?.label}
            />
            <OrderTile
              orderName={"Size"}
              price={cork?.corkSize?.price ? `€ ${cork?.corkSize?.price}` : ""}
              orderValue={cork?.corkSize?.label}
            />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: "left" }}>Cap</h3>
            <OrderTile
              orderName={"Cap Type"}
              // price={cap?.capItemPrice ? `€ ${cap?.capItemPrice}` : ""}
              orderValue={cap?.capType?.label}
            />
            <OrderTile
              orderName={"Cap Size"}
              price={cap?.capSize?.price ? `€ ${cap?.capSize?.price}` : ""}
              orderValue={cap?.capSize?.label}
            />
             <OrderTile
              orderName={"Brand"}
              orderValue={cap?.Brand?.label}
            />
            <OrderTile
              orderName={"Cap Color"}
              price={cap?.capColor?.price ? `€ ${cap?.capColor?.price}` : ""}
              orderValue={cap?.capColor?.label}
            />
           

            {/* <FormControlLabel
              checked={cap?.capDesignerIcon.value}
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckCircleOutlineIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  name="checkedH"
                />
              }
              label="Designer Icon (€ 1400 / number of bottles)"
            /> */}
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: "left" }}>Wine Case</h3>
            <OrderTile
              orderName={"Type"}
              orderValue={wineCase?.cartonType?.label}
            />
            <OrderTile
              orderName={"Formate"}
              price={
                wineCase?.caseType?.price
                  ? `€ ${wineCase?.caseType?.price}`
                  : ""
              }
              orderValue={wineCase?.caseType?.label}
              
            />
            <OrderTile
              orderName={"Extra Printing"}
              price={
                wineCase?.ExtraPrinting?.price
                  ? `€ ${wineCase?.ExtraPrinting?.price}`
                  : ""
              }
              orderValue={wineCase?.ExtraPrinting?.label}
            />
            

<h1
              style={{
                textAlign: "center",
                fontSize: "3.5rem",
                color: "#bd2132",
              }}
            >
            {/* € {Number(wine.wineType.price) * (Number(bottle.Capacity.label.substring(0, bottle.Capacity.label.length-3))/100 )} */}
            </h1>
            <h1
              style={{
                textAlign: "center",
                fontSize: "3.5rem",
                color: "#bd2132",
              }}
            >
            Wine Price  : € {(Number(wine.wineType.price) * (Number(bottle.Capacity.label.substring(0, bottle.Capacity.label.length-3))/100)).toFixed(2)}
            </h1>
         


<h1
              style={{
                textAlign: "center",
                fontSize: "3.5rem",
                color: "#bd2132",
              }}
            >
             Packaging : €{(totalPrice.toFixed(2) - Number(wine.wineType.price)).toFixed(2)}
            </h1>

       
                <div style={{flexDirection:"row",marginTop:80}}>
                         <h3 style={{ marginLeft:12,marginBottom:1 }}>
                         Select the price of your product wanted € :
                        </h3>
                        <div style={{ padding: "10px 20px 10px 10px" }}>
        <input
          // ref={inputEl}
          onBlur={(e) => {
            var number = Number(e.target.value);
          }}
          onChange={(e)=> {
            // if(Number(e.target.value) < 1.33){
            //   this.setState({ winePrice: 1.33 })
            // }else{
            this.setState({ winePrice: e.target.value.replace(',','.') })
          // }
          }
          }
          type="number"
          style={{
            width: "100%",
            alignSelf:"center",
            height: "50px",
            borderRadius: "7px",
            paddingLeft: 10,
            border: "1px solid #ddd",
          }}
          placeholder="1.33"
        />
      </div>
                    </div> 
                    {/* <h1
              style={{
                textAlign: "center",
                fontSize: "3.5rem",
                color: "#bd2132",
              }}
            >
              Total Price for order €{" "}
              {(totalPrice * Number(bottle?.noOfBottles)).toFixed(2)}
            </h1> */}
            {/* <FormControlLabel
              checked={wineCase?.caseCliche}
              control={
                <Checkbox
                  color="primary"
                  icon={<CheckCircleOutlineIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  name="checkedH"
                />
              }
              label="Cliché (€ 600 / number of bottles)"
            /> */}
           

            {/* <div className={classes.divider}></div> */}
          </Grid>
        </Grid>
        <Grid
          spacing={3}
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <div style={{ marginBottom: 20, width: "100%" }}></div>
          <Grid item xs={12} lg={6} md={6}></Grid>
        </Grid>
        <Grid
          spacing={3}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={6} lg={3} md={3}>
            <Link
              to={{
                pathname: "/order/wine",
                state: { editable: editableDetails },
              }}
            >
              <Button
                style={{ backgroundColor: "#fafafa" }}
                color="primary"
                fullWidth
                className={classes.button}
              >
                Edit Selection
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            {/* <Link to="/order/wine"> */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                const orderData = this.generateOrderPayload();
                console.log('ssss orderData',orderData);
                console.log("This is it");
                if(this.state.winePrice && Number(this.state.winePrice) 
                >= 
                ((Number(totalPrice) - Number(wine.wineType.price))
                + 
                (Number(wine.wineType.price) * (Number(bottle.Capacity.label.substring(0, bottle.Capacity.label.length-3))/100))).toFixed(2) 
 ){
                this.createOrderHandler();
      
                }else{
                  alert('wine price must greater then  €'+
                  ((Number(totalPrice) - Number(wine.wineType.price))
                  + 
                  (Number(wine.wineType.price) * (Number(bottle.Capacity.label.substring(0, bottle.Capacity.label.length-3))/100))).toFixed(2)                            
  )
                }
              }}
              className={classes.button}
            >
              Confirm quote request
            </Button>
            {/* </Link> */}
          </Grid>
        </Grid>
        <Drawer
          isVisible={this.state.showDrawer}
          onClose={() => {
            this.setState({ showDrawer: false });
          }}
        >
          <div style={{ textAlign: "center" }}>
            <CheckCircleIcon
              style={{ fontSize: "5rem" }}
              color="primary"
            ></CheckCircleIcon>
            <h2>Order Confirmed</h2>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginBottom: 30 }}
              onClick={() => history.push("/home")}
              className={classes.button}
            >
              Continue
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {},
  topPadding: {
    padding: "15px",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      // marginTop: '70px',
    },
  },
  header: {
    borderBottom: "1px solid #f1f1f1",
  },
  roundedImg: {
    width: 50,
    height: 50,
    borderRadius: 50,
    border: "1px solid white",
    boxShadow: "1px 1px 5px 3px #ddd",
  },
  heading: { color: "black", marginLeft: 10 },
  imgTop: { textAlign: "right" },
  hudTile: {
    borderRadius: "10px",
    height: "165px",
    backgroundColor: "#bd2132",
    boxShadow: "4px 4px 17px 4px rgb(190 33 49 / 30%)",
    "& p": {
      color: "white",
      textAlign: "center",
    },
    "& h1": {
      color: "white",
      textAlign: "center",
      fontSize: "75px",
      marginBottom: "0",
      marginTop: "15px",
      paddingTop: "0",
    },
  },
  divider: {
    "background-color": "#f1f1f1",
    height: "15px",
    margin: "0 -15px",
    width: "100000px",
    "margin-left": "-1000px",
  },
  button: {
    borderRadius: "8px",
    padding: "10px 20px",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  orderTile: {
    position: "relative",
    borderRadius: "10px",
    backgroundColor: "#fbfbfb",
    border: "1px solid #efefef",
    padding: "5px 10px",
    textAlign: "left",
    marginBottom: "10px",
    "& .arrow": {
      position: "absolute",
      top: "15px",
      right: "10px",
      fontWeight: "bold",
      color: "#bd2132",
    },
    "& .title": {
      fontSize: 12,
      color: "#999",
    },
    "& .detail": {
      color: "#bd2132",
    },

    "& .value": {
      color: "#333",
    },
    "& p": {
      marginTop: "8px",
      marginBottom: "8px",
    },
    "& h1": {
      color: "white",
      textAlign: "center",
      fontSize: "75px",
      marginBottom: "30px",
      marginTop: "10px",
      paddingTop: "15px",
    },
  },
});

const HudTile = withStyles(styles)((props) => (
  <div className={props.classes.hudTile}>
    <h1>{props.count}</h1>
    <p>{props.title}</p>
  </div>
));

const OrderTile = withStyles(styles)((props) => (
  <div className={props.classes.orderTile || ""}>
    <p className={"title"}>{props.orderName || ""}</p>
    <p className={"value"}>{props.orderValue || ""}</p>
    <div className="arrow">
      <p className={"detail"}>{props.price || ""}</p>
    </div>
  </div>
));

export default withRouter(withStyles(styles)(OrderDetails));
