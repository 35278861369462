import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import Drawer from "react-bottom-drawer";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PriceContext } from "./PriceContext";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    marginTop: theme.spacing(3),
  },
  drawerParent: {},
  imgClass: {
    width: "100%",
    padding: "20px 0px",
    height: "200px",
    objectFit: "contain",
  },
  imgParent: {
    width: "45%",
    display: "inline-block",
    padding: "5px 8px",
    maxWidth: "200px",
  },
  searchButton: {
    marginBottom: 20,
    marginTop: 20,
    transition: "all 0.5s",
    textTransform: "capitalize",
    color: "#fff",
    backgroundColor: "#bd2132",
    fontWeight: 500,
    whiteSpace: "nowrap",
    borderRadius: 8,
    minWidth: "100%",
    border: "none",
    padding: "10px 20px",
    fontWeight: "bold",
    boxShadow: "2px 2px 9px 2px rgb(0 0 0 / 20%)",
    "&:hover": {
      transition: "all 0.5s",
      border: "none",
      borderColor: "none",
      backgroundColor: "#bd2132",
      boxShadow: "5px 5px 9px 2px rgb(0 0 0 / 20%)",
    },
  },
  customRadioInput: {
    padding: "5px 10px",
    marginBottom: 10,
    backgroundColor: "#fbfbfb",
    border: "1px solid #e1e1e1",
    borderRadius: "8px",
    position: "relative",
    "& .MuiFormControlLabel-labelPlacementStart": {
      width: "100%",
    },
    "& .MuiTypography-body1": {
      position: "absolute",
      left: "10px",
    },
    "& p": {
      position: "absolute",
      right: "50px",
      top: "0px",
      color: "#bd2132",
      fontWeight: "bold",
    },
    "& .MuiIconButton-label": {
      paddingRight: "10px",
    },
  },
}));

function DrawerSlider(props) {
  const {
    price,
    setPrice,
    orderDetails,
    setOrderDetails,
    appData,
  } = React.useContext(PriceContext);
  const { isVisible, onClose, data = [], customInput,MainTitle,field } = props;
  console.log("This is the data", data);
  const CustomInput = customInput;
  const [value, handleChange] = React.useState(null);

  const classes = useStyles();

  let DrawerSelected = orderDetails && orderDetails?.MainTitle?.Title

  console.log('DrawerSelected',(orderDetails[MainTitle])[field]);

  return (
    <div className={classes.drawerParent}>
      <Drawer
        isVisible={isVisible}
        onClose={() => {
          onClose();
          // handleChange(null);
        }}
      >
        <div style={{ padding: "20px 10px", marginBottom: 20 }}>
          <h1 style={{ textAlign: "center" }}>{props.drawerTitle}</h1>
          {/* <h3 style={{ textAlign: "center",color:"red" }}>{ props.suggest !== '' && props.suggest !== undefined ? 'Suggested value '+ props.suggest : ''}</h3> */}

          <>
            {customInput ? (
              <CustomInput></CustomInput>
            ) : (
              <>
                <FormControl fullWidth component="fieldset">
                  <RadioGroup
                    aria-label="wine"
                    name="wine"
                    value={value}
                    onChange={(e) => {
                      props.onChange(props.field, data[e.target.value]);
                      handleChange(null);
                    }}
                  >
                    {data.map((item, index) => {
                      return (
                        !item.imgUrl && (
                          <div className={classes.customRadioInput}  style={{ border: props?.suggest && props?.suggest === item.label ? "1px solid #FF0000" :   "1px solid #e1e1e1"}}>
                            {item.price && <p>&euro; {item.price}</p>}
                            <FormControlLabel
                              fullWidth
                              labelPlacement="start"
                              value={index}
                              control={ (orderDetails[MainTitle])[field]?.label === item.label ?   <CheckCircleIcon style={{marginRight:20,marginTop:9,marginBottom:9}} /> : <Radio color="primary" />}
                              label={item.label}
                            />
                          </div>
                        )
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {data.map(
                  (item, index) =>
                    item.imgUrl && (
                      <div
                        onClick={() => {
                          props.onChange(props.field, item);
                          handleChange(item);
                          console.log('sss------color');
                        }}
                        className={classes.imgParent}
                      >
                        <img
                          className={classes.imgClass}
                          style={{
                            borderRadius: 20,
                            border:
                              DrawerSelected?.label == item?.value
                                ? "3px solid #bd3132"
                                : "3px solid #efefef",
                          }}
                          src={item.imgUrl}
                          alt={item.value}
                        />
                        <p
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "5px",
                            marginBottom: "7px",
                          }}
                        >
                          {item.label}
                        </p>
                        {item.price && (
                          <h4
                            style={{
                              color: "#bd3132",
                              textAlign: "center",
                              marginTop: 5,
                            }}
                          >
                            &euro; {item.price}
                          </h4>
                        )}
                      </div>
                    )
                )}
              </>
            )}
          </>

          <Button
            className={classes.searchButton}
            type="submit"
            size="medium"
            color="secondary"
            variant="contained"
            onClick={() => {
              onClose();
              // handleChange(null);
            }}
          >
            Done
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default React.memo(DrawerSlider);
