import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { PriceContext, isEmpty } from "./PriceContext";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Drawer from "./Drawer";
import InputTile from "../../components/InputTile";
import NavigationButtons from "../../components/NavigationButtons";
import React from "react";
import { humanize } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";

import { createwineData } from "../../Backend/Services/orderService";
import { db } from "../../Backend/firebase";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    marginTop: theme.spacing(3),
  },
  image_Blue: {
    filter: 'blue',
  },
  image_Green: {
    filter: 'green',
  },
  image_Red: {
    filter: 'red',
  }
}));

const capDesignerIconPrice = 350;

const defaultPayload = {
  label: "Tap to select",
  value: "tap_to_select",
};

// const typeDepSize = {
//   'Aluminium': [{
//     value: 'Short',
//     label: 'Short',
//     price: '5.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Medium',
//     label: 'Medium',
//     price: '1.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Long',
//     label: 'Long',
//     price: '2.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },],
//   'Etain': [
//     { value: 'Short', label: 'Short', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' }
//   ],
//   'Tin': [
//     { value: 'Medium', label: 'Medium', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//     { value: 'Long', label: 'Long', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' }
//   ],
//   'Wax': [{
//     value: 'Short',
//     label: 'Short',
//     price: '5.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Medium',
//     label: 'Medium',
//     price: '1.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Long',
//     label: 'Long',
//     price: '2.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },],
// }

// const sizeDepLook = {
//   'Short': [
//     { value: 'Neutral', label: 'Neutral', price: '1.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//     { value: 'New Label', label: 'New Label', price: '1.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//   ],
//   'Medium': [
//     { value: 'Neutral', label: 'Neutral', price: '1.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//     { value: 'New Label', label: 'New Label', price: '1.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//   ],
//   'Long': [{
//     value: 'Neutral',
//     label: 'Neutral',
//     price: '1.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Cheteau_Surain',
//     label: 'Cheteau Surain',
//     price: '2.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'News_Drinker',
//     label: 'News Drinker',
//     price: '3.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'New Design',
//     label: 'New Design',
//     price: '3.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },]
// }

// const data = {
//   capType: [{
//     value: 'Aluminium',
//     label: 'Aluminium',
//     price: '1.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Etain',
//     label: 'Etain',
//     price: '2.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Tin',
//     label: 'Tin',
//     price: '3.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   }, ,
//   {
//     value: 'Wax',
//     label: 'Wax',
//     price: '4.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   }],
//   capSize: typeDepSize['Aluminium'],
//   capColor: [{
//     value: 'Red',
//     label: 'Red',
//     price: '3.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Golden',
//     label: 'Golden',
//     price: '4.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Silver',
//     label: 'Silver',
//     price: '5.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   }],

//   capLook: sizeDepLook[typeDepSize['Aluminium'][0].value],
//   capDesignerIcon: false,
// }

const Cap = () => {
  const classes = useStyles();
  const { price, setPrice, orderDetails, setOrderDetails, appData } =
    React.useContext(PriceContext);
  let {
    wine: { noOfBottles = 1 },
  } = orderDetails;
  const { volume, wine } = orderDetails;


  console.log("These are order", orderDetails);
  console.log("These are capdata", appData);
  const newCapData = appData.capDataNew.array;
  const [bottleCapType , setBottleCapType] = React.useState([]);
  const [bottleCapSize , setBottleCapSize] = React.useState([]);
  const [bottleCapColor , setBottleCapColor] = React.useState([]);
  const [bottleCapCustome , setBottleCapCustome] = React.useState([]);
  const [wineAllData, setWineAllData] = React.useState([]);

  const GetBottleTypeo = () =>{
    console.log('ssssss doto');
    let doData =  db.collection('Cap')
    .onSnapshot(x=>{
      setWineAllData(x)
    let doto = [...new Map(x.docs.map((m) => [m.data().type, {label : m.data().type , value: m.data().type, price: m.data()?.price}])).values()]
    console.log('ssssss doto', doto);
    setBottleCapType(doto);

    let Bweight = [...new Map(x.docs.map((m) => [m.data().size, {label : m.data().size , value: m.data().size}])).values()]
    console.log('ssssss doto', Bweight);
    setBottleCapSize(Bweight);

    let Bcolor = [...new Map(x.docs.map((m) => [m.data().capColour, {label : m.data().capColour , value: m.data().capColour}])).values()]
    console.log('ssssss doto', Bcolor);
    setBottleCapColor(Bcolor);

    let Customecolor = [...new Map(x.docs.map((m) => [m.data().custom, {label : m.data().custom , value: m.data().custom}])).values()]
    setBottleCapCustome(Customecolor);
    })
  }

  React.useEffect(()=>{
  
    GetBottleTypeo();
  },[])

  const getCapSizes = () => {
    const capSizes = [];
    if (
      orderDetails.label.labelType &&
      orderDetails.label.labelType.value != "tap_to_select"
    ) {
      if (orderDetails.cap.capType) {
        newCapData &&
          newCapData.map((cap) => {
            if (
              orderDetails.cap.capType.label === cap.capType &&
              orderDetails.label.labelType.label === cap.labelType
            ) {
              var alreadyExist = false;
              capSizes.map((cs) => {
                if (cs.value === cap.size) {
                  alreadyExist = true;
                }
              });
              if (!alreadyExist) {
                // cap.value = cap.size;
                // cap.label = cap.size;
                // cap.price = "";
                capSizes.push({
                  value: cap.size,
                  label: cap.size + " mm",
                  price: "",
                });
              }
            } else {
              // console.log("Culprits", orderDetails.cap.capType.label);
              // console.log(
              //   "orderDetails.cap.capSize.label",
              //   orderDetails.label.labelType.label
              // );
              // alert("Something is wrong!");
            }
          });
        setPassData(capSizes);
        openDrawer("capSize", "Cap Size");
      } else {
        alert("Please select a cap type!");
      }
    } else {
      alert("Please select a label!");
    }

    console.log("This is cap sizes", capSizes);
  };

  const getLabels = () => {
    var capLabels = [];
    if (orderDetails.cap.capType && orderDetails.cap.capSize) {
      console.log("This is");
      appData.capDataNew &&
        newCapData.map((cap) => {
          if (
            orderDetails.cap.capType.label === cap.capType &&
            orderDetails.cap.capSize.value === cap.size
          ) {
            var alreadyExist = false;
            capLabels.map((cs) => {
              if (cs.customization === cap.customization) {
                alreadyExist = true;
              }
            });
            if (!alreadyExist) {
              if (orderDetails.wine.noOfBottles) {
                var bottleCount = Number(orderDetails.wine.noOfBottles);
                console.log("This is bottle count", bottleCount);

                if (
                  bottleCount >= cap.min &&
                  bottleCount <= cap.max &&
                  orderDetails.label.labelType.label === cap.labelType
                ) {
                  // cap.value = cap.customization;
                  // cap.label = cap.customization;
                  capLabels.push({
                    value: cap.customization,
                    label: cap.customization,
                    price: cap?.price,
                    imgUrl: cap.imgUrl,
                    cliche: cap.cliche,
                  });
                  console.log("THis is cap.lable", cap);
                  // alert("it is coming here");
                } else {
                  // console.log("captype label", orderDetails.cap.capType.label);
                  // console.log(
                  //   "orderDetails.cap.capSize.label",
                  //   orderDetails.cap.capSize.label
                  // );
                  // if (orderDetails.cap.capType.label === "Aluminium") {
                  //   console.log("This is cap ", cap);
                  // }
                }
              } else {
                alert("Please select number of bottles!");
              }
            }
          }
        });

      setPassData(capLabels);
      openDrawer("capLook", "Cap Look");
    } else {
      alert("Please select Cap Type and Cap Size");
    }
  };

  const getColors = () => {
    var colorData = [];
    if (orderDetails.cap.capType) {
    
        // if (orderDetails.cap.capLook.label === "Neutral") {
        //   colors.map((color) => {
        //     if (orderDetails.cap.capType.label === color.capType) {
        //       color.value = color.color;
        //       color.label = color.color;
        //       colorData.push(color);
        //     }
        //   });
        // } else {
        //   updateValue("capColor", {
        //     value: "Not Applicable",
        //     label: "Not Applicable",
        //   });
        // }

        // setPassData(colorData);
        setPassData(bottleCapColor)
        openDrawer("capColor", "Cap Color");
     
    } else {
      alert("Please select a cap type!");
    }
  };

  const capTypes = [
    {
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",

      value: "Aluminium",
      label: "Aluminium",
    },
    {
      label: "Tin",

      value: "Tin",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      label: "Wax",

      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
      value: "Wax",
    },
  ];
  const colors = [
    {
      capType: "Aluminium",
      color: "Red",
    },
    {
      capType: "Aluminium",
      color: "Golden",
    },
    {
      capType: "Aluminium",
      color: "Silver",
    },
    {
      capType: "Tin",
      color: "Red",
    },
    {
      capType: "Tin",
      color: "Golden",
    },
    {
      capType: "Tin",
      color: "Silver",
    },

    {
      capType: "Wax",
      color: "Red",
    },
    {
      capType: "Wax",
      color: "Golden",
    },
    {
      capType: "Wax",
      color: "Silver",
    },
  ];

  const capData = [
    {
      capType: "Aluminium",
      labelType: "News Drinker",
      size: "60",
      colorOptions: "no",
      customization: "News Drinker",
      min: 8000,
      max: 12000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "News Drinker",
      size: "60",
      colorOptions: "no",
      customization: "News Drinker",
      min: 12001,
      max: 19000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "Popcorn",
      size: "60",
      colorOptions: "no",
      customization: "Popcorn",
      min: 8000,
      max: 12000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "Popcorn",
      size: "60",
      colorOptions: "no",
      customization: "Popcorn",
      min: 12001,
      max: 19000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "Chateau Surain",
      size: "60",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 8000,
      max: 12000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "Chateau Surain",
      size: "60",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 12001,
      max: 19000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Aluminium",
      labelType: "New Label",
      size: "35",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.08,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "New Label",
      size: "35",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.07,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Aluminium",
      labelType: "New Label",
      size: "55",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "New Label",
      size: "55",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Aluminium",
      labelType: "New Label",
      size: "60",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Aluminium",
      labelType: "New Label",
      size: "60",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.05,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "35",
      colorOptions: "no",
      customization: "News Drinker",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "35",
      colorOptions: "no",
      customization: "News Drinker",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "55",
      colorOptions: "no",
      customization: "News Drinker",
      min: 8000,
      max: 12000,
      price: 0.28,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "55",
      colorOptions: "no",
      customization: "News Drinker",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "60",
      colorOptions: "no",
      customization: "News Drinker",
      min: 8000,
      max: 12000,
      price: 0.06,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "60",
      colorOptions: "no",
      customization: "News Drinker",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "35",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "35",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "55",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "55",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "60",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "News Drinker",
      size: "60",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "35",
      colorOptions: "no",
      customization: "Popcorn",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "35",
      colorOptions: "no",
      customization: "Popcorn",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "55",
      colorOptions: "no",
      customization: "Popcorn",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "55",
      colorOptions: "no",
      customization: "Popcorn",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "60",
      colorOptions: "no",
      customization: "Popcorn",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "60",
      colorOptions: "no",
      customization: "Popcorn",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "35",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "35",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "55",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "55",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "60",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Popcorn",
      size: "60",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "35",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "35",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "55",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "55",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "60",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "60",
      colorOptions: "no",
      customization: "Chateau Surain",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "35",
      colorOptions: "no",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: 350,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "35",
      colorOptions: "no",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: 350,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "55",
      colorOptions: "no",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "55",
      colorOptions: "no",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "60",
      colorOptions: "no",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "Chateau Surain",
      size: "60",
      colorOptions: "no",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "New Label",
      size: "35",
      colorOptions: "no",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "New Label",
      size: "35",
      colorOptions: "no",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "New Label",
      size: "55",
      colorOptions: "no",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "New Label",
      size: "55",
      colorOptions: "no",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Tin",
      labelType: "New Label",
      size: "60",
      colorOptions: "no",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Tin",
      labelType: "New Label",
      size: "60",
      colorOptions: "no",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "News Drinker",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.25,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "News Drinker",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.25,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "News Drinker",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.22,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "News Drinker",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.22,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "Popcorn",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "Popcorn",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "Popcorn",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "Popcorn",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "Chateau Surain",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "Chateau Surain",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "Chateau Surain",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "Chateau Surain",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "New Label",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "New Label",
      size: "10",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },

    {
      capType: "Wax",
      labelType: "New Label",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 8000,
      max: 12000,
      price: 0.23,
      cliche: null,
      imgUrl: "",
    },
    {
      capType: "Wax",
      labelType: "New Label",
      size: "30",
      colorOptions: "yes",
      customization: "Neutral",
      min: 12001,
      max: 19000,
      price: 0.2,
      cliche: null,
      imgUrl: "",
    },
  ];

  //appData stuff
  const data = Object.assign({}, appData.capData);
  const sizeDepLook = Object.assign({}, appData.sizeDepLook);
  const typeDepSize = Object.assign({}, appData.typeDepSize);

  // data.capLook = sizeDepLook[typeDepSize["Aluminium"][0].value];
  // // console.log("This is cap look", capLookdata.z);
  // data.capSize = typeDepSize["Aluminium"];
  // ////////////////////////////

  //Drawer Stuff
  const [drawerTitle, setDrawerTitle] = React.useState("wineType");
  const [isVisible, setIsVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState("wineType");
  const [shouldRender, setShouldRender] = React.useState(false);
  const [passData, setPassData] = React.useState([]);

  //SPECS
  // const [capType, setCapType] = React.useState('Aluminium');
  // const [capSize, setCapSize] = React.useState('Medium');
  // const [capColor, setCapColor] = React.useState('Red');
  // const [capLook, setCapLook] = React.useState('Neutral');

  React.useEffect(() => {
    if (
      orderDetails.label.labelType &&
      orderDetails.label.labelType.value === "New Label"
    ) {
      setOrderDetails("cap", "capDesignerIcon", true);
    } else {
      setOrderDetails("cap", "capDesignerIcon", false);
    }
    // createwineData("appData", "capDataNew", capData, [])
    //   .then(() => {
    //     // this.setState({ showDrawer: true });
    //     alert("Data was added successifull");
    //   })
    //   .catch((e) => {
    //     console.log("This is great", e);
    //     alert("what the hell is happening");
    //   })
    //   .catch((err) => {});
    if (isEmpty(orderDetails.cap)) {
      setOrderDetails("cap", "capSize", defaultPayload);
      setOrderDetails("cap", "capType", defaultPayload);
      setOrderDetails("cap", "capColor", defaultPayload);
      setOrderDetails("cap", "capLook", defaultPayload);
      setOrderDetails("cap", "capDesignerIcon", data.capDesignerIcon);
      setOrderDetails("cap", "Brand", defaultPayload);

      setTimeout(() => {
        setShouldRender(true);
      }, 700);
    } else {
      setTimeout(() => {
        setShouldRender(true);
        newCapData.map((c) => {
          if (c.capType === "Wax") {
            console.log("This is cap", c);
          }
        });
      }, 700);
    }
  }, []);

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const updateValue = (field, value) => {
    if (value) {
      // if (field === "capType") {
      //   data.capSize = typeDepSize[value.value];
      //   if (value.value != "New Label") {
      //     updateValue("capSize", typeDepSize[value.value][2]);
      //   } else {
      //     updateValue("capSize", typeDepSize[value.value][0]);
      //   }
      // }
      // if (field === "capSize") {
      //   if (value.value != "New Label") {
      //     updateValue("capLook", orderDetails.label.labelType);
      //   } else {
      //     updateValue("capLook", sizeDepLook[value.value][0]);
      //   }
      // }
      if (field === "capLook" && value.cliche != null) {
        updateValue("capDesignerIcon", {
          value: true,
          price: capDesignerIconPrice / noOfBottles,
        });
      } else if (field === "capLook" && value.cliche === null) {
        updateValue("capDesignerIcon", { value: false, price: 0 });
      }
      setOrderDetails("cap", field, value);
      onClose();
    }
  };

  const openDrawer = React.useCallback((dataType, drawerTitle) => {
    // setIsVisible(false);
    setDataType(dataType);
    setDrawerTitle(drawerTitle);
    setIsVisible(true);
  }, []);
  const {
    cap: { capSize, capType, capColor, capLook, capItemPrice, capDesignerIcon,Brand },
  } = orderDetails;
  return shouldRender ? (
    <Grid container direction="column">
      <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={6}>
      <Box mt={6} mb={3}>
        <Typography
          style={{ fontWeight: "bold" }}
          color="primary"
          align="center"
          variant="h2"
        >
          &euro; {(price - wine.wineItemPrice).toFixed(2)}
        </Typography>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold", color: "#e2b50c" }}
          variant="h6"
        >
          Item Price: {capItemPrice && capItemPrice?.toFixed(2)}&euro;
        </Typography>
      </Box>
      <Grid
        onClick={() => {
          // setPassData(data["capType"]);
          if (orderDetails?.bottle && orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle'){
          if (orderDetails?.bottle && orderDetails?.bottle?.Container?.label !== 'Tap to select' && orderDetails?.bottle?.Closing?.label !== 'Tap to select') {
            db.collection( orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle' &&  orderDetails?.bottle?.Closing?.label.toLowerCase().replaceAll('_','') === 'screwcap' ? 'screwCap' : 'Caps')
            .onSnapshot(otp =>{
            let wineColorData = wineAllData.docs.filter(y => y.data().bottleTop.toLowerCase() === orderDetails?.bottle?.Closing?.label.toLowerCase() && y.data().container.toLowerCase() === orderDetails?.bottle?.Type?.label.toLowerCase())
            let todo = [...new Map(wineColorData.map((m) => [m.data().type, { label: m.data().type, value: m.data().type  }])).values()]
            // imgUrl : otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.type.toLowerCase() )?.data()?.imageLink
            console.log('wineColorData',orderDetails?.bottle, todo);
            setPassData(todo); 
            // setPassData(bottleCapType);
          openDrawer("capType", "Cap Type");
            })
        }
        else{
          alert('please select the container & closing in container section first')
        }
      }else{
        alert('Cap is only for the bottle container please move to next')
      }
      }
      }
        className={classes.inputRow}
      >
        <InputTile
          value={capType?.label}
          label={"Cap Type"}
          price={capType?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          if (capType?.label !== 'Tap to select') {
            console.log('Color.label', capType.label);
            let wineColorData = wineAllData.docs.filter(y => y.data().type.toLowerCase() === capType.label.toLowerCase() && y.data().container.toLowerCase() === orderDetails?.bottle?.Type?.label.toLowerCase() )
            console.log('wineColorData', wineColorData);
            
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().size, { label: m.data().size, value: m.data().size }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("capSize", "Cap Size");
          } else {
            alert('Please select the Cap Type')
          }
          // if (
          //   orderDetails.cap.capType &&
          //   orderDetails.cap.capType.value === "Aluminium" &&
          //   orderDetails.label.labelType &&
          //   orderDetails.label.labelType.value !== "New Label"
          // ) {
          //   // console.log(
          //   //   "     orderDetails.cap.capType.value === ",
          //   //   orderDetails.label.labelType
          //   // );
          // } else {
          //   getCapSizes();
          // }
          // getCapSizes();
          // setPassData(bottleCapSize);
          // openDrawer("capSize", "Cap Size");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={capSize?.label}
          label={"Cap Size"}
          price={capSize?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          if (capType?.label !== 'Tap to select') {
            console.log('Color.label', capType?.label);
            db.collection( capType?.label?.toLowerCase().replaceAll('_','') === 'screwcap' ?  capType.label.toLowerCase() === 'alluminium'  ? 'AlluminiumCap'  : 'ScrewCapBrand' : capType?.label.toLowerCase() === 'cork' ? capType.label.toLowerCase() === 'alluminium'  ? 'AlluminiumCap' : 'corkBrand' : 'BibClosing')
            .onSnapshot(otp =>{
            let wineColorData = wineAllData.docs.filter(y => y.data().type.toLowerCase() === capType.label.toLowerCase() && y.data().container.toLowerCase() === orderDetails?.bottle?.Type?.label.toLowerCase()  )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().custom, { label: m.data().brand, value: m.data().brand , 
              
            imgUrl: otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.brand.toLowerCase() )?.data()?.imageLink ?
            otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.brand.toLowerCase() )?.data()?.imageLink  : ''
            
           }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("Brand", "Brand");
            })
          } else {
            alert('Please select the Cap Type')
          }
          // if (capType.label !== 'Tap to select') {
          // setPassData(bottleCapCustome);
          // openDrawer("Custome", "Custome");
          // }else{
          //   alert('Please select the Cap Type')
          // }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Brand?.label}
          label={"Brand"}
          price={capSize?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      {/* <Grid
        onClick={() => {
          // if (
          //   orderDetails.cap.capType &&
          //   orderDetails.cap.capType.value != "Aluminium"
          // ) {
          getLabels();
          // }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={capLook?.label}
          label={"Cap Look"}
          price={capLook?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>{" "} */}
      <Grid
        onClick={() => {
          // if (
          //   orderDetails.cap.capType &&
          //   orderDetails.cap.capType.value === "Aluminium" &&
          //   orderDetails.label.labelType &&
          //   orderDetails.label.labelType.value !== "New Label"
          // ) {
          // } else {
          //   getColors();
          // }
          if (capType.label !== 'Tap to select') {
            console.log('Color.label', capType.label);
            db.collection( orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle' && orderDetails?.bottle?.Type?.label.toLowerCase() === 'glass'  ? 'BottleGlassClosing' : orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle' && orderDetails?.bottle?.Type?.label.toLowerCase() === 'pet' ? 'BottlePetClosing' :  orderDetails?.bottle?.Container?.label.toLowerCase() === 'can' ? 'CanClosing'  : 'BibClosing')
            .onSnapshot(otp =>{
            let wineColorData = wineAllData.docs.filter(y => y.data().type.toLowerCase() === capType.label.toLowerCase() && y.data().container.toLowerCase() === orderDetails?.bottle?.Type?.label.toLowerCase()  && y.data().brand === Brand.label )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().capColour, { label: m.data().capColour, value: m.data().capColour , price: m.data().price , 
               // imgUrl : otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.bottleTop.toLowerCase() )?.data()?.imageLink,
               imgUrl:m.data().dropdownimgLink,
               selectedImg : m.data().imgLink }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("capColor", "Cap Color");
            })
          } else {
            alert('Please select the Cap Type')
          }

        //   if (capType.label !== 'Tap to select') {
        //   if(Custome.label === 'Yes'){
        //     let colorArr = [{label:'blue',value:'blue'},{label:'red',value:'red'},{label:'yellow',value:'yellow'},{label:'black',value:'black'},{label:'brown',value:'brown'}]
        //     setPassData(colorArr);
        //     openDrawer("capColor", "Cap Color");
        //   }else{
        //     let colorArr = [{label:'Surain',value:'Surain'},{label:'Popcorn',value:'Popcorn'},{label:'News Drinker',value:'News Drinker'}]
        //     setPassData(colorArr)
        //     openDrawer("capColor", "Cap Color");
        //   }
        // }else{
        //   alert('Please select the Cap Type')
        // }
          // setPassData(bottleCapColor)
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={capColor?.label}
          label={"Cap Color"}
          price={capColor?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <br />
      {/* <Box mb={3} mt={3}>
        <FormControlLabel
          onChange={(e) => {
            if (
              orderDetails.label.labelType &&
              orderDetails.label.labelType.value === "New Label"
            ) {
              console.log("This is e.targe", e.target.checked);
              updateValue("capDesignerIcon", {
                value: e.target.checked,
                price: e.target.checked
                  ? capDesignerIconPrice / noOfBottles
                  : 0,
              });
            } else {
              alert("Only Available for New Label");
            }
          }}
          checked={
            orderDetails.cap.capDesignerIcon != null
              ? orderDetails.cap.capDesignerIcon.value
              : false
          }
          control={
            <Checkbox
              checked={
                orderDetails.label.capDesignerIcon != null
                  ? orderDetails.label.capDesignerIcon.value
                  : false
              }
              color="primary"
              icon={<CheckCircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              name="checkedH"
            />
          }
          label="Designer icon (350 / number of bottles)"
        />
      </Box> */}
     
      <Drawer
        drawerTitle={drawerTitle}
        isVisible={isVisible}
        field={dataType}
        onChange={(field, value) => {
          updateValue(field, value);
        }}
        data={passData}
        MainTitle={'cap'}
        onClose={onClose}
      />
    </Grid>
    <Grid item xs={2}>
     {/* {orderDetails?.cap?.capType?.imgUrl?
        <img src={orderDetails?.cap?.capType?.imgUrl} alt="Uploaded Image" style={{height:250,width:150,marginTop:50}} />
        :
        <></>} */}
         <div 
       // width="500" height="600"
              style={{
              // background: `url(${orderDetails?.bottle?.bottleType?.imgUrl})`,
              marginTop:50,
              marginBottom:50,
              backgroundPosition: 'center',
              display: 'flex',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: 100,
              height: 360,
              justifyContent:"center",
              // alignItems:"center",
              // backgroundColor:"red"

            }}>
              {/* <img   src={
                "https://firebasestorage.googleapis.com/v0/b/custom-wine.appspot.com/o/adminPanelImages%2F90055st_1.png%2F0.22034802147031018?alt=media&token=3aae81e8-608b-4635-9511-0737ba37c6f4"
              } 
           alt="Uploaded Image" style={{height:capSize?.label === 'Long'? 100 : capSize?.label === 'Medium' ? 100 : 100  ,marginTop:-15,width:100,marginLeft:1,position:"absolute"}} /> */}
             {orderDetails?.cap?.capColor?.selectedImg ?
           <img   src={orderDetails?.cap?.capColor?.selectedImg} 
           alt="Uploaded Image" style={{
            height: 300  ,
          //  marginTop:-8,
          //  width:orderDetails?.bottle?.bottleType?.label.toLowerCase() === 'burgundy' ? 47 :53 ,
           width:315 ,
          //  backgroundColor:'red',

           marginLeft:0.5,
           position:"absolute"}} />
        :
      <></>}
      {orderDetails?.bottle?.bottleColor?.builderImg && orderDetails?.bottle?.Container?.label?.toLowerCase() === 'bottle'?
      <img src={orderDetails?.bottle?.bottleColor?.builderImg} alt="Uploaded Image" style={{height:300,width:300, marginBottom:20}} />
      :
      <></>}
      {/* <img src={"https://firebasestorage.googleapis.com/v0/b/custom-wine.appspot.com/o/adminPanelImages%2Fone.png%2F0.5267764827510162?alt=media&token=b4652962-12ed-41f7-a48a-841f0a5b7fb1"} alt="Uploaded Image" style={{height:300,width:80, marginBottom:20}} /> */}
        {orderDetails?.label?.labelType?.selectedImg && orderDetails?.bottle?.Container?.label?.toLowerCase() === 'bottle' ?
        // <img src={orderDetails?.label?.labelType?.selectedImg} alt="Uploaded Image" style={{height:120,width:90,marginTop:200, position:"absolute"}} />
        <img src={orderDetails?.label?.labelType?.selectedImg} alt="Uploaded Image" style={{
          // height:orderDetails?.bottle?.bottleType?.label === 'burgundy' ? 100 : 160,
        //  width: 98,
        height:300,
         width: 300,
          // marginTop:orderDetails?.bottle?.bottleType?.label === 'burgundy' ? 200 : 160 , 
          marginTop:1 , 

          position:"absolute"}} />
       :
       <></>}
        {/* <img src={"https://firebasestorage.googleapis.com/v0/b/custom-wine.appspot.com/o/adminPanelImages%2Fbanner.png%2F0.6410737699060272?alt=media&token=0a1e31be-c8e6-4ff6-baa7-2942041f5343"} alt="Uploaded Image" style={{height:50,width:77,marginTop:200, position:"absolute"}} /> */}
     
      </div>
        <div 
          // width="500" height="600"
       
       style={{
              // background: `url(${orderDetails?.bottle?.bottleType?.imgUrl})`,
              // marginTop:50,
              // marginBottom:50,
              // backgroundPosition: 'center',
              // display: 'flex',
              // backgroundSize: 'cover',
              // // backgroundRepeat: 'no-repeat',
              // width: '10vw',
              // height: '40vh',
              // // alignItems:"center",
              // justifyContent:"center",
              marginTop:capSize?.label === 'Long'? -90 :capSize?.label === 'Medium'? -75 : -60 ,
              marginBottom:50,
              backgroundPosition: 'center',
              display: 'flex',
              backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              width: 80,
              height: 320,
              justifyContent:"center",
              alignItems:"center",
            }}>
               
             
              <div style={{justifyContent:'center',alignItems:"center"}}>
        
      {/* {
        orderDetails?.label?.labelType?.imgUrl ?
        <img src={orderDetails?.label?.labelType?.imgUrl} alt="Uploaded Image" style={{height:70,width:57,marginTop:105}} />
       : <></>} */}
      
           </div>

          
        </div>
    </Grid>
    </Grid>
    <NavigationButtons
        nextRoute={"/order/wine-case"}
        disablePrevious={false}
        orderDetails={orderDetails}
        page={"cap"}
      />
    </Grid>
  ) : null;
};

export default Cap;
