import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { Component } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Counter from "../Counter"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProgressBar from '../../components/ProgressBar';

const useStyles = makeStyles((theme) => ({
    orderTile: {
        minWidth: 300,
        minHeight: 80,
        width:700,
        position: 'relative',
        borderRadius: '10px',
        backgroundColor: '#fbfbfb',
        border: '1px solid #dfdfdf',
        padding: '5px 10px',
        textAlign: 'left',
    },
    price: {
        fontWeight: 'bold',
        position: "absolute",
        bottom: 28,
        right: 45,
    },
    iconContainer: {
        textAlign: "right"
    },
    arrow: {
        color: "#2222"
    }
}))

const InputTile = (props) => {
    const classes = useStyles();
    const { label, name, showCounter, showIcon, value, price,bar,multiprice } = props;
    return (
        <Grid container justify="space-between" alignItems="center" className={classes.orderTile}>
            <Grid onClick={() => {props.innerOnClick && props.innerOnClick()}} item xs={showIcon || showCounter ? 10 : 12}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography color="textSecondary">{label}</Typography>
                        <Typography color="textPrimary">{value}</Typography>
                        {bar && value && value !== 'Tap to select' ?
                         <ProgressBar style={{width:-'10%'}} value={Number(multiprice? value * multiprice : value * 20)} />
                        :
                        <></>
                        }
                    </Grid>
                    {price && (
                        <Grid item>
                            <Typography className={classes.price} color="primary">&euro; {price}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {showIcon && (
                <Grid item xs={2} className={classes.iconContainer}>
                    <ExpandMoreIcon fontSize="large" className={classes.arrow} />
                </Grid>
            )}
            {showCounter && (
                <Grid item xs={2} className={classes.iconContainer}>
                    <Counter onChange={props.onChange} />
                </Grid>
            )}

        </Grid>
    )
}

export default InputTile;