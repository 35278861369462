
export class User {
  constructor(x) {
    this.uuid = x.uuid;
    this.firstName = x.firstName;
    this.lastName = x.lastName;
    this.profilePicture = x.profilePicture;
    this.userId = x.userId;
    this.email = x.email;
    this.birthday = x.birthday;
  }

  static fromFirestore(doc) {
    if (!doc) return null;
    const data = doc.data();
    if (!data) return null;
    return new User({
      uuid: doc.id,
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      profilePicture: data.profilePicture || '',
      userId: data.userId,
      birthday: data.birthday,
      email: data.email,
    });
  }

  toJson(x) {
    return {
      uuid: x.id,
      firstName: x.firstName || '',
      lastName: x.lastName || '',
      profilePicture: x.profilePicture || '',
      userId: x.userId,
      birthday: x.birthday,
      email: x.email,
    };
  }
}