import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import SubtractIcon from "@material-ui/icons/Remove";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  roundButton: {
    width: 35,
    height: 35,
    borderRadius: 50,
    minWidth: 35,
    padding: 5,
  },
}));

const Counter = (props) => {
  const classes = useStyles();
  const [counterValue, setCounterValue] = useState(props.value);

  const handleIncrementChange = () => {
    setCounterValue((prevState) => {
      props.onChange(prevState + 1);
      return prevState + 1;
    });
  };

  const handleDecrementChange = () => {
    setCounterValue((prevState) => {
      props.onChange(prevState - 1);
      return prevState - 1;
    });
  };

  return (
    <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
      <Button
        onClick={handleDecrementChange}
        disabled={counterValue === 0}
        disableRipple
        className={classes.roundButton}
        variant="contained"
        color="primary"
      >
        <SubtractIcon fontSize="small" />
      </Button>
      <Box minWidth={40} textAlign="center">
        <Typography>{counterValue}</Typography>
      </Box>
      <Button
        onClick={handleIncrementChange}
        disableRipple
        className={classes.roundButton}
        variant="contained"
        color="primary"
      >
        <AddIcon fontSize="small" />
      </Button>
    </Grid>
  );
};

export default Counter;
