import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import {
  getUserById,
  uploadProfilePicture,
} from "../Backend/Services/userService";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../Backend/Services/authService";

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getUserInfo = () => {
    getCurrentUser().then((a) => {
      this.setState({ currentUser: a }, () => {
        getUserById(a.uid).then((b) => {
          console.log("THis is user dta", b);
          this.setState({ userData: b });
        });
      });
    });
  };
  componentDidMount() {
    this.getUserInfo();
  }
  render() {
    const { classes } = this.props;
    const { currentUser, userData } = this.state;
    return currentUser && userData ? (
      <div className={classes.topPadding}>
        <Grid
          id="header"
          className={classes.header}
          container
          spacing={0}
          alignItems="center"
        >
          <Grid item xs={4} lg={4} md={4}>
            <ChevronLeft
              onClick={() => {
                window.history.back();
              }}
            ></ChevronLeft>
          </Grid>
          <Grid item xs={4} lg={4} md={4} className={classes.imgTop}></Grid>
          <Grid item xs={4} lg={4} md={4}></Grid>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <Grid style={{ textAlign: "center" }} item xs={12} lg={6} md={6}>
            <img
              src={
                userData.profilePicture
                  ? userData.profilePicture
                  : require("../assets/user-placeholder.png")
              }
              className={classes.roundedImg}
              alt=""
            />
            <h2 style={{ textAlign: "center" }}>{currentUser.displayName}</h2>
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12} lg={6} md={6}>
            <OrderTile
              orderName={"Email"}
              orderValue={this.state.currentUser.email}
            />
            <OrderTile orderName={"Birthday"} orderValue={userData.birthday} />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid className={classes.settingRow} item xs={12} lg={12} md={12}>
            <Link to="/accountSettings">
              <p>Account Settings</p>
              <ArrowForwardIcon></ArrowForwardIcon>
            </Link>
          </Grid>
          <Grid className={classes.settingRow} item xs={12} lg={12} md={12}>
            <Link to="/changePassword">
              <p>Change Password</p>
              <ArrowForwardIcon></ArrowForwardIcon>
            </Link>
          </Grid>

          <Grid className={classes.settingRow} item xs={12} lg={12} md={12}>
            <Link to="/aboutUs">
              <p>About Us</p>
              <ArrowForwardIcon></ArrowForwardIcon>
            </Link>
          </Grid>
          <Grid className={classes.settingRow} item xs={12} lg={12} md={12}>
            <Link to="/logout">
              <p style={{ color: "red" }}>Logout</p>
              {/* <ArrowForwardIcon></ArrowForwardIcon> */}
            </Link>
          </Grid>
        </Grid>
      </div>
    ) : null;
  }
}

const styles = (theme) => ({
  root: {},
  topPadding: {
    // marginTop: '20px',
    padding: "15px 0",
    overflowX: "hidden",
    width: "100vw",
    [theme.breakpoints.up("sm")]: {
      // marginTop: '70px',
    },
  },
  header: {
    padding: "10px 0px",
  },
  settingRow: {
    padding: "0px 15px",
    borderBottom: "1px solid #e1e1e1",
    position: "relative",
    "&:hover": {
      backgroundColor: "#efefef",
      cursor: "pointer",
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      right: "10px",
      top: "15px",
    },
  },
  roundedImg: {
    width: "50vw",
    height: "50vw",
    borderRadius: "50vw",
    border: "1px solid white",
    boxShadow: "1px 1px 5px 3px #ddd",
    objectFit: "cover",
    [theme.breakpoints.up("sm")]: {
      width: "20vw",
      height: "20vw",
    },
  },
  heading: { color: "black", marginLeft: 10 },
  imgTop: { textAlign: "right" },
  hudTile: {
    borderRadius: "10px",
    height: "165px",
    backgroundColor: "#bd2132",
    boxShadow: "4px 4px 17px 4px rgb(190 33 49 / 30%)",
    "& p": {
      color: "white",
      textAlign: "center",
    },
    "& h1": {
      color: "white",
      textAlign: "center",
      fontSize: "75px",
      marginBottom: "0",
      marginTop: "15px",
      paddingTop: "0",
    },
  },
  divider: {
    "background-color": "#f1f1f1",
    height: "15px",
    margin: "0 -15px",
    width: "100000px",
    "margin-left": "-1000px",
  },
  button: {
    borderRadius: "8px",
    padding: "10px 20px",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  orderTile: {
    position: "relative",
    borderRadius: "10px",
    // backgroundColor: '#fbfbfb',
    // border: '1px solid #efefef',
    padding: "5px 10px",
    textAlign: "left",
    marginBottom: "10px",
    "& .arrow": {
      position: "absolute",
      top: "15px",
      right: "10px",
      fontWeight: "bold",
      color: "#bd2132",
    },
    "& .title": {
      fontSize: 12,
      color: "#999",
    },
    "& .detail": {
      color: "#bd2132",
    },

    "& .value": {
      color: "#333",
    },
    "& p": {
      marginTop: "8px",
      marginBottom: "8px",
    },
    "& h1": {
      color: "white",
      textAlign: "center",
      fontSize: "75px",
      marginBottom: "30px",
      marginTop: "10px",
      paddingTop: "15px",
    },
  },
});

const HudTile = withStyles(styles)((props) => (
  <div className={props.classes.hudTile}>
    <h1>{props.count}</h1>
    <p>{props.title}</p>
  </div>
));

const OrderTile = withStyles(styles)((props) => (
  <div className={props.classes.orderTile}>
    <p className={"title"}>{props.orderName}</p>
    <p className={"value"}>{props.orderValue}</p>
    <div className="arrow">
      <p className={"detail"}>{props.price}</p>
    </div>
  </div>
));

export default withStyles(styles)(OrderDetails);
