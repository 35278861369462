import React from 'react';

export const PriceContext = React.createContext({
  price: 0,
  setPrice:(v) => {},
  orderDetails: {},
  appData: {},
  setOrderDetails: (page, field, object) => {},
});



export function isEmpty(obj) {
  for(var key in obj) {
      if(key.indexOf('ItemPrice') == -1 && obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
