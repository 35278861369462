import { Box, Grid, Typography } from "@material-ui/core";
import { PriceContext, isEmpty } from "./PriceContext";

import Drawer from "./Drawer";
import InputTile from "../../components/InputTile";
import NavigationButtons from "../../components/NavigationButtons";
import React from "react";
import { humanize } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../Backend/firebase";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    marginTop: theme.spacing(3),
  },
}));

const defaultPayload = {
  label: "Tap to select",
  value: "tap_to_select",
};

// const brandDiamCorkTypes = [
//   {
//     value: 'Diam 1',
//     label: 'Diam 1',
//     price: '4.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Diam 3',
//     label: 'Diam 3',
//     price: '4.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Diam 5',
//     label: 'Diam 5',
//     price: '4.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   }
// ]
// const brandAmorimCorkTypes = [
//   {
//     value: 'Neutrocork',
//     label: 'Neutrocork',
//     price: '1.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Neutrocork Premium',
//     label: 'Neutrocork Premium',
//     price: '2.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Neutrocork Prestige',
//     label: 'Neutrocork Prestige',
//     price: '3.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
// ]

// const typeDiam1Sizes = [
//   {
//     value: '44',
//     label: '44',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
// ]
// const typeDiam3Sizes = [
//   {
//     value: '44',
//     label: '44',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: '47',
//     label: '47',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
// ]
// const typeDiam5Sizes = [
//   {
//     value: '44',
//     label: '44',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: '47',
//     label: '47',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: '49',
//     label: '49',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
// ]

// const typeNC_NCPresSizes = [
//   {
//     value: '38',
//     label: '38',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: '44',
//     label: '44',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
// ]

// const typeNCPremSizes = [
//   {
//     value: '44',
//     label: '44',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: '47',
//     label: '47',
//     price: '0.10',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
// ]

// const data = {
//   corkBrand: [{
//     value: 'Diam',
//     label: 'Diam',
//     price: '1.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },
//   {
//     value: 'Amorim',
//     label: 'Amorim',
//     price: '2.50',
//     imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate='
//   },],
//   corkType: brandDiamCorkTypes,
//   corkSize: typeDiam1Sizes,
// }

const Cork = () => {
  const classes = useStyles();
  const {
    price,
    setPrice,
    orderDetails,
    setOrderDetails,
    appData,
  } = React.useContext(PriceContext);
  const { volume, wine } = orderDetails;
  //appData stuff
  const data = Object.assign({}, appData.corkData);
  console.log("This is the cork data", data);
  const brandDiamCorkTypes = Object.assign(
    {},
    appData.brandDiamCorkTypes.array
  );
  const brandAmorimCorkTypes = Object.assign(
    {},
    appData.brandAmorimCorkTypes.array
  );
  const typeDiam1Sizes = Object.assign({}, appData.typeDiam1Sizes.array);
  const typeDiam3Sizes = Object.assign({}, appData.typeDiam3Sizes.array);
  const typeDiam5Sizes = Object.assign({}, appData.typeDiam5Sizes.array);
  const typeNC_NCPresSizes = Object.assign(
    {},
    appData.typeNC_NCPresSizes.array
  );
  const typeNCPremSizes = Object.assign({}, appData.typeNCPremSizes.array);
  //////////////////////////

  const [drawerTitle, setDrawerTitle] = React.useState("wineType");
  const [isVisible, setIsVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState("wineType");
  const [passData, setPassData] = React.useState([]);
  const [shouldRender, setShouldRender] = React.useState(false);

  const [capBrand , setCapBrand] = React.useState([]);
  const [bottleCapType , setBottleCapType] = React.useState([]);
  const [bottleCapSize , setBottleCapSize] = React.useState([]);
  const [wineAllData, setWineAllData] = React.useState([]);

  const GetBottleTypeo = (capD) =>{
    console.log('ssssss doto');
    let doData =  db.collection('Closing')
    .onSnapshot(x=>{
      setWineAllData(x)
    let doto = [...new Map(x.docs.map((m) => [m.data().brand, {label : m.data().brand , value: m.data().brand}])).values()]
    console.log('ssssss doto', doto);
    setCapBrand(doto);
    

    let Bweight = [...new Map(x.docs.map((m) => [m.data().type, {label : m.data().type , value: m.data().type}])).values()]
    console.log('ssssss doto', Bweight);
    setBottleCapType(Bweight);

    let Bcolor = [...new Map(x.docs.map((m) => [m.data().size, {label : m.data().size , value: m.data().size , price: m.data().price}])).values()]
    console.log('ssssss doto', Bcolor);
    setBottleCapSize(Bcolor);
    })
  }

  React.useEffect(()=>{
  
    GetBottleTypeo();
  },[])
  // const [corkBrand, setCorkBrand] = React.useState('Amorim');
  // const [corkType, setCorkType] = React.useState('Neutrocork Premium');
  // const [corkSize, setCorkSize] = React.useState('38');

  React.useEffect(() => {
    if (isEmpty(orderDetails.cork) && orderDetails?.bottle?.Closing?.label === 'cork') {
      setOrderDetails("cork", "corkBrand", defaultPayload);
      setOrderDetails("cork", "corkType", defaultPayload);
      setOrderDetails("cork", "corkSize", defaultPayload);
      // setOrderDetails('cork', 'capLook', data.capLook[0] )
      setTimeout(() => {
        setShouldRender(true);
      }, 700);
    } else {
      setTimeout(() => {
        setShouldRender(true);
      }, 700);
    }
  }, []);

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const updateValue = (field, value) => {
    if (field == "corkBrand" && value.value === "Diam") {
      updateNextFieldData("corkType", brandDiamCorkTypes);
      updateValue("corkType", brandDiamCorkTypes[0]);
    }
    if (field == "corkBrand" && value.value === "Amorim") {
      updateNextFieldData("corkType", brandAmorimCorkTypes);
      updateValue("corkType", brandAmorimCorkTypes[0]);
    }
    if (field == "corkType" && value.value === "Diam 1") {
      updateNextFieldData("corkSize", typeDiam1Sizes);
      updateValue("corkSize", typeDiam1Sizes[0]);
    }
    if (field == "corkType" && value.value === "Diam 3") {
      updateNextFieldData("corkSize", typeDiam3Sizes);
      updateValue("corkSize", typeDiam3Sizes[0]);
    }
    if (field == "corkType" && value.value === "Diam 5") {
      updateNextFieldData("corkSize", typeDiam5Sizes);
      updateValue("corkSize", typeDiam5Sizes[0]);
    }
    if (
      field == "corkType" &&
      (value.value === "Neutrocork" || value.value === "Neutrocork Prestige")
    ) {
      updateNextFieldData("corkSize", typeNC_NCPresSizes);
      updateValue("corkSize", typeNC_NCPresSizes[0]);
    }
    if (field == "corkType" && value.value === "Neutrocork Premium") {
      updateNextFieldData("corkSize", typeNCPremSizes);
      updateValue("corkSize", typeNCPremSizes[0]);
    }
    setOrderDetails("cork", field, value);
    onClose();
  };
  const updateNextFieldData = (nextField, value) => {
    data[nextField] = value;
  };

  const openDrawer = React.useCallback((dataType, drawerTitle) => {
    // setIsVisible(false);
    setDataType(dataType);
    setDrawerTitle(drawerTitle);
    setIsVisible(true);
  }, []);
  const {
    cork: { corkBrand, corkType, corkSize, corkItemPrice },
  } = orderDetails;
  console.log("THis is order details", orderDetails);
  return shouldRender ? (
    <Grid container direction="column">
      <Box mt={6} mb={3}>
        <Typography
          style={{ fontWeight: "bold" }}
          color="primary"
          align="center"
          variant="h2"
        >
          &euro; {price && (price - wine.wineItemPrice).toFixed(2)}
        </Typography>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold", color: "#e2b50c" }}
          variant="h6"
        >
          Item Price: {corkItemPrice && corkItemPrice?.toFixed(2)}&euro;
        </Typography>
      </Box>
      <Grid
        onClick={() => {
          if (orderDetails?.bottle && orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle' && orderDetails?.bottle?.Type?.label.toLowerCase() === 'glass' && orderDetails?.bottle?.Closing?.label.toLowerCase() === 'cork') {
          // setPassData(data["corkBrand"]);
          setPassData(capBrand); 
          openDrawer("corkBrand", "Cork Brand");
        }else{
          alert('Closing is only for Glass Bottle with Cork closing please move on to Cap');
          }
          GetBottleTypeo(corkBrand?.label); 
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={corkBrand?.label}
          label={"Brand"}
          price={corkBrand?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          // if (
          //   orderDetails.cork.corkBrand &&
          //   orderDetails.cork.corkBrand.value === "Amorim"
          // ) {
          //   setPassData(data["corkTypeAmorim"]);
          // } else {
          //   setPassData(data["corkType"]);
          // }

          if (corkBrand.label !== 'Tap to select') {
            console.log('Color.label', corkBrand.label);
            let wineColorData = wineAllData.docs.filter(y => y.data().brand === corkBrand.label && y.data().wineType === orderDetails.wine.StillSparkling.label )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().type, { label: m.data().type, value: m.data().type }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("corkType", "Cork Type");
          } else {
            alert('Please select the Brand')
          }
          //   setPassData(bottleCapType);
          // openDrawer("corkType", "Cork Type");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={corkType?.label}
          label={"Type"}
          price={corkType?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          // if (
          //   orderDetails.cork.corkType &&
          //   orderDetails.cork.corkType.value === "Diam 1"
          // ) {
          //   setPassData(data["corkSizeDiam1"]);
          // } else if (
          //   orderDetails.cork.corkType &&
          //   orderDetails.cork.corkType.value === "Diam 3"
          // ) {
          //   setPassData(data["corkSizeDiam3"]);
          // } else if (
          //   orderDetails.cork.corkType &&
          //   orderDetails.cork.corkType.value === "Diam 5"
          // ) {
          //   setPassData(data["corkSizeDiam5"]);
          // } else if (
          //   orderDetails.cork.corkType &&
          //   orderDetails.cork.corkType.value === "Neutrocork Premium"
          // ) {
          //   setPassData(data["corkSizeNeutrocorkPremium"]);
          // } else if (
          //   orderDetails.cork.corkType &&
          //   orderDetails.cork.corkType.value === "Neutrocork Prestige"
          // ) {
          //   setPassData(data["corkTypeNeutrocorkPrestige"]);
          // } else {
          //   setPassData(data["corkSize"]);
          // }
          if (corkBrand.label !== 'Tap to select') {
            let wineColorData = wineAllData.docs.filter(y => y.data().brand === corkBrand.label && y.data().type === corkType.label)
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().size, { label: m.data().size, value: m.data().size, price:m.data().price }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
          
          openDrawer("corkSize", "Cork Size");
          }else{
            alert('Please select the Brand')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={corkSize?.label}
          label={"Size"}
          price={corkSize?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Box mt={3}>
        <NavigationButtons
          nextRoute={"/order/label"}
          disablePrevious={false}
          orderDetails={orderDetails}
          page={"cork"}
        />
      </Box>
      <Drawer
        drawerTitle={drawerTitle}
        isVisible={isVisible}
        field={dataType}
        onChange={(field, value) => {
          updateValue(field, value);
        }}
        data={passData}
        MainTitle={'cork'}
        onClose={onClose}
      />
    </Grid>
  ) : null;
};

export default Cork;
