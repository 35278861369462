
export class Order {
  constructor(x) {
    this.uuid = x.uuid;
    this.timestampAdded = x.timestampAdded;
    // wine
    this.noOfBottles = x.noOfBottles;
    this.wineTaste = x.wineTaste;
    this.wineType = x.wineType;
    this.wineYear = x.wineYear;
    this.wineItemPrice = x.wineItemPrice;
    // bottle
    this.bottleColor = x.bottleColor;
    this.bottleType = x.bottleType;
    this.volume = x.volume;
    this.wineWeight = x.wineWeight;
    this.bottleItemPrice = x.bottleItemPrice;
    // label
    this.labelType = x.labelType;
    this.labelDesignerIcon = x.labelDesignerIcon;
    this.labelCliche = x.labelCliche;
    this.labelItemPrice = x.labelItemPrice;

    // cap
    this.capColor = x.capColor;
    this.capLook = x.capLook;
    this.capSize = x.capSize;
    this.capType = x.capType;
    this.capDesignerIcon = x.capDesignerIcon;
    this.capItemPrice = x.capItemPrice;

    // cork
    this.corkBrand = x.corkBrand;
    this.corkSize = x.corkSize;
    this.corkType = x.corkType;
    this.corkItemPrice = x.corkItemPrice;
    // wine case
    this.caseType = x.caseType;
    this.caseCliche = x.caseCliche;
    this.wineCaseItemPrice = x.wineCaseItemPrice;

    // price
    this.totalPrice = x.totalPrice;

    //user
    this.userId = x.userId;
  }

  static fromFirestore(doc) {
    const data = doc.data();
    if (!data) return null;

    return new Order({
      uuid: doc.id,
      timestampAdded:  new Date(data.timestampAdded * 1000),
      // wine
      noOfBottles: data['noOfBottles'] ? data['noOfBottles'] : '',
      wineTaste: data['wineTaste'] ? data['wineTaste'] : '',
      wineType: data['wineType'] ? data['wineType'] : '',
      wineYear: data['wineYear'] ? data['wineYear'] : '',
      wineItemPrice: data['wineItemPrice'] ? data['wineItemPrice'] : '',
      // bottle
      bottleColor: data['bottleColor'] ? data['bottleColor'] : '',
      bottleType: data['bottleType'] ? data['bottleType'] : '',
      volume: data['volume'] ? data['volume'] : '',
      wineWeight: data['wineWeight'] ? data['wineWeight'] : '',
      bottleItemPrice: data['bottleItemPrice'] ? data['bottleItemPrice'] : '',
      // label
      labelType: data['labelType'] ? data['labelType'] : '',
      labelDesignerIcon: data['labelDesignerIcon'] ? data['labelDesignerIcon'] : '',
      labelCliche: data['labelCliche'] ? data['labelCliche'] : '',
      labelItemPrice: data['labelItemPrice'] ? data['labelItemPrice'] : '',

      // cap
      capColor: data['capColor'] ? data['capColor'] : '',
      capLook: data['capLook'] ? data['capLook'] : '',
      capSize: data['capSize'] ? data['capSize'] : '',
      capType: data['capType'] ? data['capType'] : '',
      capDesignerIcon: data['capDesignerIcon'] ? data['capDesignerIcon'] : '',
      capItemPrice: data['capItemPrice'] ? data['capItemPrice'] : '',

      // cork
      corkBrand: data['corkBrand'] ? data['corkBrand'] : '',
      corkSize: data['corkSize'] ? data['corkSize'] : '',
      corkType: data['corkType'] ? data['corkType'] : '',
      corkItemPrice: data['corkItemPrice'] ? data['corkItemPrice'] : '',
      // wine case
      caseType: data['caseType'] ? data['caseType'] : '',
      caseCliche: data['caseCliche'] ? data['caseCliche'] : '',
      wineCaseItemPrice: data['wineCaseItemPrice'] ? data['wineCaseItemPrice'] : '',

      // price
      totalPrice: data['totalPrice'] ? data['totalPrice'] : '',
      //user
      userId: data['userId'] ? data['userId'] : '',
    });
  }

  toJson(x) {
    return {
      uuid: x.uuid,
      timestampAdded: x.timestampAdded,
      // wine
      noOfBottles: x.noOfBottles,
      wineTaste: x.wineTaste,
      wineType: x.wineType,
      wineYear: x.wineYear,
      wineItemPrice: x.wineItemPrice,
      // bottle
      bottleColor: x.bottleColor,
      bottleType: x.bottleType,
      volume: x.volume,
      wineWeight: x.wineWeight,
      bottleItemPrice: x.bottleItemPrice,
      // label
      labelType: x.labelType,
      labelDesignerIcon: x.labelDesignerIcon ? x.labelDesignerIcon : false,
      labelCliche: x.labelCliche ? x.labelCliche : false,
      labelItemPrice: x.labelItemPrice,

      // cap
      capColor: x.capColor,
      capLook: x.capLook,
      capSize: x.capSize,
      capType: x.capType,
      capDesignerIcon: x.capDesignerIcon ? x.capDesignerIcon : false,
      capItemPrice: x.capItemPrice,

      // cork
      corkBrand: x.corkBrand,
      corkSize: x.corkSize,
      corkType: x.corkType,
      corkItemPrice: x.corkItemPrice,
      // wine case
      caseType: x.caseType,
      caseCliche: x.caseCliche ? x.caseCliche : false,
      wineCaseItemPrice: x.wineCaseItemPrice,

      // price
      totalPrice: x.totalPrice,
      //user
      userId: x.userId,
    };
  }
}