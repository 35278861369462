import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { PriceContext, isEmpty } from "./PriceContext";

import Drawer from "./Drawer";
import InputTile from "../../components/InputTile";
import NavigationButtons from "../../components/NavigationButtons";
import React, { useEffect, useState } from "react";
import { humanize } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../Backend/firebase";
import ProgressBar from '../../components/ProgressBar';


const defaultPayload = {
  label: "Tap to select",
  value: "tap_to_select",
};

const useStyles = makeStyles((theme) => ({
  inputRow: {
    marginTop: theme.spacing(3),
  },
  customRadioInput: {
    padding: "5px 10px",
    backgroundColor: "#fbfbfb",
    border: "1px solid #e1e1e1",
    borderRadius: "8px",
    position: "relative",
    "& .MuiFormControlLabel-labelPlacementStart": {
      width: "100%",
    },
    "& .MuiTypography-body1": {
      position: "absolute",
      left: "10px",
    },
    "& p": {
      position: "absolute",
      right: "50px",
      top: "0px",
      color: "#bd2132",
      fontWeight: "bold",
    },
    "& .MuiIconButton-label": {
      paddingRight: "10px",
    },
  },
}));

const data = {
  volume: [
    {
      value: "750",
      label: "750",
      price: "1.50",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      value: "1500",
      label: "1500",
      price: "2.50",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
  ],
  wineType: [
    {
      value: "sulfites",
      label: "Sulfites",
      price: "1.50",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      value: "without_sulfites",
      label: "Without Sulfites",
      price: "2.50",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
  ],
  wineTaste: [
    {
      value: "wooded",
      label: "Wooded",
      price: "3.50",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      value: "fruity",
      label: "Fruity",
      price: "4.50",
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
  ],
  wineYear: [
    {
      value: "2018",
      label: 2018,
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      value: "2019",
      label: 2019,
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      value: "2020",
      label: 2020,
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
    {
      value: "2021",
      label: 2021,
      imgUrl:
        "https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=",
    },
  ],
};

const volumeMultiplier = {
  tap_to_select: 1,
  750: 1,
  1500: 2,
};

const Wine = (props) => {
  const inputEl = React.useRef(null);
  const {
    price,
    setPrice,
    orderDetails,
    setOrderDetails,
    appData,
  } = React.useContext(PriceContext);

  //appData stuff
  const data = Object.assign({}, appData.wineData);
  //////////////////////////

  const [isVisible, setIsVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState("wineType");
  const [passData, setPassData] = React.useState([]);
  const [drawerTitle, setDrawerTitle] = React.useState("wineType");
  const [shouldRender, setShouldRender] = React.useState(false);
  const [WineTypeo, setWineType] = React.useState([]);
  const [WineDataYear, setWineDataYear] = React.useState([]);
  const [WineDataCapacity, setWineDataCapacity] = React.useState([]);

  const [colour, setColour] = useState('');
  const [grape, setGrape] = useState('');
  const [still, setStill] = useState('');
  const [Sulfite, setSulfite] = useState('');
  const [woody, setWoody] = useState('');
  const [tannins, setTannins] = useState('');
  const [freshness, setFreshness] = useState('');
  const [fruity, setFruity] = useState('');
  const [sweetness, setSweetness] = useState('');
  const [acidity, setAcidity] = useState('');
  const [complexity, setComplexity] = useState('');
  const [wineAllData, setWineAllData] = useState([]);

  const [wineColorFiler, setWineColorFilter] = useState('');
  const [sugest , setSugest] = useState(' ')


  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  const openDrawer = React.useCallback((dataType, dataTitle,sug) => {
    setSugest(sug)
    setDataType(dataType);
    setDrawerTitle(dataTitle);
    setIsVisible(true);
  }, []);
  console.log('ssss ========>>>>>>>>>>',wineColorFiler);
  React.useEffect(() => {
    if (props.location?.state?.editable) {
      props.setFromEdit(props.location.state.editable);
    }
    if (isEmpty(orderDetails.wine) && !props.location?.state?.editable) {

    setOrderDetails("wine", "wineYear", defaultPayload);
    setOrderDetails("wine", "Color", defaultPayload);
    setOrderDetails("wine", "Grape", defaultPayload);
    setOrderDetails("wine", "StillSparkling", defaultPayload);
    setOrderDetails("wine", "TargetMarket", defaultPayload);
    setOrderDetails("wine", "Fruity", defaultPayload);
    setOrderDetails("wine", "Sweetness", defaultPayload);
    setOrderDetails("wine", "PriceRange", defaultPayload);
    setTimeout(() => {
      setShouldRender(true);
    }, 500);
  } else {
    setTimeout(() => {
      setShouldRender(true);
    }, 500);
  }
  }, [wineColorFiler]);



  const DeclearValueOfWine = () =>{

    if (props.location?.state?.editable) {
      props.setFromEdit(props.location.state.editable);
    }
    if (isEmpty(orderDetails.wine) && !props.location?.state?.editable) {
     
     

      

      if(wineColorFiler === 'red'){
      setOrderDetails("wine", "wineType", defaultPayload ? defaultPayload : {value:'',label:''});
      setOrderDetails("wine", "Woody", defaultPayload ? defaultPayload : {value:'',label:''});
      setOrderDetails("wine", "Tannins", defaultPayload ? defaultPayload : {value:'',label:''});
      setOrderDetails("wine", "Complexity", defaultPayload ? defaultPayload : {value:'',label:''});
    }else{
      if(wineColorFiler === 'white' || wineColorFiler === 'rose'){
      setOrderDetails("wine", "Freshness", defaultPayload ? defaultPayload : {value:'',label:''});
      setOrderDetails("wine", "Acidity", defaultPayload ? defaultPayload : {value:'',label:''});
    }}
      setTimeout(() => {
        setShouldRender(true);
      }, 500);
    } else {
      setTimeout(() => {
        setShouldRender(true);
      }, 500);
    }
  }

  const updateValue = (field, value) => {
    let r;
    setOrderDetails("wine", field, value);
    onClose();
    return r;
  };

  
  const {
    wine: { wineType, wineTaste, 
        noOfBottles,
       wineYear, wineItemPrice,
        volume, Color, Grape,
      StillSparkling, Woody, 
      Tannins, Freshness, 
      Fruity, Sweetness,
      Acidity , Complexity, 
      TargetMarket,
      PriceRange
      
    },
  } = orderDetails;

  console.log("This is order detaisl", orderDetails);





  const getWineType = () => {
    console.log('ssssss doto');
    let doData = db.collection('Wine')
      .onSnapshot(x => {
        setWineAllData(x);
        let doto = [...new Map(x.docs.map((m) => [m.data().sulfiteAdded, { label: m.data().sulfiteAdded, value: m.data().sulfiteAdded }])).values()]
        console.log('ssssss doto', doto);
        setSulfite(doto);
        let ColorData = [...new Map(x.docs.map((m) => [m.data().colour.toLowerCase(), { label: m.data().colour, value: m.data().colour }])).values()]
        setColour(ColorData);
        let GrapeData = [...new Map(x.docs.map((m) => [m.data().grape, { label: m.data().grape, value: m.data().grape }])).values()]
        setGrape(GrapeData);
        let StillData = [...new Map(x.docs.map((m) => [m.data().stillSparkling, { label: m.data().stillSparkling, value: m.data().stillSparkling }])).values()]
        setStill(StillData);

        let WoodyData = [...new Map(x.docs.map((m) => [m.data().woody, { label: m.data().woody, value: m.data().woody }])).values()]
        setWoody(WoodyData);

        let TanninsData = [...new Map(x.docs.map((m) => [m.data().tannins, { label: m.data().tannins, value: m.data().tannins }])).values()]
        setTannins(TanninsData);

        let FreshnessData = [...new Map(x.docs.map((m) => [m.data().freshness, { label: m.data().freshness, value: m.data().freshness }])).values()]
        setFreshness(FreshnessData);

        let FruityData = [...new Map(x.docs.map((m) => [m.data().fruity, { label: m.data().fruity, value: m.data().fruity }])).values()]
        setFruity(FruityData);

        let SweetnessData = [...new Map(x.docs.map((m) => [m.data().sweetnessSugar, { label: m.data().sweetnessSugar, value: m.data().sweetnessSugar }])).values()]
        setSweetness(SweetnessData);

        let AcidityData = [...new Map(x.docs.map((m) => [m.data().acidity, { label: m.data().acidity, value: m.data().acidity }])).values()]
        setAcidity(AcidityData);

        let ComplexityData = [...new Map(x.docs.map((m) => [m.data().acidity, { label: m.data().acidity, value: m.data().acidity }])).values()]
        setComplexity(ComplexityData);


      })
  }
  const getWineYears = () => {
    console.log('ssssss doto');
    let doData = db.collection('Wine')
      .onSnapshot(x => {
        let doto = [...new Map(x.docs.map((m) => [m.data().year, m.data().year.includes(';') ? m.data().year.substring(0, 4) : m.data().year])).values()]
        let filterdYear = [...new Map(doto.map((m) => [m, { label: m, value: m }])).values()]
        console.log('ssssss Years', filterdYear);
        setWineDataYear(filterdYear);
      })
  }
  const getWineCapacity = () => {
    console.log('ssssss doto');
    let doData = db.collection('Container')
      .onSnapshot(x => {
        let doto = [...new Map(x.docs.map((m) => [m.data().capacity, { label: m.data().capacity, value: m.data().capacity, price: m.data().price }])).values()]
        console.log('ssssss doto', doto);
        setWineDataCapacity(doto);
      })
  }
  React.useEffect(() => {
    getWineType();
    getWineYears();
    getWineCapacity();
  }, [])

  const classes = useStyles();
  const nonbottlesInput = () => {
    return (
      <div style={{ padding: "10px 20px 10px 10px" }}>
        <input
          ref={inputEl}
          onBlur={(e) => {
            var number = Number(e.target.value);
            // if (
            //   orderDetails.wine.volume &&
            //   orderDetails.wine.volume.label === "1.5"
            // ) {
            //   if (number < 300 || number > 600) {
            //     alert("Number of botles should be between 300 and 600");
            //   } else {
            //     updateValue("noOfBottles", e.target.value);
            //   }
            // } else {
            if (number < 8000
              // || number > 30000
            ) {
              // alert("Number of botles should be between 8000 and 30,000");
              alert("Number of botles should be 8000 or more then 8000");
            } else {
              updateValue("noOfBottles", e.target.value);
            }
            // }
          }}
          type="number"
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "7px",
            paddingLeft: 10,
            border: "1px solid #ddd",
          }}
          placeholder="No. of bottles"
        />
      </div>
    );
  };


  const bottlesInput = React.useMemo(() => nonbottlesInput);
  return shouldRender ? (
    <Grid container direction="column">
    <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={6}>
      <Box mt={5} mb={3}>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold" }}
          variant="h2"
        >
          &euro; {price ? price.toFixed(2) : price}
        </Typography>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold", color: "#e2b50c" }}
          variant="h6"
        >
          Item Price: &euro; {wineItemPrice.toFixed(2)}
        </Typography>
        {console.log('000000-----wineType', WineTypeo)}
      </Box>
  
   
      <Grid
        onClick={() => {
          DeclearValueOfWine()
          // setPassData(data["volume"]);
          console.log('colors', colour);
          setPassData(colour);
          openDrawer("Acidity", "Acidity");
          openDrawer("Color", "Colour");

        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Color?.label}
          label={"Color"}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
         
        />
      </Grid>

      <Grid
        onClick={() => {
          // setPassData(data["volume"]);
          if (Color.label !== 'Tap to select') {
            console.log('Color.label', Color.label);
            setWineColorFilter(Color?.label)
            let wineColorData = wineAllData.docs.filter(y => y.data().colour === Color.label)
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().grape, { label: m.data().grape, value: m.data().grape ,imgUrl : m.data().imageLinkGrape }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("Grape", "Grape");
            setWineColorFilter(Color?.label)
          } else {
            setWineColorFilter(Color?.label)
            alert('Please select the wine first')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Grape?.label}
          label={"Grape"}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          // setPassData(data["volume"]);
          if (Color.label !== 'Tap to select') {
            let wineColorData = wineAllData.docs.filter(y => y.data().colour === Color.label && y.data().grape === Grape?.label)
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().stillSparkling, { label: m.data().stillSparkling, value: m.data().stillSparkling, imgUrl : m.data().imageLinkGlass }])).values()]
            console.log('wineColorData', GrapeData);
            let check = GrapeData.filter(x=>x.value.includes('/'))
            if(check.length){
            let multiColor = check[0]?.value?.split('/')
            if(check[0]?.value?.includes('/')){
              console.log('wineColorData',multiColor)
              let newDataArr =   multiColor.map(x=>{
                   return ({ label: x, value: x })
                  })
    
              console.log('wineColorData',newDataArr)
                 setPassData(newDataArr);
                }
              }else{
                setPassData(GrapeData)

              }
            // setPassData(GrapeData);
            openDrawer("StillSparkling", "Still/Sparking");
          } else {
            alert('Please select the wine first')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={StillSparkling?.label}
          label={"Still/Sparking"}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
  
     
   
      <Grid
        onClick={() => {
          // setPassData(data["volume"]);
          if (Color.label !== 'Tap to select') {
             db.collection('TargetMarketData')
             .onSnapshot(x => {
             let wineColorData = x.docs.filter(y =>
              y.data().colour == Color.label
              )
              console.log('wwww   ======== ?????>>>>',wineColorData);
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().targetMarket, value: m.data().targetMarket }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("TargetMarket", "Target Market");
            })
          } else {
            alert('Please select the wine first')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={TargetMarket?.label}
          label={"Target Market"}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>

      <Grid
        onClick={() => {
          // setPassData(data["volume"]);
          if (Color.label !== 'Tap to select') {
             db.collection('TargetMarketData')
             .onSnapshot(x => {
             let wineColorData = x.docs.filter(y =>
              y.data().colour.toLowerCase() == Color.label.toLowerCase() && y.data().stillSparkling.toLowerCase() == StillSparkling.label.toLowerCase()  && y.data().targetMarket == TargetMarket.label 
              )
              console.log('wwww   ======== ?????>>>>',wineColorData);
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().price, { label: m.data().price, value: m.data().price }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("PriceRange", "Price Range");
            })
          } else {
            alert('Please select the wine first')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={PriceRange?.label}
          label={"Price Range"}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      {Color.label === 'red' || 'white'?
      <div
        onClick={() => {
          if (Color.label !== 'Tap to select') {
            let wineColorData = wineAllData.docs.filter(y => y.data().colour === Color.label && y.data().grape === Grape?.label && y.data().stillSparkling.toLowerCase().includes(StillSparkling.label.toLowerCase()) )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().sulfiteAdded, { label: m.data().sulfiteAdded, value: m.data().sulfiteAdded , price :m.data().price }])).values()]
            console.log('wineColorData', GrapeData);
            let check = GrapeData.filter(x=>x.value.includes('/'))
            if(check.length >= 1){
            let multiColor = check[0]?.value?.split('/')

            if(check[0]?.value?.includes('/')){
              console.log('wineColorData',multiColor)
              let newDataArr =   multiColor.map(x=>{
                   return ({ label: x, value: x })
                  })
              console.log('wineColorData',newDataArr)
                 setPassData(newDataArr);
  
                }
              }else{
                setPassData(GrapeData)
              }
            // setPassData(GrapeData);
            openDrawer("wineType", "Sulfite");
          } else {
            alert('Please select the wine first')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={wineType?.label}
          label={"Sulfite"}
          // price={
          //   wineType.price
          //     ? wineType.price * (volumeMultiplier[volume.value] || 1)
          //     : ""
          // }
          showIcon={true}
          showCounter={false}
        />
      </div>
      :
      <></>}


      <Grid
        // onClick={() => {
        //   if (wineType && wineType.label === "Sulfites free") {
        //     setPassData(data["wineYearNoSulphites"]);
        //   } else {
        //     setPassData(data["wineYear"]);
        //   }

        //   openDrawer("wineYear", "Year");
        // }}

        onClick={() => {
          if (Color.label !== 'Tap to select' && Grape.label !== 'Tap to select' && StillSparkling.label !== 'Tap to select' && wineType.label !== 'Tap to select') {
            let NewYear = [];
            let wineColorData = wineAllData.docs.filter(y => y.data().colour.toLowerCase() === Color.label.toLowerCase() && y.data().grape.toLowerCase() === Grape.label.toLowerCase() && y.data().stillSparkling.toLowerCase().includes(StillSparkling.label.toLowerCase())  && y.data().sulfiteAdded.toLowerCase().includes(wineType.label.toLowerCase()))
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().year, { label: m.data().year, value: m.data().year }])).values()]
            console.log('wineColorData', wineColorData);
            if (GrapeData[0]?.label?.length >= 4) {
              setWineDataYear([])
              let years = GrapeData[0].label.split(';');
              console.log('wineColorData', years);
              for (let i = 0; i < years.length; i++) {
                NewYear.push({ label: years[i], value: years[i] })
              }
            }
            setPassData(NewYear);
            openDrawer("wineYear", "Year");
          } else {
            alert('please select above items first')
          }
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={wineYear?.label}
          label={"Year"}
          // price={wineYear.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      {Color.label === 'red'?
      <Grid
        onClick={() => {

         
            if (Color.label !== 'Tap to select') {
            if (Color.label === 'red') {
              let WoodyArr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]
              db.collection('TargetMarketData')
              .onSnapshot(x => {
              let wineColorData = x.docs.filter(y =>
               y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
               )
                let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().woody, value: m.data().woody }])).values()]
                 setPassData(WoodyArr);
                 openDrawer("Woody", "Oak influence - low to high", sugData[0]?.label);
                })
          
            } else {
              let WoodyArr = [{ label: 'N/A', value: 'N/A' }]
              setPassData(WoodyArr);
            }
           


          } else {
            alert('Please select the wine first')
          }
        

        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Woody?.label}
          bar = {true}
          label={"Oak influence - low to high"}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      
      </Grid>
      :
      <></>}
      { Color.label === 'red'?
      <Grid
        onClick={() => {
          if (Color.label !== 'Tap to select') {
            if (Color.label === 'red') {
              let WoodyArr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]
              setPassData(WoodyArr);
            } else {
              let WoodyArr = [{ label: 'N/A', value: 'N/A' }]
              setPassData(WoodyArr);
            }
            db.collection('TargetMarketData')
            .onSnapshot(x => {
            let wineColorData = x.docs.filter(y =>
             y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
             )
              let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().tannins, value: m.data().tannins }])).values()]
              //  setPassData(WoodyArr);
               openDrawer("Tannins", "Tannins - low to high", sugData[0]?.label);
              })
            // openDrawer("Tannins", "Tannins - low to high");



          } else {
            alert('Please select the wine first')
          }
          // setPassData(data["volume"]);
          // setPassData(tannins);
          // openDrawer("Tannins", "Tannins");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Tannins?.label}
          label={"Tannins - low to high"}
          bar = {true}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      :
      <></>}
       {Color.label !== 'red'?
      <Grid
        onClick={() => {
          if (Color.label !== 'Tap to select') {
            if (Color.label !== 'red') {
              let WoodyArr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]
              setPassData(WoodyArr);
            } else {
              let WoodyArr = [{ label: 'N/A', value: 'N/A' }]
              setPassData(WoodyArr);
            }
            db.collection('TargetMarketData')
            .onSnapshot(x => {
            let wineColorData = x.docs.filter(y =>
             y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
             )
              let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().freshness, value: m.data().freshness }])).values()]
              //  setPassData(WoodyArr);
               openDrawer("Freshness", "Freshness - low to high", sugData[0]?.label);
              })
            
            // openDrawer("Freshness", "Freshness - low to high");
          } else {
            alert('Please select the wine first')
          }
          // setPassData(data["volume"]);
          // setPassData(freshness);
          // openDrawer("Freshness", "Freshness");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Freshness?.label}
          label={"Freshness - low to high"}
          // price={volume.price}
          bar = {true}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      :
      <></>}
      <Grid
        onClick={() => {
          if (Color.label !== 'Tap to select') {
        
              let WoodyArr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]
              setPassData(WoodyArr);
              db.collection('TargetMarketData')
              .onSnapshot(x => {
              let wineColorData = x.docs.filter(y =>
               y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
               )
                let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().fruity, value: m.data().fruity }])).values()]
                //  setPassData(WoodyArr);
                 openDrawer("Fruity", "Fruitness - low to high", sugData[0]?.label);
                })
            // openDrawer("Fruity", "Fruitness - low to high");
          } else {
            alert('Please select the wine first')
          }
          // setPassData(data["volume"]);
          // setPassData(fruity);
          // openDrawer("Fruity", "Fruity");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Fruity?.label}
          label={"Fruitness - low to high"}
          bar = {true}
          // price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          if (Color.label !== 'Tap to select') {
        
            let WoodyArr = [{ label: 0, value: 0 },{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 },
              { label: 6, value: 6 }, { label: 7, value: 7 }, { label: 8, value: 8 }, { label: 9, value: 9 }, { label: 10, value: 10 },]
            setPassData(WoodyArr);
            db.collection('TargetMarketData')
            .onSnapshot(x => {
            let wineColorData = x.docs.filter(y =>
             y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
             )
              let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().sweetnessSugar, value: m.data().sweetnessSugar }])).values()]
              //  setPassData(WoodyArr);
               openDrawer("Sweetness", "Sweetness/sugar gr/l - low to high", sugData[0]?.label);
              })
            // openDrawer("Sweetness", "Sweetness/sugar gr/l - low to high");
        } else {
          alert('Please select the wine first')
        }
          // setPassData(data["volume"]);
          // setPassData(sweetness);
          // openDrawer("Sweetness", "Sweetness/Sugar");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Sweetness?.label}
          label={"Sweetness/sugar gr/l - low to high"}
          // price={volume.price}
          multiprice= {10}
          showIcon={true}
          bar = {true}
          showCounter={false}
        />
      </Grid>
      {Color.label !== 'red'?
      <Grid
        onClick={() => {
          if (Color.label !== 'Tap to select') {
            if (Color.label !== 'red') {
              let WoodyArr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]
              setPassData(WoodyArr);
            } else {
              let WoodyArr = [{ label: 'N/A', value: 'N/A' }]
              setPassData(WoodyArr);
            }
            db.collection('TargetMarketData')
            .onSnapshot(x => {
            let wineColorData = x.docs.filter(y =>
             y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
             )
              let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().acidity, value: m.data().acidity }])).values()]
              //  setPassData(WoodyArr);
               openDrawer("Acidity", "Acidity - low to high", sugData[0]?.label);
              })
            // openDrawer("Acidity", "Acidity - low to high");
          } else {
            alert('Please select the wine first')
          }
          // setPassData(data["volume"]);
          // setPassData(acidity);
          // openDrawer("Acidity", "Acidity");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Acidity?.label}
          label={"Acidity - low to high"}
          // price={volume.price}
          showIcon={true}
          bar = {true}
          showCounter={false}
        />
      </Grid>
      :
      <></>}
       {Color.label === 'red'?
      <Grid
        onClick={() => {
          // setPassData(data["volume"]);
          if (Color.label !== 'Tap to select') {
            if (Color.label === 'red') {
              let WoodyArr = [{ label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 }]
              setPassData(WoodyArr);
            } else {
              let WoodyArr = [{ label: 'N/A', value: 'N/A' }]
              setPassData(WoodyArr);
            }
            db.collection('TargetMarketData')
            .onSnapshot(x => {
            let wineColorData = x.docs.filter(y =>
             y.data().colour == Color.label && y.data().stillSparkling == StillSparkling.label && y.data().targetMarket == TargetMarket.label && y.data().price == PriceRange.label
             )
              let sugData = [...new Map(wineColorData.map((m) => [m.data().targetMarket, { label: m.data().complexity, value: m.data().complexity }])).values()]
              //  setPassData(WoodyArr);
               openDrawer("Complexity", "Light - low to high", sugData[0]?.label);
              })
            // openDrawer("Complexity", "Light to heavy wine");
          } else {
            alert('Please select the wine first')
          }
          // setPassData(complexity);
          // openDrawer("Complexity", "Complexity");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Complexity?.label}
          label={"Light to heavy wine"}
          // price={Complexity.price}
          showIcon={true}
          bar = {true}
          showCounter={false}
        />
      </Grid>
      :
      <></>}
      {/* <Grid
        onClick={() => {
          // setPassData(data["volume"]);
          setPassData(WineDataCapacity);
          openDrawer("volume", "Volume (L)");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={volume.label}
          label={"Volume (L)"}
          price={volume.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid> */}
      {/* <Grid className={classes.inputRow}>
        <InputTile
          innerOnClick={() => {
            openDrawer("noOfBottles", "No. of Bottles");
          }}
          className={classes.inputRow}
          value={noOfBottles}
          label={"No. of bottles"}
          price={""}
          showIcon={false}
          onChange={(a) => {
            updateValue("noOfBottles", a);
          }}
          // showCounter={true}
        />
      </Grid> */}
      
      <Grid
        container
        justify="center"
        direction="column"
        spacing={0}
        className={classes.parentGrid}
      >
        <Grid lg={6} md={6} sm={12} xs={12} item>
          <Drawer
            drawerTitle={drawerTitle}
            suggest={sugest}
            isVisible={isVisible}
            field={dataType}
            onChange={(field, value) => {
              console.log("This is fields", field, value);
              updateValue(field, value);
            }}
            data={passData}
            MainTitle={'wine'}
            onClose={onClose}
            customInput={dataType == "noOfBottles" && bottlesInput}
          />
        </Grid>
      </Grid>
      </Grid>
      <Grid item xs={2}>
      {orderDetails?.wine?.StillSparkling?.imgUrl?
         <img src={orderDetails?.wine?.StillSparkling?.imgUrl} alt="Uploaded Image" style={{height:280,width:200,marginTop:-1000}} />
      :
      orderDetails?.wine?.Grape?.imgUrl?
         <img src={orderDetails.wine.Grape.imgUrl} alt="Uploaded Image" style={{height:250,width:150,marginTop:-1000}} />
         :
         <></>}
         </Grid>
    </Grid>
    <Box mt={3}>
        <NavigationButtons
          nextRoute={"/order/bottle"}
          disablePrevious={true}
          orderDetails={orderDetails}
          page={"wine"}
        />
      </Box>
    </Grid>
  ) : null;
};

export default Wine;
