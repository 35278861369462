import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AboutUs from "../pages/AboutUs";
import AccountSettings from "../pages/AccountSettings";
import AnimalDetailPage from "../pages/AnimalDetailPage";
import { AppProvider } from "../Context/AppContext";
import BuyPage from "../pages/BuyPage";
import ChangePassword from "../pages/ChangePassword";
import ContactusPage from "../pages/ContactusPage";
import Footer from "../components/ZFooter";
import Home from "../pages/Home";
import HomePage from "../pages/HomePage";
import Logout from "../pages/Logout";
import Navbar from "../components/ZNavbar";
import NotFoundPage from "../pages/NotFoundPage";
import OrderDetails from "../pages/OrderDetails";
import OrderSummary from "../pages/OrderSummary";
import OrdersPage from "../pages/OrdersPage";
import Profile from "../pages/Profile";
import ScrollToTopRoute from "./ScrollToTop";
import SellPage from "../pages/SellPage";
import Signup from "../pages/Signup";

const RouterRoutes = () => {
  const [store, setStore] = useState({
    city: null,
    category: null,
    weight: null,
    price: null,
    animals: [],
  });

  

  useEffect(() => {
  }, [store]);

  const updateStoreData = (key, value) => {
    setStore({ ...store, [key]: value });
  };

  return (
    <AppProvider
      value={{
        storeData: store,
        setFilter: updateStoreData,
      }}
    >
      <Router>
        {/* <Navbar /> */}
        <Switch>
          {[
            UnauthenticatedRoutes,
            <ScrollToTopRoute key={1} component={NotFoundPage} />,
          ]}
        </Switch>
        {/* <Footer /> */}
      </Router>
    </AppProvider>
  );
};

const withPaddingTop = (MComponent) => {
  const mc = () => (
    <div style={{ paddingTop: 0 }}>
      <MComponent></MComponent>
    </div>
  );

  return mc;
};

const UnauthenticatedRoutes = [
  <ScrollToTopRoute exact path="/" component={withPaddingTop(BuyPage)} />,
  <ScrollToTopRoute exact path="/login" component={withPaddingTop(BuyPage)} />,
  <ScrollToTopRoute exact path="/sign-up" component={withPaddingTop(Signup)} />,
  <ScrollToTopRoute exact path="/home" component={withPaddingTop(Home)} />,
  <ScrollToTopRoute
    exact
    path="/orderDetails/:orderUUID"
    component={withPaddingTop(OrderDetails)}
  />,
  <ScrollToTopRoute
    exact
    path="/orderSummary"
    component={withPaddingTop(OrderSummary)}
  />,
  <ScrollToTopRoute
    exact
    path="/profile"
    component={withPaddingTop(Profile)}
  />,
  <ScrollToTopRoute
    exact
    path="/accountSettings"
    component={withPaddingTop(AccountSettings)}
  />,
  <ScrollToTopRoute
    exact
    path="/changePassword"
    component={withPaddingTop(ChangePassword)}
  />,
  <ScrollToTopRoute
    exact
    path="/forgotPassword"
    component={withPaddingTop(Profile)}
  />,
  <ScrollToTopRoute
    exact
    path="/aboutUs"
    component={withPaddingTop(AboutUs)}
  />,
  <ScrollToTopRoute
    exact
    path="/animal-detail"
    component={withPaddingTop(AnimalDetailPage)}
  />,
  <ScrollToTopRoute
    exact
    path="/contact-us"
    component={withPaddingTop(ContactusPage)}
  />,
  <ScrollToTopRoute
    exact
    path="/order/:tab"
    component={withPaddingTop(OrdersPage)}
  />,
  <ScrollToTopRoute
    exact
    path="/logout"
    component={withPaddingTop(Logout)}
  />,
];

// const AuthenticatedRoutes = [];

export default RouterRoutes;
