import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core';
import React, { Component } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import { getCurrentUser } from '../Backend/Services/authService'
import { getOrders } from "../Backend/Services/orderService";
import { getUserById } from '../Backend/Services/userService'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      isLoading: true,
    }
  }
  getUserInfo = () => {
    return getCurrentUser().then(a => {
      this.setState({ currentUser: a }, () => {
        getUserById(a.uid).then((b) => {
          this.setState({ userData: b })
        })
      })
    })
  }

  componentDidMount() {
    getOrders()
      .then((res) => {
        this.getUserInfo()
        this.setState({ orders: res, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { classes } = this.props;
    const { orders, isLoading, userData } = this.state;
    return (
      userData ? <div style={{ padding: 15 }}>
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={6} lg={6} md={6}>
            <h1 className={classes.heading}>Home</h1>
          </Grid>
          <Grid onClick={() => { window.location.href = '/profile' }} item xs={6} lg={6} md={6} className={classes.imgTop}>
            <img src={userData.profilePicture ? userData.profilePicture : require('../assets/user-placeholder.png')} className={classes.roundedImg} alt="" />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={5} lg={2} md={2}>
            <HudTile count={orders.length} title={'Current Orders'} />
          </Grid>
          <Grid item xs={5} lg={2} md={2}>
            <HudTile count={763} title={'Total Orders'} />
          </Grid>
        </Grid>
        <Grid spacing={3} container direction="row" justify="space-evenly" alignItems="center">
          <div style={{ marginBottom: 20, width: '100%' }}></div>
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Your Orders</h3>
            {
              orders.length
                ?
                orders.map((order) => (
                  <Grid key={order.uuid}>
                    <OrderTile orderUUID={order.uuid} orderName={`Order No #${order.uuid}`} orderDetails={`${order.noOfBottles} Bottles`} />
                  </Grid>
                ))
                :
                isLoading ? "Fetching orders..." : "No orders found!"
            }
          </Grid>
        </Grid>
        <Grid spacing={3} container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <Link to="/order/wine">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                startIcon={<Icon>add</Icon>}
              >
                Create Order
            </Button>
            </Link>
          </Grid>
        </Grid>

      </div> : null
    )
  }
}


const styles = theme => ({
  root: {
  },
  roundedImg: { width: 50, height: 50, borderRadius: 50, border: '1px solid white', boxShadow: '1px 1px 5px 3px #ddd' },
  heading: { color: 'black', marginLeft: 10 },
  imgTop: { textAlign: 'right' },
  hudTile: {
    borderRadius: '10px',
    height: '165px',
    backgroundColor: '#bd2132',
    boxShadow: '4px 4px 17px 4px rgb(190 33 49 / 30%)',
    '& p': {
      color: 'white',
      textAlign: 'center'
    },
    '& h1': {
      color: 'white',
      textAlign: 'center',
      fontSize: '75px',
      marginBottom: '20px',
      marginTop: '10px',
      paddingTop: '15px',
    }
  },
  button: {
    borderRadius: '8px',
    padding: '10px 20px',
  },
  orderTile: {
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: '#fbfbfb',
    border: '1px solid #dfdfdf',
    padding: '5px 10px',
    textAlign: 'left',
    marginBottom: '10px',
    '& .arrow': {
      position: 'absolute',
      top: '25px',
      right: '10px',
    },
    '& .title': {
      fontWeight: 'bold'
    },
    '& .detail': {
      color: '#bd2132'
    },
    '& p': {
      marginTop: '8px',
      marginBottom: '8px',

    },
    '& h1': {
      color: 'white',
      textAlign: 'center',
      fontSize: '75px',
      marginBottom: '30px',
      marginTop: '10px',
      paddingTop: '15px',
    }
  },
});


const HudTile = withStyles(styles)((props) => (
  <div className={props.classes.hudTile}>
    <h1>{props.count}</h1>
    <p>{props.title}</p>
  </div>
))


const OrderTile = withStyles(styles)((props) => (
  <Link to={`/orderDetails/${props.orderUUID}`}>
    <div className={props.classes.orderTile}>
      <p className={'title'}>{props.orderName}</p>
      <p className={'detail'}>{props.orderDetails}</p>
      <div className='arrow'>
        <ArrowForwardIcon></ArrowForwardIcon>
      </div>
    </div>
  </Link>
))



export default withStyles(styles)(Login);

