import { Box, Grid, Typography } from "@material-ui/core";
import { PriceContext, isEmpty } from "./PriceContext";

import Drawer from "./Drawer";
import InputTile from "../../components/InputTile";
import NavigationButtons from "../../components/NavigationButtons";
import React,{useState} from "react";
import { humanize } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { db } from "../../Backend/firebase";

const defaultPayload = {
  label: "Tap to select",
  value: "tap_to_select",
};

//Drawer
// let volDepTypes = {
//   '750': [
//     { value: 'Bordelaise', label: 'Bordelaise', price: '1.50', imgUrl: 'https://creopack.se/wp-content/uploads/2020/12/Bordelaise-Evolvigne-2.png' },
//     { value: 'Haut Brion', label: 'Haut Brion', price: '3.50', imgUrl: 'https://cdn.shopify.com/s/files/1/0131/6713/2731/products/haut_brion_480a97cf-4e7d-4d7d-9203-e2976330f980_531x531.png?v=1581958158' },
//     { value: 'Bourgonne', label: 'Bourgonne', price: '4.50', imgUrl: 'https://creopack.se/wp-content/uploads/2020/11/BOURGOGNE-ECOVA-.png' }
//   ],

//   '1500': [
//     { value: 'Bordelaise', label: 'Bordelaise', price: '1.50', imgUrl: 'https://creopack.se/wp-content/uploads/2020/12/Bordelaise-Evolvigne-2.png' },
//     { value: 'Bourgonne', label: 'Bourgonne', price: '4.50', imgUrl: 'https://creopack.se/wp-content/uploads/2020/11/BOURGOGNE-ECOVA-.png' }
//   ],
//   '3000': [
//     { value: 'Bordelaise Bassa', label: 'Bordelaise', price: '1.50', imgUrl: 'https://creopack.se/wp-content/uploads/2020/12/Bordelaise-Evolvigne-2.png' },
//   ]
// };

// let typeDepWeight = {
//   'Bordelaise': {
//     '750': [
//       { value: '500', label: '500', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' }
//     ],
//     '1500': [
//       { value: '850', label: '850', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' }
//     ]
//   },
//   'Haut Brion': {
//     '750': [
//       { value: '600', label: '600', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//       { value: '750', label: '750', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//     ],
//   },

//   'Bourgonne': {
//     '750': [
//       { value: '410', label: '410', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//       { value: '480', label: '480', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//       { value: '650', label: '650', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//     ],
//     '1000': [
//       { value: '1000', label: '1000', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//      ]
//   },
//   'Bordelaise BASSA': {
//     '3000': [
//       { value: '1750', label: '1750', price: '5.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
//     ]
//   }
// }

const useStyles = makeStyles((theme) => ({
  inputRow: {
    marginTop: theme.spacing(3),
  },
}));

const Bottle = () => {
  const {
    price,
    setPrice,
    orderDetails,
    setOrderDetails,
    appData,
  } = React.useContext(PriceContext);
  const classes = useStyles();
  const { volume, wine } = orderDetails;
  const [bottleTypeo , setBottleType] = React.useState([]);
  const [bottleWeight , setBottleWeight] = React.useState([]);
  const [bottleDataColor , setBottleDataColor] = React.useState([]);
  const [container , setCountainer] = useState('');
  const [Btype , setBType] = useState('');
  const [capacity , setCapacity] = useState('');
  const [closingB , setClosing] = useState('');
  const [shape , setShape] = useState('');
  const [colour , setColour] = useState('');
  const [weight , setWeight] = useState('');
  const [howMany, setHowMany] = useState('');
  const [wineAllData, setWineAllData] = useState([]);
  const [multiColorData, setMultiColorData] = useState([]);
  const [bottleOptions, setbottleOptions] = useState('');
  const [typeOptions, setTypeOptions] = useState('');
  const [ManuFacPrice , setMenuFacPrice] = useState('')




  const GetBottleTypeo = () =>{
    console.log('ssssss doto');
    

    db.collection('ContainerData')
    .onSnapshot(x=>{
     setbottleOptions(x.docs)
    })
  
    
    let doData =  db.collection('ContainerWineCase')
    .onSnapshot(x=>{
      setWineAllData(x)
    let doto = [...new Map(x.docs.map((m) => [m.data().shape, {label : m.data().shape , value: m.data().shape}])).values()]
    // console.log('ssssss doto', doto);
    setShape(doto);

    let Bweight = [...new Map(x.docs.map((m) => [m.data().weight, {label : m.data().weight , value: m.data().weight}])).values()]
    // console.log('ssssss doto', Bweight);
    setWeight(Bweight);
    let Bcolor = [...new Map(x.docs.map((m) => [m.data().colour, {label : m.data().colour , value: m.data().colour}])).values()]
    // console.log('ssssss doto', Bcolor);
    setColour(Bcolor);

    let Bcontainer = [...new Map(x.docs.map((m) => [m.data().container, {label : m.data().container , value: m.data().container}])).values()]
    // console.log('ssssss doto', Bcolor);
    setCountainer(Bcontainer);
    let Btype = [...new Map(x.docs.map((m) => [m.data().type, {label : m.data().type , value: m.data().type}])).values()]
    // console.log('ssssss doto', Bcolor);
    setBType(Btype);
    let BCapacity = [...new Map(x.docs.map((m) => [m.data().capacity, {label : m.data().capacity , value: m.data().capacity}])).values()]
    // console.log('ssssss doto', Bcolor);
    setCapacity(BCapacity);
    let Bclosing = [...new Map(x.docs.map((m) => [m.data().closing, {label : m.data().closing , value: m.data().closing}])).values()]
    // console.log('ssssss doto', Bcolor);
    setClosing(Bclosing);
    })
  }
  // const getBottleWeight = () =>{
  //   console.log('ssssss doto');
  //   let doData =  db.collection('Wine')
  //   .onSnapshot(x=>{
  //   let doto = [...new Map(x.docs.map((m) => [m.data().sulfiteAdded, {label : m.data().sulfiteAdded , value: m.data().sulfiteAdded , price:10}])).values()]
  //   console.log('ssssss doto', doto);
  //   setWineType(doto);
  //   })
  // }
  // const GetBottleColor = () =>{
  //   console.log('ssssss doto');
  //   let doData =  db.collection('Wine')
  //   .onSnapshot(x=>{
  //   let doto = [...new Map(x.docs.map((m) => [m.data().sulfiteAdded, {label : m.data().sulfiteAdded , value: m.data().sulfiteAdded , price:10}])).values()]
  //   console.log('ssssss doto', doto);
  //   setWineType(doto);
  //   })
  // }

  React.useEffect(()=>{
  
    GetBottleTypeo();
  },[])

  // const data = {
  //   wineWeight: typeDepWeight[volDepTypes[volume ? volume.value : '750'][0].value][volume ? volume.value : '750'],
  //   bottleColor: [
  //     { value: 'Green', label: 'Green', price: '1.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
  //     { value: 'Brown', label: 'Brown', price: '2.50', imgUrl: 'https://www.sothebyswine.com/custom/apps/slatwallcms/assets/images/cache/placeholder_445w_445h.jpg?modifiedDate=' },
  //   ],
  //   bottleType: volDepTypes[volume ? volume.value : '750'],
  // }

  //appData stuff
  const data = Object.assign({}, appData.bottleData);
  console.log("This is the data", data);
  console.log("This is the appdata", appData.bottleData);
  const volDepTypes = appData.volDepTypes;
  const typeDepWeight = appData.typeDepWeight;
  if (volDepTypes) {
    data.bottleType = volDepTypes[volume ? volume.value : "750"];
  }
  data.wineWeight =
    typeDepWeight[volDepTypes[volume ? volume.value : "750"][0].value][
      volume ? volume.value : "750"
    ];
  ////////////////////////////////

  //Drawer Stuff
  const [drawerTitle, setDrawerTitle] = React.useState("wineType");
  const [isVisible, setIsVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState("wineType");
  const [imageBuild, setImageBuild] = React.useState();
  const [passData, setPassData] = React.useState([]);
  const [shouldRender, setShouldRender] = React.useState(false);
  const [compData, setCompData] = React.useState(data);
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  React.useEffect(() => {
    if (isEmpty(orderDetails.bottle)) {
      if(Container && Container?.label?.replaceAll(' ','').toLowerCase() === 'bottle'){
      setOrderDetails("bottle", "bottleColor", defaultPayload);
      setOrderDetails("bottle", "bottleType", defaultPayload);
      setOrderDetails("bottle", "Closing", defaultPayload);
      }

      setOrderDetails("bottle", "Container", defaultPayload);
      setOrderDetails("bottle", "Type", defaultPayload);
      setOrderDetails("bottle", "Capacity", defaultPayload);
      setOrderDetails("bottle", "wineWeight", defaultPayload);

      setOrderDetails("label", "labelType", null);



    

      setTimeout(() => {
        setShouldRender(true);
      }, 500);
    } else {
      setTimeout(() => {
        setShouldRender(true);
      }, 500);
    }
  }, []);

  const updateValue = (field, value) => {
    let r;
    // if (field === "bottleType") {
    //   let nData = compData;
    //   console.log("This is typedep weigit", value.value);
    //   nData.wineWeight =
    //     typeDepWeight[value.value][volume ? volume.value : "750"];
    //   updateValue(
    //     "wineWeight",
    //     typeDepWeight[value.value][volume ? volume.value : "750"][0]
    //   );
    //   console.log("this is compdata", compData);
    //   setCompData(nData);
    // }
    setOrderDetails("bottle", field, value);
    onClose()
    return r;
  };
  const updateBottleValue = (field, value) => {
    let r;
    setOrderDetails("bottle", field, value);
    return r;
  };
  const openDrawer = React.useCallback((dataType, drawerTitle) => {
    setDataType(dataType);
    setDrawerTitle(drawerTitle);
    setIsVisible(true);
  }, []);
  const {
    bottle: {
      wineWeight,
      bottleColor,
      bottleType,
      // bottleType75,
      bottleItemPrice,
      Container,
      Type,
      Capacity,
      Closing,
      noOfBottles,
      menufacturingPrice,

    },
  } = orderDetails;
  console.log("THis is orderdetails", orderDetails);

  const inputEl = React.useRef(null);
  
  const nonbottlesInput = () => {
    return (
      <div style={{ padding: "10px 20px 10px 10px" }}>
        <input
          ref={inputEl}
          onBlur={(e) => {
            var number = Number(e.target.value);
            // if (
            //   orderDetails.wine.volume &&
            //   orderDetails.wine.volume.label === "1.5"
            // ) {
            //   if (number < 300 || number > 600) {
            //     alert("Number of botles should be between 300 and 600");
            //   } else {
            //     updateValue("noOfBottles", e.target.value);
            //   }
            // } else {
              if (number < howMany[0]?.data()?.minOrder 
                // || number > 30000
                ) {
                // alert("Number of botles should be between 8000 and 30,000");
                alert(`Number of Container more then ${howMany[0]?.data()?.minOrder}`);
              } else {
                updateValue("noOfBottles", e.target.value);
                let wineColorData = wineAllData.docs.filter(y =>  y.data()?.container.toLowerCase().includes(Container?.label.toLowerCase()) && y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data()?.wineType.toLowerCase().includes(orderDetails?.wine?.StillSparkling?.label.toLowerCase()) && y.data()?.sulfiteAdded.toLowerCase().includes(orderDetails.wine?.wineType?.label.toLowerCase().includes('Sulfite free'.toLowerCase())? 'free' : orderDetails.wine?.wineType?.label.toLowerCase()) && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label && y.data()?.closing === Closing?.label && y.data()?.shape === bottleType?.label &&  y.data()?.colour === bottleColor?.label)
            
                 let GrapeData = [...new Map(wineColorData.map((m) => [m.data().weight, { label: m.data().weight, value: m.data().weight , price: m.data().price }])).values()]
               console.log('loop ',howMany[0]?.data(),wineColorData[0]?.data(),GrapeData);
               if(number > 0 && number < 5001){
                setPrice(price + JSON.parse(howMany[0]?.data()?.zeroToFive ? howMany[0]?.data()?.zeroToFive : '0'))
               }else{
                if(number > 5000 && number < 9001){
                  setPrice(price + JSON.parse(howMany[0]?.data()?.fiveToNine ? howMany[0]?.data()?.fiveToNine : '0'))
                 }else{
                  if(number > 9000 && number < 13001){
                    setPrice(price + JSON.parse(howMany[0]?.data()?.nineToThirteen ?howMany[0]?.data()?.nineToThirteen :'0' ))
                   }else{
                    if(number > 13000 ){
                      setPrice(price + JSON.parse(howMany[0]?.data()?.thirteenToNinteen? howMany[0]?.data()?.thirteenToNinteen : '0'))
                     }
                   }
                 }
               }
                //imran

              }
            // }
          }}
          type="number"
          style={{
            width: "100%",
            height: "50px",
            borderRadius: "7px",
            paddingLeft: 10,
            border: "1px solid #ddd",
          }}
          placeholder="No. of bottles"
        />
      </div>
    );
  };

 
  const bottlesInput = React.useMemo(() => nonbottlesInput);
  return shouldRender ? (
    <Grid container direction="column">
    <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={6}>
      <Box mt={6} mb={3}>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold" }}
          variant="h2"
        >
          &euro; {(price - wine.wineItemPrice).toFixed(2)}
        </Typography>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold", color: "#e2b50c" }}
          variant="h6"
        >
          Item Price: &euro;{bottleItemPrice.toFixed(2)}
        </Typography>
      </Box>
      <Grid
        onClick={() => {
          setOrderDetails("bottle", "Container", null);
          setOrderDetails("bottle", "Type", null);
          setOrderDetails("bottle", "Capacity", null);



          console.log('000000----',orderDetails);
          if (orderDetails?.wine && orderDetails?.wine?.Color?.label !== 'Tap to select' && orderDetails?.wine?.StillSparkling?.label !== 'Tap to select' && orderDetails?.wine?.wineType?.label !== 'Tap to select') {
            let ContData = orderDetails?.wine
            // console.log('Color.label', ContData,wineAllData.docs.map((x)=> x.data().wineColour ));
            let wineColorData = wineAllData.docs.filter(y => y.data().wineColour.toLowerCase().includes(ContData?.Color?.label.toLowerCase()) && y.data().wineType.toLowerCase().includes(ContData?.StillSparkling?.label.toLowerCase()) && y.data().sulfiteAdded.toLowerCase().includes(ContData?.wineType?.label.toLowerCase().includes('Sulfite free'.toLowerCase())? 'free' : ContData?.wineType?.label.toLowerCase()) )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().container, { label: m.data().container, value: m.data().container, Manuprice: m.data().bottlingProcess, imgUrl : bottleOptions.find(x=>x.data().itemName.toLowerCase() === m.data().container.toLowerCase()).data().imageLink , imagebuilder: m.data().imagebuilding }])).values()]
            console.log('wineColorData', GrapeData,wineColorData);
            
            if(GrapeData.length > 0){
            setPassData(GrapeData);
            openDrawer("Container", "Container");
            setOrderDetails("cork","corkBrand", null);
            setOrderDetails("cork","corkItemPrice", null);
            setOrderDetails("cork","corkSize", null);
            setOrderDetails("cork","corkType", null);


            setOrderDetails("cap","Brand", null);
            setOrderDetails("cap","capItemPrice", null);
            setOrderDetails("cap","capSize", null);
            setOrderDetails("cap","capType", null);
            setOrderDetails("cap","capColor", null);


            setOrderDetails("label", "labelType", null);
            setOrderDetails("bottle", "bottleType", null);
            setOrderDetails("bottle", "wineWeight", null);
            setOrderDetails("bottle", "bottleColor", null);
            setOrderDetails("bottle", "bottleItemPrice", 0);
            setOrderDetails("wineCase", "cartonType", null);
            setOrderDetails("wineCase", "caseType", null);
            setOrderDetails("wineCase", "ExtraPrinting", null);




            }else{
            alert('No Container available please select different wine & StillSparkling & Sulfite');
            }
            // setPassData(container);
            // openDrawer("Container", "Container");
          }else{
            alert('Please select the wine & StillSparkling & Sulfite first');
          }
        
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Container?.label}
          label={"Container"}
          // price={bottleType.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
     
      <Grid
        onClick={() => {
          if (Container?.label !== 'Tap to select') {
            console.log('Color.label', Container.label);
           
            let TypeOptionsNew = ''
            db.collection(Container?.label.toLowerCase() === 'bottle' ? 'BottleType' : Container?.label.toLowerCase() === 'can' ? 'Can'  : 'Bib')
            .onSnapshot(otp =>{
            
            let wineColorData = wineAllData.docs.filter(y => y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) &&  y.data().container === Container.label && y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data()?.wineType.toLowerCase().includes(orderDetails?.wine?.StillSparkling?.label.toLowerCase()) && y.data()?.sulfiteAdded.toLowerCase().includes(orderDetails.wine?.wineType?.label.toLowerCase().includes('Sulfite free'.toLowerCase())? 'free' : orderDetails.wine?.wineType?.label.toLowerCase()))
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().type, { label: m.data().type, value: m.data().type  , 
              imgUrl : otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.type.toLowerCase() )?.data()?.imageLink ? 
              otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.type.toLowerCase() )?.data()?.imageLink 
              : ''
             }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("Type", "Type");
             
          })
          } else {
            alert('Please select the Container')
          }
            // setPassData(Btype);
            // openDrawer("Type", "Type");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Type?.label}
          label={"Type"}
          // price={bottleType.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          if (Container?.label !== 'Tap to select') {
          if(Container?.label !== 'bottle'){
            setMenuFacPrice(Container.Manuprice)
            setOrderDetails("bottle", 'manuFacturingPrice', {label:"manuFacturingPrice",value:"manuFacturingPrice", price: Container.Manuprice});
          }
            console.log('Color.label', Container.label);
            let wineColorData = wineAllData.docs.filter(y =>  y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data().container === Container.label && y.data().type === Type.label)
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().capacity, { label: m.data().capacity, value: m.data().capacity  }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("Capacity", "Capacity / CL");
          } else {
            alert('Please select the Container')
          }
            // setPassData(capacity);
            // openDrawer("Capacity", "Capacity / CL");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Capacity?.label}
          label={"Capacity"}
          // price={bottleType.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      {Container && Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox' ||  Container?.label?.replaceAll(' ','').toLowerCase() === 'can'?
      <></>
      :
      <Grid
        onClick={() => {
          if (Container?.label !== 'Tap to select') {
            console.log('Color.label', Container.label);
            db.collection(Container?.label.toLowerCase() === 'bottle' && Type?.label.toLowerCase() === 'glass'  ? 'BottleGlassClosing' : Container?.label.toLowerCase() === 'bottle' && Type?.label.toLowerCase() === 'pet' ? 'BottlePetClosing' :  Container?.label.toLowerCase() === 'can' ? 'CanClosing'  : 'BibClosing')
            .onSnapshot(otp =>{
            let wineColorData = wineAllData.docs.filter(y => y.data()?.container === Container?.label && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().closing, { label: m.data().closing, value: m.data().closing , imgUrl : otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.closing.toLowerCase() )?.data()?.imageLink  }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("Closing", "Closing");
            })
          } else {
            alert('Please select the Container')
          }
            // setPassData(closingB);
            // openDrawer("Closing", "Closing");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={Closing?.label}
          label={"Closing"}
          // price={bottleType.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
}
      

      {/* <Grid
        onClick={() => {
          if (Container?.label !== 'Tap to select') {
            console.log('Color.label', Container.label);
            let wineColorData = wineAllData.docs.filter(y => y.data()?.container === Container?.label && y.data()?.type === Type?.label)
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().bottlingProcess, { label: m.data().bottlingProcess, value: m.data().bottlingProcess,  }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("Bottling proccess", "BottlingProccess");
          } else {
            alert('Please select the Container')
          }
            // setPassData(closingB);
            // openDrawer("Closing", "Closing");
        }}
        className={classes.inputRow}
      >
        <InputTile
          // value={Closing?.label}
          label={"Bottling proccess"}
          // price={bottleType.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid> */}
     



     {Container && Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox' || Container?.label?.replaceAll(' ','').toLowerCase() === 'can'?
      <></>
      :
      <Grid
        onClick={() => {
          setOrderDetails("bottle", "bottleType", "Tap to select");
          setOrderDetails("bottle", "bottleColor", 'Tap to select');
          if (Container.label !== 'Tap to select') {
            console.log('Color.label', Container.label);
            db.collection('ShapeData')
            .onSnapshot(otp =>{
            let wineColorData = wineAllData.docs.filter(y => y.data()?.container.toLowerCase().includes(Container?.label.toLowerCase()) && y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data()?.wineType.toLowerCase().includes(orderDetails?.wine?.StillSparkling?.label.toLowerCase()) && y.data()?.sulfiteAdded.toLowerCase().includes(orderDetails.wine?.wineType?.label.toLowerCase().includes('Sulfite free'.toLowerCase())? 'free' : orderDetails.wine?.wineType?.label.toLowerCase()) && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label && y.data()?.closing === Closing?.label && y.data()?.container === Container?.label && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label && y.data()?.closing === Closing?.label)
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().shape, { label: m.data().shape, value: m.data().shape , imgUrl: otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.data()?.shape.toLowerCase() )?.data()?.imageLink  }])).values()]
            console.log('wineColorData', GrapeData);
            setPassData(GrapeData);
            openDrawer("bottleType", "Shape");
            })
          } else {
            alert('Please select the Container')
          }
            // setPassData(shape);
            // openDrawer("bottleType", "Shape");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={bottleType?.label}
          label={"Shape"}
          price={bottleType?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
}

     {Container && Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox' ||  Container?.label?.replaceAll(' ','').toLowerCase() === 'can'?
      <></>
      :
   
      <Grid
        // onClick={() => {
        //   if (
        //     orderDetails.wine.volume &&
        //     orderDetails.wine.volume.label === "0.75"
        //   ) {
        //     if (
        //       orderDetails.bottle.bottleType &&
        //       orderDetails.bottle.bottleType.label === "Haut Brion"
        //     ) {
        //       setPassData([{ label: "Brown", value: "Brown", price: "0" }]);
        //     } else {
        //       setPassData(data["bottleColor"]);
        //     }
        //   } else {
        //     if (
        //       orderDetails.bottle.bottleType &&
        //       orderDetails.bottle.bottleType.label === "Bordelaise"
        //     ) {
        //       setPassData(data["bottleColorBordelaise1.5"]);
        //     } else if (
        //       orderDetails.bottle.bottleType &&
        //       orderDetails.bottle.bottleType.label === "Bourgonne"
        //     ) {
        //       setPassData(data["Bourgonne"]);
        //     } else {
        //       setPassData(data["wineWeight"]);
        //     }
        //   }

        //   openDrawer("bottleColor", "Bottle Color");
        // }}
        onClick={()=>{
          if (Container.label !== 'Tap to select') {
            console.log('Color.label', Container.label);
           
            // db.collection('ContainerColourData')
            // .onSnapshot(otp =>{
            let wineColorData = wineAllData.docs.filter(y => y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data()?.container === Container?.label && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label && y.data()?.closing === Closing?.label && y.data()?.shape === bottleType?.label && y.data()?.sulfiteAdded.toLowerCase().includes(orderDetails?.wine?.wineType?.label.toLowerCase()) )
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().colour, { label: m.data().colour, value: m.data().colour , builderImg : m.data().imagebuilding , 
              whiteBottle:m.data().whitebottle,
              greenBottle:m.data().greenBottle,
              brownBottle:m.data().brownBottle,
              imgUrl: m.data().whitebottle ? m.data().whitebottle : m.data().greenBottle ? m.data().greenBottle : m.data().brownBottle,
              ProcessingPrice:m.data().bottlingProcess,

             }])).values()]
            let imageBuidingImg = [...new Map(wineColorData.map((m) => [m.data().imagebuilding, { label: m.data().imagebuilding, value: m.data().imagebuilding}])).values()]
            setImageBuild(imageBuidingImg[0]?.value)
            if(Container?.label === 'bottle'){
              setMenuFacPrice(GrapeData[0]?.ProcessingPrice)
              setOrderDetails("bottle", 'manuFacturingPrice', {label:"manuFacturingPrice",value:"manuFacturingPrice", price: GrapeData[0]?.ProcessingPrice});
            }
            let multiColor = GrapeData[0]?.value?.includes('/') ? GrapeData[0]?.value?.split('/') : GrapeData[1]?.value?.split('/')
            console.log('imageBuidingImg',imageBuidingImg,GrapeData)
            if(GrapeData[0]?.value?.includes('/') || GrapeData[1]?.value?.includes('/')){
          console.log('wineColorData',multiColor)
          // GrapeData.map((otp)=>{
          let newDataArr =   multiColor.map(x=>{
               return ({ label: x, value: x , 
                 imgUrl:x.toLowerCase() === 'white'? GrapeData[0].whiteBottle ? GrapeData[0].whiteBottle : GrapeData[1].whiteBottle   :x.toLowerCase() === 'green'?  GrapeData[0].greenBottle ? GrapeData[0].greenBottle : GrapeData[1].greenBottle  :  x.toLowerCase() === 'brown'? GrapeData[0].brownBottle ? GrapeData[0].brownBottle : GrapeData[1].brownBottle : '' ,
                  builderImg : GrapeData[0]?.builderImg,
                  // price:Container?.label === 'bottle'? GrapeData[0]?.price : null,
                })
              })
            // })
          console.log('wineColorData',newDataArr)
             setPassData(newDataArr);
            }else{
             setPassData(GrapeData)
            }
            openDrawer("bottleColor", "Bottle Color");
          // })
          } else {
            alert('Please select the Container')
          }
          // setPassData(colour);
          // openDrawer("bottleColor", "Bottle Color");
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={bottleColor?.label}
          label={"Color"}
          price={bottleColor?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
}
      <Grid className={classes.inputRow}>
        <InputTile
          innerOnClick={() => {
            openDrawer("noOfBottles", "No. of Bottles");
          }}
          className={classes.inputRow}
          value={noOfBottles}
          label={"No. of bottles"}
          price={""}
          showIcon={false}
          onChange={(a) => {
            updateBottleValue("noOfBottles", a);
          }}
          // showCounter={true}
        />
      </Grid>
      <Grid
       
       onClick={()=>{
        if (Container.label !== 'Tap to select') {
          console.log('Color.label', Container.label);
          let wineColorData =[]
          if(Container?.label === 'bottle'){
            wineColorData =   wineAllData.docs.filter(y =>  
            y.data()?.container.toLowerCase().includes(Container?.label.toLowerCase()) && y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data()?.wineType.toLowerCase().includes(orderDetails?.wine?.StillSparkling?.label.toLowerCase()) && y.data()?.sulfiteAdded.toLowerCase().includes(orderDetails.wine?.wineType?.label.toLowerCase().includes('Sulfite free'.toLowerCase())? 'free' : orderDetails.wine?.wineType?.label.toLowerCase()) && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label &&
           y.data()?.closing === Closing?.label && y.data()?.shape === bottleType?.label &&  y.data()?.colour.toLowerCase().includes(bottleColor?.label.toLowerCase()
           ))
           }else{
            wineColorData =   wineAllData.docs.filter(y =>  
              y.data()?.container.toLowerCase().includes(Container?.label.toLowerCase()) && y.data()?.wineColour.toLowerCase().includes(orderDetails?.wine?.Color?.label.toLowerCase()) && y.data()?.wineType.toLowerCase().includes(orderDetails?.wine?.StillSparkling?.label.toLowerCase()) && y.data()?.sulfiteAdded.toLowerCase().includes(orderDetails.wine?.wineType?.label.toLowerCase().includes('Sulfite free'.toLowerCase())? 'free' : orderDetails.wine?.wineType?.label.toLowerCase()) && y.data()?.type === Type?.label && y.data()?.capacity === Capacity?.label
             )
           }
           
          setHowMany(wineColorData);
          let GrapeData = []
          if(noOfBottles > 0 && noOfBottles < 5001 && wineColorData[0].data().colour.includes('/') ){
           GrapeData = [...new Map(wineColorData.map((m) => [m.data().weight, { label: m.data().weight, value: m.data().weight , price: bottleColor.label === 'brown' ? m.data().brownzeroToFive ? m.data().brownzeroToFive : m.data().zeroToFive: bottleColor.label === 'green' ? m.data().greenzeroToFive ? m.data().greenzeroToFive : m.data().zeroToFive :  m.data().whitezeroToFive ? m.data().whitezeroToFive : m.data().zeroToFive  }])).values()]
          }else{
          if(noOfBottles > 5000 && noOfBottles < 9001 && wineColorData[0]?.data()?.colour.includes('/')){
            GrapeData = [...new Map(wineColorData.map((m) => [m.data().weight, { label: m.data().weight, value: m.data().weight , price: bottleColor.label === 'brown' ? m.data().brownfiveToNine ? m.data().brownfiveToNine  : m.data().fiveToNine: bottleColor.label === 'green' ? m.data().greenfiveToNine ? m.data().greenfiveToNine : m.data().fiveToNine :  m.data().whitefiveToNine ? m.data().whitefiveToNine :  m.data().fiveToNine }])).values()]
           }else{
            if(noOfBottles > 9000 && noOfBottles < 13001 && wineColorData[0]?.data()?.colour.includes('/')){
              GrapeData = [...new Map(wineColorData.map((m) => [m.data().weight, { label: m.data().weight, value: m.data().weight , price: bottleColor.label === 'brown' ? m.data().brownnineToThirteen ? m.data().brownnineToThirteen  : m.data().nineToThirteen   : bottleColor.label === 'green' ? m.data().greennineToThirteen ? m.data().greennineToThirteen : m.data().nineToThirteen :  m.data().whitenineToThirteen ? m.data().whitenineToThirteen : m.data().nineToThirteen }])).values()]
             }else{
              if(noOfBottles > 13000 && noOfBottles < 19001 && wineColorData[0]?.data()?.colour.includes('/')){
                GrapeData = [...new Map(wineColorData.map((m) => [m.data().weight, { label: m.data().weight, value: m.data().weight , price: bottleColor.label === 'brown' ? m.data().brownthirteenToNinteen ? m.data().brownthirteenToNinteen  : m.data().thirteenToNinteen : bottleColor.label === 'green' ? m.data().greenthirteenToNinteen ? m.data().greenthirteenToNinteen : m.data().thirteenToNinteen :  m.data().whitethirteenToNinteen ? m.data().whitethirteenToNinteen : m.data().thirteenToNinteen }])).values()]
               }else{
                GrapeData = [...new Map(wineColorData.map((m) => [m.data().weight, { label: m.data().weight, value: m.data().weight , 
                  price: noOfBottles > 0 && noOfBottles < 5001 ? m.data().zeroToFive 
                  : noOfBottles > 5000 && noOfBottles < 9001 ? m.data().fiveToNine 
                  : noOfBottles > 9000 && noOfBottles < 13001 ? m.data().nineToThirteen 
                  :  m.data().thirteenToNinteen

                 }])).values()]

               }
             }
           }
          }
          console.log('loop ', GrapeData);
          setPassData(GrapeData);
          openDrawer("wineWeight", "Bottle Weight");
        } else {
          alert('Please select the Container')
        }
        //  setPassData(weight);
        //  openDrawer("wineWeight", "Bottle Weight");
       }}
       className={classes.inputRow}
     >
       <InputTile
         value={wineWeight?.label}
         label={"Weight"}
         price={wineWeight?.price}
         showIcon={true}
         showCounter={false}
       />
     </Grid>
     <br/>
     <Grid>
     <InputTile
         value={'ManuFacturing Cost'}
         label={""}
         price={menufacturingPrice ? menufacturingPrice : ManuFacPrice}
         showIcon={false}
         showCounter={false}
       />
     </Grid>

    
{/*     
      <Box mt={3}>
        <NavigationButtons
          nextRoute={"/order/cork"}
          disablePrevious={false}
          orderDetails={orderDetails}
          page={"wine"}
        />
      </Box> */}
      <Drawer
        drawerTitle={drawerTitle}
        isVisible={isVisible}
        field={dataType}
        onChange={(field, value) => {
          updateValue(field, value);
        }}
        data={passData}
        MainTitle={'bottle'}
        onClose={onClose}
        customInput={dataType == "noOfBottles" && bottlesInput}
      />
    </Grid>
     {/* </Grid> */}
      {/* </Grid> */}
     <Grid item xs={3}>
     {/* {
          orderDetails?.bottle?.bottleType?.imgUrl?
          <img src={orderDetails?.bottle?.bottleType?.imgUrl} alt="Uploaded Image" style={{height:250,width:150,marginTop:-1000}} />
       :
        orderDetails?.bottle?.Type?.imgUrl?
        <img src={orderDetails?.bottle?.Type?.imgUrl} alt="Uploaded Image" style={{height:250,width:150,marginTop:-1000}} />
       :
        orderDetails?.bottle?.Container?.imgUrl?
        <img src={orderDetails?.bottle?.Container?.imgUrl} alt="Uploaded Image" style={{height:250,width:150,marginTop:-1000}} />
     :
     <></>} */}
     {imageBuild && Container?.label?.toLowerCase() === 'bottle'?
          <img src={imageBuild} alt="Uploaded Image" style={{height:300,width:300,marginTop:70}} />
     :
     Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox' && Container?.imagebuilder?
        <img src={Container?.imagebuilder} alt="Uploaded Image" style={{height:270,width:270,marginTop:70}} />
     :
     Container?.label?.replaceAll(' ','').toLowerCase() === 'can' && Container?.imagebuilder?
     <img src={Container?.imagebuilder} alt="Uploaded Image" style={{height:300,width:300,marginTop:70}} />
     :
     <></>}
        </Grid>
   </Grid>
   <Box mt={3}>
       <NavigationButtons
         nextRoute={"/order/cork"}
         disablePrevious={false}
         orderDetails={orderDetails}
         page={"bottle"}
       />
     </Box>
   </Grid>
  ) : null;
};

export default React.memo(Bottle);
