import * as React from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  CategoryOptions,
  CitiesOptions,
  PriceOptions,
  WeightOptions,
} from '../static/_data';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Translate, Visibility, VisibilityOff} from '@material-ui/icons';
import {
  sendForgotPasswordEmail,
  signInWithEmail,
} from '../Backend/Services/authService';

import AppContext from '../Context/AppContext';
import Colors from '../styles/Colors';
import Drawer from 'react-bottom-drawer';
import SelectDropdown from '../components/Select/Select';
import SnackBar from '../components/SnackBar';
import ZAnimalCard from '../components/ZAnimalCard';
import firebase from 'firebase';
import {getAnimalsByFilter} from '../Backend/Services/animalService';
import {useForm} from 'react-hook-form';

const Hidden = () => null;

const useInputStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

interface Props {}

type FormData = {
  email: string;
  password: string;
};

const BuyPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {errors, handleSubmit, control} = useForm<FormData>({});

  let store = {} as any;
  store = React.useContext(AppContext);

  const [data, setData] = React.useState<FormData>({email: '', password: ''});
  const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarVariant, setSnackBarVariant] = React.useState('success');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [resetEmail, setResetEmail] = React.useState<string>('');
  const [resetStatus, setResetStatus] = React.useState<string>('');

  const [showPass, setShowPass] = React.useState(false);
  const [showPage, setShowPage] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);

  React.useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     window.location.href = '/home';
    //   } else {
    //     setShowPage(true);
    //   }
    // });
    var user = firebase.auth().currentUser;
    if (user) {
      window.location.href = '/home';
    } else {
      setShowPage(true);
    }
  }, []);
  const animals = [...store.storeData?.animals];

  const searchAnimals = (data) => {
    const {category, city, price, weight} = data;

    const animal = !!category && category?.value;
    const location = !!city && city?.value;
    const weightFilter = !!weight && weight?.value;
    const priceFilter = !!price && price?.value;
    getAnimalsByFilter(animal, location, weightFilter, priceFilter)
      .then((res) => {
        // setAnimals([...res]);
        store.setFilter('animals', [...res]);
      })
      .catch((err) => {
        window.alert('ERROR');
      });
  };

  const onInputChange = (e) => {
    const {name, value} = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const loginHandler = (e) => {
    e.preventDefault();
    const {email, password} = data;

    signInWithEmail(email, password)
      .then((res) => {
        setIsLoading(false);
        history.push('/home');
      })
      .catch((err) => {
        setIsLoading(false);
        setShowSnackBar(true);
        setSnackBarVariant('error');
        setSnackBarMessage(err);
      });
  };

  return (
    <>
      {showPage && (
        <Container maxWidth="xl" className={classes.container}>
          <div style={{paddingTop: 10, textAlign: 'center'}}>
            <img
              height="100px"
              src={require('../assets/images/winelogo.png')}
              alt=""
            />
          </div>
          <h3 style={{textAlign: 'center', fontWeight: 'bold'}}>
            Login to your account
          </h3>
          <form onSubmit={loginHandler}>
            <Grid container justify="center" alignItems="center">
              <Grid item md={6} xs={12}>
                {/* <Grid item xs={1} implementation='css' mdUp component={Hidden} /> */}
                <Grid
                  // lg={6}
                  // md={6}
                  // sm={12}
                  // xs={12}
                  item
                  className={`${classes.center} ${classes.locationfilterContainer}`}
                >
                  <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    variant="filled"
                    placeholder="abc@example.com"
                    InputProps={{disableUnderline: true}}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </Grid>
                <Grid
                  // lg={6}
                  // md={6}
                  // sm={12}
                  // xs={12}
                  item
                  className={`${classes.center} ${classes.locationfilterContainer}`}
                >
                  <TextField
                    fullWidth
                    name="password"
                    label="Password"
                    variant="filled"
                    type={showPass ? 'text' : 'password'}
                    required
                    onChange={(e) => onInputChange(e)}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPass(!showPass)}
                            edge="end"
                          >
                            {showPass ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  container
                  spacing={0}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="end"
                      control={<Checkbox color="primary" />}
                      label="Remember Me"
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid onClick={() => setShowDrawer(true)} item xs={6}>
                    <p
                      style={{
                        textAlign: 'right',
                        color: '#bd2132',
                        fontWeight: 'bold',
                      }}
                    >
                      Forgot Password?
                    </p>
                  </Grid>
                </Grid>

                <Grid xs={12} item direction="row">
                  <Button
                    className={classes.searchButton}
                    type="submit"
                    size="medium"
                    color="secondary"
                    variant="contained"
                    disabled={isLoading}
                    style={{marginBottom: '20px'}}
                  >
                    Login
                  </Button>
                  <Link to="/sign-up">
                    <Typography
                      gutterBottom
                      variant="body1"
                      component="p"
                      align="center"
                    >
                      Don't have an account?{' '}
                      <span className={classes.linkGreen}> Sign Up</span>
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </form>

          <Drawer
            isVisible={showDrawer}
            onClose={() => {
              setShowDrawer(false);
            }}
          >
            <div style={{textAlign: 'center', marginBottom: 30}}>
              <h2>Reset Your Password</h2>
              <p>
                Enter your email and we will send a link to you where you can
                reset your password.
              </p>
            </div>
            <form
              onSubmit={(a) => {
                a.preventDefault();
                sendForgotPasswordEmail(resetEmail)
                  .then(() => {
                    window.alert(
                      `Pssword Reset Email Sent at ${resetEmail}. Please Check your inbox`
                    );
                    setResetStatus('success');
                    setShowDrawer(false);
                  })
                  .catch(() => {
                    setResetStatus('fail');
                  });
              }}
            >
              <TextField
                fullWidth
                label="Email"
                variant="filled"
                type="email"
                error={resetStatus == 'fail'}
                placeholder="abc@example.com"
                onChange={(a) => {
                  setResetEmail(a.target.value);
                }}
                InputProps={{disableUnderline: true}}
                className={classes.forgotFiled}
              />
              {resetStatus == 'fail' && (
                <p style={{color: 'red'}}>
                  Reset Email Not Sent, Email is not registered
                </p>
              )}
              <Button
                className={classes.searchButton}
                size="medium"
                type="submit"
                color="secondary"
                variant="contained"
                style={{marginBottom: 30}}
              >
                Continue
              </Button>
            </form>
          </Drawer>
        </Container>
      )}
      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          message={snackBarMessage}
          onClose={() => setShowSnackBar(false)}
          variant={snackBarVariant}
          autoHideDuration={2000}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    parentGrid: {
      [theme.breakpoints.up('sm')]: {
        transform: 'Translate(calc( (calc(100vw / 12) * 2.5)))',
      },
    },
    container: {
      marginTop: 64, //header's height
      minHeight: '80vh',
    },
    paper: {},
    searchButton: {
      transition: 'all 0.5s',
      textTransform: 'capitalize',
      color: '#fff',
      backgroundColor: '#bd2132',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      borderRadius: 5,
      minWidth: '100%',
      border: 'none',
      padding: '10px 20px',
      boxShadow: '2px 2px 9px 2px rgb(0 0 0 / 20%)',
      '&:hover': {
        transition: 'all 0.5s',
        border: 'none',
        borderColor: 'none',
        backgroundColor: '#bd2132',
        boxShadow: '5px 5px 9px 2px rgb(0 0 0 / 20%)',
      },
    },
    filtersBox: {
      padding: theme.spacing(3, 0, 2, 0),
    },
    forgotFiled: {
      marginBottom: 20,
      '& .MuiTextField-root': {
        width: '100%',
        borderRadius: '5px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#fdfdfd',
        border: '1px solid #ddd',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
    locationfilterContainer: {
      padding: theme.spacing(0, 0, 2, 0),
      '& .MuiTextField-root': {
        width: '100%',
        borderRadius: '5px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#fdfdfd',
        border: '1px solid #ddd',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
    filterContainer: {
      padding: theme.spacing(2, 2, 2, 0),

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2, 0),
      },
    },
    resultsContainer: {
      padding: theme.spacing(2, 0, 2, 0),
      minHeight: '50vh',

      [theme.breakpoints.down('sm')]: {
        minHeight: '25vh',
      },
    },
    center: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    seperator: {
      color: Colors.placeHolderText,
      margin: 0,
    },
    linkGreen: {
      cursor: 'pointer',
      color: Colors.appRed,
      fontWeight: 600,
    },
  })
);

BuyPage.defaultProps = {};

export default withRouter(BuyPage);
