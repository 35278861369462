import { Order } from "../Models/order";
import { Users } from "../Models/users";
import { db } from "../firebase";
import firebase from "firebase";

export const getOrders = async function () {
  const user = await firebase.auth().currentUser;
  const query = await db
    .collection("Orders")
    .where("userId", "==", user.uid)
    .limit(20)
    .get();

  let orders = [];

  query.docs.forEach((doc) => {
    const order = Order.fromFirestore(doc);
    if (order) {
      orders.push(order);
    }
  });
  return orders;
};

export const createOrder = async function (data) {
  await db.collection("Orders").add({...data});
};

// export const createwineData = async function (data) {
//   await db.collection("wineCaseDataNew").doc("wineCaseDataTest").add(data);
// };

export async function createwineData(collection, doc, array, value) {
  //alert(doc);
  firebase.firestore().collection(collection).doc(doc).update({
    array,
  });
}

export const deleteOrder = async function (id) {
  await db.collection("Orders").doc(id).delete();
};

export const updateOrder = async function (id, data) {
  await db.collection("Orders").doc(id).set(data, { merge: true });
};

export const getOrderById = async function (id) {
  const query = await db.collection("Orders").doc(id).get();
  return Order.fromFirestore(query);
};
