import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { PriceContext, isEmpty } from "./PriceContext";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Drawer from "./Drawer";
import InputTile from "../../components/InputTile";
import NavigationButtons from "../../components/NavigationButtons";
import React from "react";
import { humanize } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { createwineData } from "../../Backend/Services/orderService";
import { db } from "../../Backend/firebase";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    margin: theme.spacing(3, 0),
  },
}));

const defaultPayload = {
  label: "Tap to select",
  value: "tap_to_select",
};

const WineCase = () => {
  const classes = useStyles();
  const {
    price,
    setPrice,
    orderDetails,
    setOrderDetails,
    appData,
  } = React.useContext(PriceContext);

  //appData stuff
  const data = Object.assign({}, appData.wineCaseData);
  console.log("THis is app data", appData.wineCaseDataNew);
  ////////////////////////////////

  const [drawerTitle, setDrawerTitle] = React.useState("wineType");
  const [isVisible, setIsVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState("wineType");
  const [passData, setPassData] = React.useState([]);
  // const [caseType, setCaseType] = React.useState('Wood Case 2 x 3 Carton');
  const [shouldRender, setShouldRender] = React.useState(false);
  const [wineCaseType , setWineCaseType] = React.useState([]);
  const [bottleTypeo , setBottleType] = React.useState([]);
  const [wineAllData, setWineAllData] = React.useState([]);

  const GetBottleTypeo = () =>{
    console.log('ssssss doto');
    let doData =  db.collection('ContainerWineCase')
    .onSnapshot(x=>{
      setWineAllData(x);
    let Bweight = [...new Map(x.docs.map((m) => [m.data().wineCase, {label : m.data().wineCase , value: m.data().wineCase}])).values()]
    console.log('ssssss doto', Bweight);
    setWineCaseType(Bweight);

    let doto = [...new Map(x.docs.map((m) => [m.data().format, {label : m.data().format , value: m.data().format}])).values()]
    console.log('ssssss doto', doto);
    setBottleType(doto);

    })
  }

  React.useEffect(()=>{
  
    GetBottleTypeo();
  },[])

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  React.useEffect(() => {
    if (isEmpty(orderDetails.wineCase) && orderDetails?.bottle?.Container?.label.toLowerCase() === 'bottle') {
      console.log("This is the data", data);
      
      setOrderDetails("wineCase", "caseType", defaultPayload);
      setOrderDetails("wineCase", "cartonType", defaultPayload);
      setOrderDetails("wineCase", "caseCliche", data.caseCliche);
      setOrderDetails("wineCase", "ExtraPrinting", defaultPayload);
      console.log("now adding the data");

      // createwineData("appData", "wineCaseDataNew", wineCaseData, [])
      //   .then(() => {
      //     // this.setState({ showDrawer: true });
      //     alert("Data was added successifull");
      //   })
      //   .catch((e) => {
      //     console.log("This is great", e);
      //     alert("what the hell is happening");
      //   })
      //   .catch((err) => {});

      // setOrderDetails('cork', 'capLook', data.capLook[0] )
      setTimeout(() => {
        setShouldRender(true);
      }, 700);
    } else {
      setTimeout(() => {
        setShouldRender(true);
      }, 700);
    }
  }, []);

  const setWineCaseData = (bottleSize, bottleWeight, bottleName) => {
    console.log(
      "This is case type",
      orderDetails.wineCase.cartonType,
      bottleSize,
      bottleWeight
    );
    var caseData = [];
    if (bottleSize === null) {
      alert("Please select wine volume first!!");
    } else if (bottleWeight === null || bottleName == null) {
      alert("Please select a bottle first!!");
    } else if (!orderDetails.wineCase.cartonType) {
      alert("Please select a carton type first!!");
    } else {
      appData.wineCaseDataNew &&
        appData.wineCaseDataNew.array.map((bottleCase) => {
          if (
            bottleCase.bottleSize === bottleSize &&
            bottleCase.bottleName === bottleName
          ) {
            console.log(
              "THis is bottle size",
              bottleCase.bottleSize,
              bottleSize,
              bottleCase.bottleWeight,
              bottleWeight,
              bottleCase.bottleName,
              bottleName,
              bottleCase.cartonType,
              orderDetails.wineCase.cartonType.value
            );
          }

          if (
            bottleCase.bottleSize === bottleSize &&
            bottleCase.bottleName === bottleName &&
            bottleCase.bottleWeight === Number(bottleWeight) &&
            bottleCase.cartonType === orderDetails.wineCase.cartonType.value
          ) {
            bottleCase.label = bottleCase.caseType;
            bottleCase.value = bottleCase.caseType;
            caseData.push(bottleCase);
          }
        });
      setPassData(caseData);
      openDrawer("caseType", "Case Type");
    }
  };

  const wineCaseData = [
    {
      bottleSize: "0.75L",
      caseType: "6 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.15",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.24",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.26",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.15",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.15",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.17",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "Wine case 2 x 3",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.10",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "Wine case 2 x 3",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "Wine case 2 x 3",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.14",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "Wine case 2 x 3",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "Wine case 2 x 3",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "Wine case 2 x 3",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },

    {
      bottleSize: "0.75L",
      caseType: "6 flat - Carton",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.60",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Carton",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.61",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Carton",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.62",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Carton",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.59",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Carton",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.59",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Carton",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.59",
      cartonType: "Wood case",
      imgUrl: "",
    },

    {
      bottleSize: "0.75L",
      caseType: "12 bottles - 2x6",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "12 bottles - 2x6",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "12 bottles - 2x6",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "12 bottles - 2x6",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.12",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "12 bottles - 2x6",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "12 bottles - 2x6",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.13",
      cartonType: "Carton",
      imgUrl: "",
    },

    {
      bottleSize: "0.75L",
      caseType: "6 flat - Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.59",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.65",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.66",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.64",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.64",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "6 flat - Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.64",
      cartonType: "Wood case",
      imgUrl: "",
    },

    {
      bottleSize: "0.75L",
      caseType: "2x3 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.37",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x3 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.40",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x3 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.40",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x3 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.40",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x3 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.40",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x3 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.40",
      cartonType: "Wood case",
      imgUrl: "",
    },

    {
      bottleSize: "0.75L",
      caseType: "2x6 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 500,
      bottleName: "Bordelaise",
      price: "0.32",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x6 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 600,
      bottleName: "Haut Brion",
      price: "0.33",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x6 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 750,
      bottleName: "Haut Brion",
      price: "0.34",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x6 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 410,
      bottleName: "Bourgonne",
      price: "0.33",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x6 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 480,
      bottleName: "Bourgonne",
      price: "0.33",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "0.75L",
      caseType: "2x6 Guillotine",
      min: 8000,
      max: 18000,
      bottleWeight: 650,
      bottleName: "Bourgonne",
      price: "0.33",
      cartonType: "Wood case",
      imgUrl: "",
    },
    {
      bottleSize: "1.5L",
      caseType: "3 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 850,
      bottleName: "Bordelaise",
      price: "0.67",
      cartonType: "Carton",
      imgUrl: "",
    },
    {
      bottleSize: "1.5L",
      caseType: "3 flat case",
      min: 8000,
      max: 18000,
      bottleWeight: 1000,
      bottleName: "Burgundy",
      price: "0.73",
      cartonType: "Carton",
      imgUrl: "",
    },
  ];

  console.log("THis is great", wineCaseData);

  const updateValue = (field, value) => {
    let r;
    setOrderDetails("wineCase", field, value);
    onClose();
    return r;
  };
  const openDrawer = React.useCallback((dataType, drawerTitle) => {
    // setIsVisible(false);
    setDataType(dataType);
    setDrawerTitle(drawerTitle);
    setIsVisible(true);
  }, []);
  const {
    wineCase: { cartonType, caseType, wineCaseItemPrice, caseCliche ,ExtraPrinting },
    label: { labelType },
  } = orderDetails;
  const { volume, wine } = orderDetails;
  if (labelType && labelType.value == "New Label" && !caseCliche) {
    console.log("This is labelType", labelType, caseCliche);
    updateValue("caseCliche", true);
  }
  if (labelType && labelType.value != "New Label" && caseCliche) {
    console.log("This is labelType", labelType, caseCliche);
    updateValue("caseCliche", false);
  }
  console.log("THis is order detail", orderDetails);

  return shouldRender ? (
    <Grid container direction="column">
    <Grid container spacing={1} alignItems="center" justify="space-between">
      <Grid item xs={6}>
      <Box mt={6} mb={3}>
        <Typography
          style={{ fontWeight: "bold" }}
          color="primary"
          align="center"
          variant="h2"
        >
          &euro; {(price - wine.wineItemPrice).toFixed(2)}
        </Typography>
        <Typography
          color="primary"
          align="center"
          style={{ fontWeight: "bold", color: "#e2b50c" }}
          variant="h6"
        >
          Item Price: {wineCaseItemPrice.toFixed(2)}&euro;
        </Typography>
      </Box>
      <Grid
        onClick={() => {
          // if (
          //   orderDetails.wine.volume &&
          //   orderDetails.wine.volume.label === "1.5"
          // ) {
          //   setPassData(data["carton1.5"]);
          //   // openDrawer("carton1.5", "Carton Type");
          // } else {
          //   setPassData(data["carton75"]);
          //   // openDrawer("carton75", "Carton Type");
          // }
          if(orderDetails?.bottle?.Container?.label === 'bottle'){
          if (orderDetails?.bottle && orderDetails?.bottle?.Container?.label !== 'Tap to select' && orderDetails?.bottle?.Closing?.label !== 'Tap to select' && orderDetails?.cap?.capType?.label !== 'Tap to select'
          ) {

            let wineColorData = wineAllData.docs.filter(y => 
              y.data().container?.toLowerCase() === orderDetails?.bottle?.Container?.label?.toLowerCase() && 
              y.data().capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label?.toLowerCase()  &&
              y.data().closing?.toLowerCase() === orderDetails?.bottle?.Closing?.label?.toLowerCase() && 
               y.data().colour?.toLowerCase().includes(orderDetails?.bottle?.bottleColor?.label?.toLowerCase()) &&
              y.data().type?.toLowerCase() === orderDetails?.bottle?.Type?.label?.toLowerCase()
              ) 
              

              db.collection('WineCaseData')
            .onSnapshot(otp =>{

              if(wineColorData.length){
             
            let GrapeData = [...new Map(wineColorData?.length && wineColorData[0]?.data()?.wineCaseData?.map((m) => [m?.wineCase, { label: m?.wineCase, value: m?.wineCase , imgUrl : otp.docs.find(x=> x.data().itemName.toLowerCase() === m?.wineCase?.toLowerCase() )?.data()?.imageLink }])).values()]
            console.log('wineColorData', wineColorData[0].data() , GrapeData);

          setPassData(GrapeData);
          openDrawer("cartonType", "Carton Type");
              }else{
                setPassData([]);
                openDrawer("cartonType", "Carton Type");
              }
            })
        }else{
          alert('please select container , closing from container section and capType from cap section first')
        }
      }else{
        if(orderDetails?.bottle?.Container?.label !== 'bottle'){
          if (orderDetails?.bottle && orderDetails?.bottle?.Container?.label !== 'Tap to select'
          ) {

            let wineColorData = wineAllData.docs.filter(y => 
              y.data().container?.toLowerCase() === orderDetails?.bottle?.Container?.label?.toLowerCase() && 
              y.data().capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label?.toLowerCase()  &&
              y.data().type?.toLowerCase() === orderDetails?.bottle?.Type?.label?.toLowerCase()
              ) 
              

              db.collection('WineCaseData')
            .onSnapshot(otp =>{

              if(wineColorData.length){
             
            let GrapeData = [...new Map(wineColorData?.length && wineColorData[0]?.data()?.wineCaseData?.map((m) => [m?.wineCase, { label: m?.wineCase, value: m?.wineCase  }])).values()]
            console.log('wineColorData', wineColorData[0].data() , GrapeData);

          setPassData(GrapeData);
          openDrawer("cartonType", "Carton Type");
              }else{
                setPassData([]);
                openDrawer("cartonType", "Carton Type");
              }
            })
        }else{
          alert('please select container , closing from container section and capType from cap section first')
        }
        }
      }
      
      }
      }
        className={classes.inputRow}
      >
        <InputTile
          value={cartonType?.label}
          label={"CartonType"}
          price={cartonType?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          if(orderDetails?.bottle?.Container?.label === 'bottle'){
          if(cartonType?.label !== 'Tap to select' ){
        
            let wineColorData = wineAllData.docs.filter(y => 
              y.data().container?.toLowerCase() === orderDetails?.bottle?.Container?.label?.toLowerCase() && 
              y.data().capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label?.toLowerCase()  &&
              y.data().closing?.toLowerCase() === orderDetails?.bottle?.Closing?.label?.toLowerCase() && 
               y.data().colour?.toLowerCase().includes(orderDetails?.bottle?.bottleColor?.label?.toLowerCase()) &&
              y.data().type?.toLowerCase() === orderDetails?.bottle?.Type?.label?.toLowerCase()
              ) 
              // y.data().wineCase?.toLowerCase() === cartonType?.label?.toLowerCase())
            // let GrapeData = [...new Map(wineColorData.map((m) => [m.data().format, { label: m.data().format, value: m.data().format , price: m.data().WineCaseprice}])).values()]
           let newArr =  wineColorData.length && wineColorData[0].data().wineCaseData.filter(x => x.wineCase.toLowerCase() === orderDetails.wineCase.cartonType?.label?.toLowerCase())
            // let GrapeData = [...new Map(wineColorData?.length && wineColorData[0]?.data()?.wineCaseData?.map((m) => [m?.format, { label: m?.format, value: m?.format }])).values()]
            let GrapeData = [...new Map(newArr.map((m) => [m?.format, { label: m?.format, value: m?.format ,price: m.WineCaseprice, imgUrl : m?.WineCaseimgLink  }])).values()]
            console.log('wineColorData', wineColorData);
            setPassData(GrapeData);
            openDrawer("caseType", "Case Type");
          
        }else{
          alert('please select Carton Type')
        }
      }else{
        if(orderDetails?.bottle?.Container?.label !== 'bottle'){
          if(cartonType?.label !== 'Tap to select' ){
        
            let wineColorData = wineAllData.docs.filter(y => 
              y.data().container?.toLowerCase() === orderDetails?.bottle?.Container?.label?.toLowerCase() && 
              y.data().capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label?.toLowerCase()  &&
             
              
              y.data().type?.toLowerCase() === orderDetails?.bottle?.Type?.label?.toLowerCase()
              ) 
              // y.data().wineCase?.toLowerCase() === cartonType?.label?.toLowerCase())
            // let GrapeData = [...new Map(wineColorData.map((m) => [m.data().format, { label: m.data().format, value: m.data().format , price: m.data().WineCaseprice}])).values()]
           let newArr =  wineColorData.length && wineColorData[0].data().wineCaseData.filter(x => x.wineCase.toLowerCase() === orderDetails.wineCase.cartonType?.label?.toLowerCase())
            // let GrapeData = [...new Map(wineColorData?.length && wineColorData[0]?.data()?.wineCaseData?.map((m) => [m?.format, { label: m?.format, value: m?.format }])).values()]
            let GrapeData = [...new Map(newArr.map((m) => [m?.format, { label: m?.format, value: m?.format ,price: m.WineCaseprice, imgUrl : m?.WineCaseimgLink  }])).values()]
            console.log('wineColorData', wineColorData);
            setPassData(GrapeData);
            openDrawer("caseType", "Case Type");
          
        }else{
          alert('please select Carton Type')
        }
        }
      }
     
          
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={caseType?.label}
          label={"CaseType"}
          price={caseType?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      <Grid
        onClick={() => {
          // setOrderDetails("wineCase", "ExtraPrinting", null);
          if(orderDetails?.bottle?.Container?.label === 'bottle'){
          if(cartonType?.label !== 'Tap to select' ){
         
            let wineColorData = wineAllData.docs.filter(y => 
              y.data().container?.toLowerCase() === orderDetails?.bottle?.Container?.label?.toLowerCase() && 
              y.data().capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label?.toLowerCase()  &&
              y.data().closing?.toLowerCase() === orderDetails?.bottle?.Closing?.label?.toLowerCase() && 
              y.data().colour?.toLowerCase(orderDetails?.bottle?.bottleColor?.label?.toLowerCase()) && 
              y.data().type?.toLowerCase() === orderDetails?.bottle?.Type?.label?.toLowerCase()
              // y.data().wineCase?.toLowerCase() === cartonType?.label?.toLowerCase()
              )
              let newArr =    wineColorData[0].data().wineCaseData.filter(x => x.wineCase.toLowerCase() === cartonType?.label?.toLowerCase())
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().format, { label: m.data().format, value: m.data().format , price: m.data().printingFee}])).values()]
            console.log('wineColorData', wineColorData[0]?.data()?.wineCaseData);
            setPassData([{label:'Yes' ,value:'Yes' ,price:newArr[0]?.printingFee},{label:'No' ,value:'No',price:null}]);
            openDrawer("ExtraPrinting", "ExtraPrinting");
          
        }else{
          alert('please select Carton Type')
        }
      }else{
        if(orderDetails?.bottle?.Container?.label !== 'bottle'){
          if(cartonType?.label !== 'Tap to select' ){
         
            let wineColorData = wineAllData.docs.filter(y => 
              y.data().container?.toLowerCase() === orderDetails?.bottle?.Container?.label?.toLowerCase() && 
              y.data().capacity?.toLowerCase() === orderDetails?.bottle?.Capacity?.label?.toLowerCase()  &&
              
            
              y.data().type?.toLowerCase() === orderDetails?.bottle?.Type?.label?.toLowerCase()
              // y.data().wineCase?.toLowerCase() === cartonType?.label?.toLowerCase()
              )
              let newArr =    wineColorData[0].data().wineCaseData.filter(x => x.wineCase.toLowerCase() === cartonType?.label?.toLowerCase())
            let GrapeData = [...new Map(wineColorData.map((m) => [m.data().format, { label: m.data().format, value: m.data().format , price: m.data().printingFee}])).values()]
            console.log('wineColorData', wineColorData[0]?.data()?.wineCaseData);
            setPassData([{label:'Yes' ,value:'Yes' ,price:newArr[0]?.printingFee},{label:'No' ,value:'No',price:null}]);
            openDrawer("ExtraPrinting", "ExtraPrinting");
          
        }else{
          alert('please select Carton Type')
        }
        }
      }
     
          
        }}
        className={classes.inputRow}
      >
        <InputTile
          value={ExtraPrinting?.label}
          label={"Extra Printing"}
          price={ExtraPrinting?.price}
          showIcon={true}
          showCounter={false}
        />
      </Grid>
      {/* <Box mb={3}>
        <FormControlLabel
          onChange={(e) => {
            updateValue("caseCliche", e.target.checked);
          }}
          checked={caseCliche}
          control={
            <Checkbox
              color="primary"
              icon={<CheckCircleOutlineIcon />}
              checkedIcon={<CheckCircleIcon />}
              name="checkedH"
            />
          }
          label="Cliche (600 / number of bottles)"
        />
      </Box> */}
     
      <Drawer
        drawerTitle={drawerTitle}
        isVisible={isVisible}
        field={dataType}
        onChange={(field, value) => {
          updateValue(field, value);
        }}
        data={passData}
        MainTitle={'wineCase'}
        onClose={onClose}
      />
   
     </Grid>
     <Grid item xs={2}>
     
           <div 
              // width="500" height="600"
              style={{
              // background: `url(${orderDetails?.bottle?.bottleType?.imgUrl})`,
              marginTop:50,
              marginBottom:50,
              backgroundPosition: 'center',
              display: 'flex',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              width: 310 ,
              height: 310 ,
              // width: orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 300 : 100,
              // height:orderDetails?.bottle?.Container?.label?.replaceAll(' ','').toLowerCase() === 'baginbox'? 380 : orderDetails?.bottle?.Container?.label?.toLowerCase() === 'can'? 210 : 360 ,
              // justifyContent:"center",
              // alignItems:"center",
              // backgroundColor:"red"

            }}>
              {orderDetails?.wineCase?.caseType?.imgUrl?
        <img src={orderDetails.wineCase?.caseType.imgUrl} alt="Uploaded Image"  style={{height:180,width:180,marginTop:50}} />
        :
        <></>}
              {/* <img src={"https://firebasestorage.googleapis.com/v0/b/custom-wine.appspot.com/o/adminPanelImages%2FPopcorn%20Merlot%206%20standing.png%2F0.2953059438721237?alt=media&token=f5d008ef-21da-45db-9d89-5f032c5d0ad1"} alt="Uploaded Image" style={{height:180,width:180,marginTop:50}} /> */}
            </div>
        </Grid>
        </Grid>
   
    <Box mt={3}>
    <NavigationButtons
        nextRoute={"/orderSummary"}
        disablePrevious={false}
        orderDetails={orderDetails}
        page={"wineCase"}
        totalPrice={price}
      />
      </Box>
    </Grid>
  ) : null;
};

export default WineCase;
