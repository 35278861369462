import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField
} from '@material-ui/core';
import { Link, withRouter } from "react-router-dom";
import React, { Component } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Icon from '@material-ui/core/Icon';
import WineCase from './OrderSubPages/WineCase';
import firebase from "firebase";
import { getOrderById } from '../Backend/Services/orderService'

class OrderDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderDetails: {},
      loading: true,
    }
  }

  componentDidMount() {
    const { match: { params: { orderUUID } } } = this.props;
    getOrderById(orderUUID).then((a) => {
      this.setState({
        loading: false,
        orderDetails: a,
      })
    })
  }

  render() {
    const { classes, match: { params: { orderUUID } } } = this.props;
    const { orderDetails: {
      bottleColor,
      bottleType,
      capColor,
      capLook,
      capSize,
      capType,
      caseType,
      corkBrand,
      corkSize,
      corkType,
      labelType,
      noOfBottles,
      totalPrice,
      volume,
      wineTaste,
      wineType,
      wineWeight,
      labelDesignerIcon,
      labelCliche,
      capDesignerIcon,
      caseCliche,
      wineYear,
      bottleItemPrice,
      wineItemPrice,
      wineCaseItemPrice,
      capItemPrice,
      corkItemPrice,
      labelItemPrice }, loading } = this.state;

      const editableDetails = {
        wine: {wineType, volume, wineYear, wineTaste, noOfBottles, wineItemPrice},
        bottle: { bottleType, bottleColor, wineWeight, bottleItemPrice},
        label: {labelType, labelDesignerIcon, labelCliche, labelItemPrice},
        cap: {capType, capSize, capColor, capLook, capDesignerIcon,capItemPrice},
        cork: {corkBrand, corkSize, corkType, corkItemPrice},
        wineCase: {caseType, caseCliche, wineCaseItemPrice},

      }
    // const {} = orderDetails;
    return (
      !loading && <div className={classes.topPadding}>
        <Grid id='header' className={classes.header} container spacing={0} alignItems='center'>
          <Grid item xs={1} lg={1} md={1}>
            <ChevronLeft onClick={() => { window.history.back(); }}></ChevronLeft>
          </Grid>
          <Grid item xs={10} lg={10} md={10} className={classes.imgTop}>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Order #{orderUUID}</p>
          </Grid>
          <Grid item xs={1} lg={1} md={1}>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Wine</h3>
            <OrderTile orderName={'Type of Wine'} price={`‎€ ${wineType.price}`} orderValue={wineType.label} />
            <OrderTile orderName={'Year'} orderValue={wineYear.label} />
            <OrderTile orderName={'Taste'} price={`‎€ ${wineTaste.price}`} orderValue={wineTaste.label} />
            <OrderTile orderName={'Volume (ml)'} price={`‎€ ${volume.price}`} orderValue={volume.label} />
            <OrderTile orderName={'Number of Bottles'} orderValue={noOfBottles} />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Bottle</h3>
            <OrderTile orderName={'Bottle Type'} orderValue={bottleType.label} />
            <OrderTile orderName={'Weight'} price={wineWeight.price ? `€ ${wineWeight.price}` : ''} orderValue={wineWeight.label} />
            <OrderTile orderName={'Color'} price={bottleColor.price ? `€ ${bottleColor.price}` : ''} orderValue={bottleColor.label} />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Label</h3>
            <OrderTile orderName={'Type'} price={labelType.price ? `€ ${labelType.price}` : ''} orderValue={labelType.label} />
            <FormControlLabel
              checked={labelDesignerIcon?.value}
              control={<Checkbox color="primary" icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />}
              label="Designer (€ 2400 / number of bottles)"
            />
            <FormControlLabel
              checked={labelCliche?.value}
              control={<Checkbox color="primary" icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />}
              label="Cliché (€ 400 / number of bottles)"
            />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Cap</h3>
            <OrderTile orderName={'Cap Type'} price={capType.price ? `€ ${capType.price}` : ''} orderValue={capType.label} />
            <OrderTile orderName={'Cap Size'} orderValue={capSize.label} price={capSize.price ? `€ ${capSize.price}` : ''} />
            <OrderTile orderName={'Cap Color'} price={capColor.price ? `€ ${capColor.price}` : ''} orderValue={capColor.label} />
            <OrderTile orderName={'Cap Look'} orderValue={capLook.label} />

            <FormControlLabel
              checked={capDesignerIcon.value}
              control={<Checkbox color="primary" icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />}
              label="Cliché (€ 400 / number of bottles)"
            />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Cork</h3>
            <OrderTile orderName={'Brand'} price={corkBrand.price ? `€ ${corkBrand.price}` : ''} orderValue={corkBrand.label} />
            <OrderTile orderName={'Type'} price={corkType.price ? `€ ${corkType.price}` : ''} orderValue={corkType.label} />
            <OrderTile orderName={'Size'} price={corkSize.price ? `€ ${corkSize.price}` : ''} orderValue={corkSize.label} />
            <div className={classes.divider}></div>
          </Grid>
        </Grid>

      
        
        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <Grid item xs={12} lg={6} md={6}>
            <h3 style={{ textAlign: 'left' }}>Wine Case</h3>
            <OrderTile orderName={'Case Type'} price={caseType.price ? `€ ${caseType.price}` : ''} orderValue={caseType.label} />
            <FormControlLabel
              checked={caseCliche}
              control={<Checkbox color="primary" icon={<CheckCircleOutlineIcon />} checkedIcon={<CheckCircleIcon />} name="checkedH" />}
              label="Cliché (€ 600 / number of bottles)"
            />
            <h1 style={{ textAlign: 'center', fontSize: '3.5rem', color: '#bd2132' }}>€ {totalPrice}</h1>
            {/* <div className={classes.divider}></div> */}
          </Grid>
        </Grid>
        <Grid spacing={3} container direction="row" justify="space-evenly" alignItems="center">
          <div style={{ marginBottom: 20, width: '100%' }}></div>
          <Grid item xs={12} lg={6} md={6}>
          </Grid>
        </Grid>
        <Grid spacing={3} container direction="row" justify="center" alignItems="center">
          <Grid item xs={6} lg={3} md={3}>
            <Link to={{
              pathname: "/order/wine",
              state: { editable: editableDetails  }
            }}>
              <Button
                style={{ backgroundColor: '#fafafa' }}
                color="primary"
                fullWidth
                className={classes.button}
              >
                Edit Order
            </Button>
            </Link>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <Link to="/order/wine">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
              >
                Re-order
            </Button>
            </Link>
          </Grid>
        </Grid>

      </div>
    )
  }
}


const styles = theme => ({
  root: {
  },
  topPadding: {
    padding: '15px',
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      // marginTop: '70px',
    },
  },
  header: {
    borderBottom: '1px solid #f1f1f1'
  },
  roundedImg: { width: 50, height: 50, borderRadius: 50, border: '1px solid white', boxShadow: '1px 1px 5px 3px #ddd' },
  heading: { color: 'black', marginLeft: 10 },
  imgTop: { textAlign: 'right' },
  hudTile: {
    borderRadius: '10px',
    height: '165px',
    backgroundColor: '#bd2132',
    boxShadow: '4px 4px 17px 4px rgb(190 33 49 / 30%)',
    '& p': {
      color: 'white',
      textAlign: 'center'
    },
    '& h1': {
      color: 'white',
      textAlign: 'center',
      fontSize: '75px',
      marginBottom: '0',
      marginTop: '15px',
      paddingTop: '0',
    }
  },
  divider: {
    'background-color': '#f1f1f1',
    'height': '15px',
    'margin': '0 -15px',
    'width': '100000px',
    'margin-left': '-1000px',
  },
  button: {
    borderRadius: '8px',
    padding: '10px 20px',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  orderTile: {
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: '#fbfbfb',
    border: '1px solid #efefef',
    padding: '5px 10px',
    textAlign: 'left',
    marginBottom: '10px',
    '& .arrow': {
      position: 'absolute',
      top: '15px',
      right: '10px',
      fontWeight: 'bold',
      color: '#bd2132'
    },
    '& .title': {
      fontSize: 12,
      color: '#999'
    },
    '& .detail': {
      color: '#bd2132'
    },

    '& .value': {
      color: '#333'
    },
    '& p': {
      marginTop: '8px',
      marginBottom: '8px',

    },
    '& h1': {
      color: 'white',
      textAlign: 'center',
      fontSize: '75px',
      marginBottom: '30px',
      marginTop: '10px',
      paddingTop: '15px',
    }
  },
});


const HudTile = withStyles(styles)((props) => (
  <div className={props.classes.hudTile}>
    <h1>{props.count}</h1>
    <p>{props.title}</p>
  </div>
))


const OrderTile = withStyles(styles)((props) => (
  <div className={props.classes.orderTile}>
    <p className={'title'}>{props.orderName || ''}</p>
    <p className={'value'}>{props.orderValue || '' }</p>
    <div className='arrow'>
      <p className={'detail'}>{props.price || ''}</p>
    </div>
  </div>
))



export default withStyles(styles)(withRouter(OrderDetails));

