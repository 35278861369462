import {Box, Button, Container, Fab, Grid, Typography} from '@material-ui/core';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import React, {useState} from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';

import BottlePage from './OrderSubPages/Bottle';
import CapPage from './OrderSubPages/Cap';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Colors from '../styles/Colors';
import CorkPage from './OrderSubPages/Cork';
import LabelPage from './OrderSubPages/Label';
import {PriceContext} from './OrderSubPages/PriceContext';
import ProgressBar from '../components/ProgressBar';
import ScrollToTopRoute from '../app_utils/ScrollToTop';
import SnackBar from '../components/SnackBar';
import TextField from '../components/Textfield';
import WineCasePage from './OrderSubPages/WineCase';
import {getAppData} from '../Backend/Services/userService'
//sub-pages
import WinePage from './OrderSubPages/Wine';
import {humanize} from '../utils';
import {useForm} from 'react-hook-form';

// const PriceContext = React.createContext({
//   price: 0,
//   setPrice:(v) => {},
// });

interface Props {
  onCancel: any;
}

type FormData = {
  email: string;
  password: string;
};

const SignIn: React.FC<Props> = ({onCancel}) => {
  const [showSnackBar, setShowSnackBar] = React.useState<boolean>(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const getStepValue = (type) => {
    switch (type) {
      case 'wine':
        return 16.66 * 1;
      case 'bottle':
        return 16.66 * 2;
      case 'cork':
        return 16.66 * 3;
      case 'label':
        return 16.66 * 4;
      case 'cap':
        return 16.66 * 5;
      case 'wine-case':
        return 100;
      default:
        return 16.66;
    }
  };

  const classes = useStyles();
  const {tab} = useParams();
  const [tabName, settabName] = React.useState(`Select ${humanize(tab)}`);
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [editableDetails, setEditableDetails] = React.useState(false);
  const [appDataState, setAppDataState] = React.useState({});
  const [appDataLoading, setAppDataLoading] = React.useState(true);
  const [currentOrderDetails, setCurrentOrderDetails] = React.useState(
    editableDetails || {
      wine: {
        wineItemPrice: 0,
      },
      bottle: {
        bottleItemPrice: 0,
      },
      label: {
        labelItemPrice: 0,
      },
      cap: {
        capItemPrice: 0,
      },
      cork: {
        corkItemPrice: 0,
      },
      wineCase: {
        wineCaseItemPrice: 0,
      },
    }
  );

  React.useEffect(() => {
    getAppData().then((a) => {
      setAppDataState(a);
      setAppDataLoading(false);
    })
  }, [])

  React.useEffect(() => {
    var tPrice = 0;
    var pKeys = Object.keys(currentOrderDetails);
    pKeys.map((pItem) => {
      tPrice = tPrice + currentOrderDetails[pItem][`${pItem}ItemPrice`];
    });
    setTotalPrice(tPrice);
  }, [editableDetails]);
  React.useEffect(() => {
    const stepValue = getStepValue(tab);
    setActiveStep(stepValue);
    settabName(`Select ${humanize(tab)}`);
  }, [tab]);
  const setFromEdit = (a) => {
    setCurrentOrderDetails(a);
    setEditableDetails(true);
  };
  return (
    <>
    { !appDataLoading ? <Container component="main" className={classes.main}>
      <Grid
        id="header"
        className={classes.header}
        container
        spacing={0}
        alignItems="center"
      >
        <Grid item xs={2} lg={2} md={2}>
          <ChevronLeft
            onClick={() => {
              window.history.back();
            }}
          ></ChevronLeft>
        </Grid>
        <Grid item xs={8} lg={8} md={8}>
          <p style={{textAlign: 'center', fontWeight: 'bold'}}>{tabName}</p>
        </Grid>
        <Grid item xs={2} lg={2} md={2}></Grid>
      </Grid>
      <Grid container>
        <ProgressBar value={activeStep} />

        <Grid
          container
          justify="space-between"
          wrap="nowrap"
          className={classes.mt2}
        >
          <Link to="/order/wine">
            <Button
              onClick={() => {
                settabName('Select Wine');
              }}
              className={classes.button}
              type="submit"
              color="primary"
              variant={tab === 'wine' ? 'contained' : 'outlined'}
              disableRipple
              disableElevation
            >
              Wine
            </Button>
          </Link>
          <Link to="/order/bottle">
            <Button
              onClick={() => {
                settabName('Select Bottle');
              }}
              className={classes.button}
              type="submit"
              color="primary"
              variant={tab === 'bottle' ? 'contained' : 'outlined'}
            >
              Container
            </Button>
          </Link>
         
          <Link to="/order/cork">
            <Button
              onClick={() => {
                settabName('Select Cork');
              }}
              className={classes.button}
              type="submit"
              color="primary"
              variant={tab === 'cork' ? 'contained' : 'outlined'}
            >
              Closing
            </Button>
          </Link>
          <Link to="/order/label">
            <Button
              onClick={() => {
                settabName('Select Label');
              }}
              className={classes.button}
              type="submit"
              color="primary"
              variant={tab === 'label' ? 'contained' : 'outlined'}
            >
              Label
            </Button>
          </Link>
          <Link to="/order/cap">
            <Button
              onClick={() => {
                settabName('Select Cap');
              }}
              className={classes.button}
              type="submit"
              color="primary"
              variant={tab === 'cap' ? 'contained' : 'outlined'}
            >
              Cap
            </Button>
          </Link>
        
          <Link to="/order/wine-case">
            <Button
              onClick={() => {
                settabName('Select Wine Case');
              }}
              className={classes.button}
              type="submit"
              color="primary"
              variant={tab === 'wine-case' ? 'contained' : 'outlined'}
            >
              Wine Case
            </Button>
          </Link>
        </Grid>
        <Grid container style={{paddingTop: 55}}>
          <PriceContext.Provider
            value={{
              // setAllDetails: (a) => {setCurrentOrderDetails(a)},
              price: totalPrice,
              appData: appDataState,
              setOrderDetails: (page, field, object) => {
                let currObj = Object.assign({}, currentOrderDetails);
                currObj[page][field] = object;
                if (object?.price) {
                  let keys = Object.keys(currObj[page]);
                  let itemPrice = 0;
                  keys.map((item) => {
                    let cObj = currObj[page][item];
                    if (cObj?.price) {
                      itemPrice = itemPrice + parseFloat(cObj?.price);
                    }
                  });
                  currObj[page][`${page}ItemPrice`] = itemPrice;
                }
                setCurrentOrderDetails(currObj);
                var tPrice = 0;
                var pKeys = Object.keys(currentOrderDetails);
                pKeys.map((pItem) => {
                  tPrice =
                    tPrice + currentOrderDetails[pItem][`${pItem}ItemPrice`];
                });
                setTotalPrice(tPrice);
              },
              orderDetails: currentOrderDetails,
              setPrice: (v) => {
                setTotalPrice(v);
              },
            }}
          >
            <Switch>
              <ScrollToTopRoute
                exact
                path="/order/wine"
                name="Login"
                setFromEdit={setFromEdit}
                component={WinePage}
              />
              <ScrollToTopRoute
                exact
                path="/order/bottle"
                name="Signup"
                component={BottlePage}
              />
              <ScrollToTopRoute
                exact
                path="/order/label"
                name="Enroll"
                component={LabelPage}
              />
              <ScrollToTopRoute
                exact
                path="/order/cap"
                name="Login"
                component={CapPage}
              />
              <ScrollToTopRoute
                exact
                path="/order/cork"
                name="Signup"
                component={CorkPage}
              />
              <ScrollToTopRoute
                exact
                path="/order/wine-case"
                name="Enroll"
                component={WineCasePage}
              />
            </Switch>
          </PriceContext.Provider>
        </Grid>
      </Grid>
    </Container> : null}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      borderBottom: '1px solid #f1f1f1',
    },
    main: {
      minHeight: '62vh',
      // marginTop: 110,
      paddingBottom: 20,
      width: '100vw',
      overflow: 'hidden',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
      },
    },
    container: {
      //header's height
    },
    tab: {
      minWidth: 100,
      textTransform: 'capitalize',
    },
    button: {
      minWidth: 100,
      minHeight: 50,
      textTransform: 'capitalize',
      fontWeight: 500,
      borderRadius: 25,
      [theme.breakpoints.down('sm')]: {
        marginRight: 10,
        minWidth: 100,
        minHeight: 25,
      },
    },
    mt2: {
      marginTop: theme.spacing(3),
      overflow: 'auto',
      position: 'absolute',
      marginLeft: '-15px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  })
);

SignIn.defaultProps = {};

export default SignIn;
