import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField
} from '@material-ui/core';
import React, { Component } from 'react'
import { getUserById, uploadProfilePicture } from '../Backend/Services/userService'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import { getCurrentUser } from '../Backend/Services/authService'

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPass: false,
    }
  }
  setShowPass = (s) => {
    this.setState({ showPass: s })
  }
  getUserInfo = () => {
    getCurrentUser().then(a => {
      this.setState({ currentUser: a }, () => {
        getUserById(a.uid).then((b) => {
          this.setState({ userData: b })
        })
      })
    })
  }
  componentDidMount() {
    this.getUserInfo()
  }
  render() {
    const { classes } = this.props;
    const { currentUser, userData } = this.state;
    return (
      currentUser && userData ? <div className={classes.topPadding}>
        <Grid id='header' className={classes.header} container spacing={0} alignItems='center'>
          <Grid item xs={2} lg={2} md={2}>
            <ChevronLeft onClick={() => { window.history.back(); }}></ChevronLeft>
          </Grid>
          <Grid item xs={8} lg={8} md={8} className={classes.imgTop}>
            <h4>Account Settings</h4>
          </Grid>
          <Grid item xs={2} lg={2} md={2}>
          </Grid>
        </Grid>

        <Grid container direction="row" justify="center" alignItems="center">
          <input className={classes.hiddenInput} type="file" id="myfile" onChange={(e) => { uploadProfilePicture(e.target.files[0], userData.uuid, this.getUserInfo) }} name="myfile">

          </input>
          <Grid style={{ textAlign: 'center' }} item xs={12} lg={6} md={6}>
          <img src={userData.profilePicture ? userData.profilePicture : require('../assets/user-placeholder.png')} className={classes.roundedImg} alt="" >

          </img>
          <img src={require('../assets/cam-overlay.png')} className={classes.roundedImgOverlay} alt="" />


            {/* <h2 style={{ textAlign: 'center' }}>John Doe</h2> */}
            {/* <div className={classes.divider}></div> */}
          </Grid>
        </Grid>
        <Grid container className={classes.inputsWrapper} direction="row" justify="space-evenly" alignItems="center">
          <Grid container xs={12} lg={6} md={6} style={{ margin: '-15px' }} >
            <Grid className={classes.gridPadding} item xs={6} lg={6} md={6}>
              <TextField
                className={classes.inputStyle}
                fullWidth
                defaultValue={userData.firstName}
                label="First Name"
                variant="filled"
                placeholder="John"
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid className={classes.gridPadding} item xs={6} lg={6} md={6}>
              <TextField
                className={classes.inputStyle}
                fullWidth
                defaultValue={userData.lastName}
                label="Last Name"
                variant="filled"
                placeholder="Doe"
                InputProps={{ disableUnderline: true }}
              />
            </Grid>

            <Grid className={classes.gridPadding} item xs={12} lg={12} md={12}>
              <TextField
                className={classes.inputStyle}
                defaultValue={userData.email}
                fullWidth
                label="Email"
                variant="filled"
                placeholder="johndoe@email.com"
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid className={classes.gridPadding} item xs={12} lg={12} md={12}>
              <div className={classes.dateParent}>
                <TextField
                  id="date"
                  label="Birthday"
                  type="date"
                  fullWidth
                  defaultValue={userData.birthday}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

      </div> : null
    )
  }
}


const styles = theme => ({
  root: {
  },
  topPadding: {
    padding: '15px 0',
    overflowX: 'hidden',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
    },
  },
  header: {
    padding: '0px 0px',
    borderBottom: '1px solid #f1f1f1',
    marginBottom: '20px',
  },
  dateParent: {
    padding: '7px 12px',
    backgroundColor: '#fbfbfb',
    border: '1px solid #efefef',
    borderRadius: '8px',
    marginBottom: '10px',
    '& .MuiFormControl-root': {
    }
  },

  inputStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#fbfbfb',
      border: '1px solid #efefef',
      borderRadius: '8px',
      marginBottom: '10px',
    },
  },
  settingRow: {
    padding: '0px 15px',
    borderBottom: '1px solid #e1e1e1',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#efefef',
      cursor: 'pointer',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '10px',
      top: '15px',
    }
  },
  roundedImg: {
    width: '35vw',
    height: '35vw',
    borderRadius: '50vw',
    border: '1px solid white',
    boxShadow: '1px 1px 5px 3px #ddd',
    objectFit: 'cover',
    marginBottom: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '20vw',
      height: '20vw',
    },

  },
  roundedImgOverlay : {
    position: 'absolute',
    left: 'calc(50% - calc(17.5vw - -1px))',
    top: '98px',
    zIndex: 10,
    width: '35vw',
    height: '35vw',
    borderRadius: '50vw',
    border: '1px solid white',
    boxShadow: '1px 1px 5px 3px #ddd',
    objectFit: 'cover',
    marginBottom: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '20vw',
      height: '20vw',
      left: 'calc(50% - calc(10vw - -1px))',
    },

  },
  heading: { color: 'black', marginLeft: 10 },
  imgTop: { textAlign: 'center' },

  divider: {
    'background-color': '#f1f1f1',
    'height': '15px',
    'margin': '0 -15px',
    'width': '100000px',
    'margin-left': '-1000px',
  },
  button: {
    borderRadius: '8px',
    padding: '10px 20px',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  hiddenInput: {
    width: '100%',
    height: '200px',
    position: 'absolute',
    opacity: 0,
    zIndex: 20,
  },
  orderTile: {
    position: 'relative',
    borderRadius: '10px',
    // backgroundColor: '#fbfbfb',
    // border: '1px solid #efefef',
    padding: '5px 10px',
    textAlign: 'left',
    marginBottom: '10px',
    '& .arrow': {
      position: 'absolute',
      top: '15px',
      right: '10px',
      fontWeight: 'bold',
      color: '#bd2132'
    },
    '& .title': {
      fontSize: 12,
      color: '#999'
    },
    '& .detail': {
      color: '#bd2132'
    },

    '& .value': {
      color: '#333'
    },
    '& p': {
      marginTop: '8px',
      marginBottom: '8px',

    },
    '& h1': {
      color: 'white',
      textAlign: 'center',
      fontSize: '75px',
      marginBottom: '30px',
      marginTop: '10px',
      paddingTop: '15px',
    }
  },
  gridPadding: {
    padding: '0px 5px'
  },
  inputsWrapper: {
    padding: '15px'
  },
});

const OrderTile = withStyles(styles)((props) => (
  <div className={props.classes.orderTile}>
    <p className={'title'}>{props.orderName}</p>
    <p className={'value'}>{props.orderValue}</p>
    <div className='arrow'>
      <p className={'detail'}>{props.price}</p>
    </div>
  </div>
))



export default withStyles(styles)(OrderDetails);

