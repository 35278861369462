import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import firebase from "firebase";

class ScrollToTopRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
    }
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if(this.props.path == '/') {
        //   setTimeout(() => {
            this.props.history.push('/home');
            this.setState({load: true});
          // }, 2000)
        } else {
          this.setState({load: true});
        }
      } else {
        this.props.history.push('/');
        this.setState({load: true});
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.path === this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { load } = this.state;

    return load && <Route {...rest} render={props => (<Component setFromEdit={this.props.setFromEdit} {...props} />)} />;
  }
}

export default withRouter(ScrollToTopRoute);